import { useState, useEffect } from "react";
import * as SettingsServices from "../../services/settingsServices";
import * as UMSService from "../../services/umsService";
import { SelectPicker } from "rsuite";

const Storeandgroupcomponent = (props: any) => {
  const {
    userContext = {},
    setStoreNameHeader,
    storeNameHeader,
    storeName,
    setStoreName,
    storeID,
    setStoreID,
    userStore,
    setUserStore,
    onOptionChangeStore,
  } = props;
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  //   const [storeNameHeader, setStoreNameHeader] = useState([]);
  //const [storeName, setStoreName] = useState([]);
  //const [storeID, setStoreID] = useState([]);
  //const [userStore, setUserStore] = useState("");

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, store } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    if (storeID) {
      setUserStore(storeID[0]);
    }
  };
  const SelectAwgOrGot = (event) => {
    setAwgOrGotSelect(event);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
  };

  return (
    <>
      {userContext && userContext.roleName === "Super Admin" ? (
        <div className="d-flex flex-row justify-content-center align-items-center flex-nowrap">
          <SelectPicker
            className="header-bar-item"
            placeholder="GOT"
            onChange={SelectAwgOrGot}
            label="Group Type"
            data={awgOrGot.map((item) => ({
              label: item.groupStore_desc,
              value: item.groupStore_uid,
            }))}
          />
          <SelectPicker
            onChange={SelectGroupType}
            label="Group"
            className="header-bar-item"
            virtualized
            data={groupAndGroupID.map((item) => ({
              label: item.groupStore_desc,
              value: item.groupStore_uid,
            }))}
          />
          <SelectPicker
            onChange={onOptionChangeHandler}
            label="Store"
            className="header-bar-item"
            placeholder={storeName && storeName[0] ? storeName[0] : "All"}

            virtualized
            data={storeName.map((item, index) => ({
              label: item,
              value: index,
            }))}
          />
        </div>
      ) : userContext && userContext.store !== 0 ? (
        <>
                <label style={{height:"30px", fontWeight:700}} className=" alert">
                  Store: {storeNameHeader}
                </label>{" "}        </>
      ) : userContext &&
        userContext.groupTypeId &&
        userContext.groupTypeId !== "" ? (
        <>
          {" "}
          <SelectPicker
            onChange={onOptionChangeHandler}
            label="Store"
            placeholder={storeName && storeName[0] ? storeName[0] : "All"}
            className="header-bar-item"
            data={storeName.map((item, index) => ({
              label: item,
              value: index,
            }))}
          />
        </>
      ) : (
        <>
          <SelectPicker
            onChange={SelectGroupType}
            label="Group"
            className="header-bar-item"
            virtualized
            data={groupAndGroupID.map((item) => ({
              label: item.groupStore_desc,
              value: item.groupStore_uid,
            }))}
          />
          {
            <>
              {" "}
              <SelectPicker
                onChange={onOptionChangeHandler}
                label="Store"
                placeholder={storeName && storeName[0] ? storeName[0] : "All"}

                className="header-bar-item"
                virtualized
                data={storeName.map((item, index) => ({
                  label: item,
                  value: index,
                }))}
                style={{ width: 224 }}
              />
            </>
          }
        </>
      )}
    </>
  );
};

export default Storeandgroupcomponent;
