/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CheckPicker,
  Checkbox,
  Drawer,
  Dropdown,
  PickerHandle,
  Input,
  Modal,
  InputGroup,
} from "rsuite";
import { useState, useEffect } from "react";
import SearchIcon from "@rsuite/icons/Search";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { Icon } from "@iconify/react";
import TableComponent from "../../../common/tableComponent";

const ReportTrackItDetailComponent = (props: any) => {
  const {
    clickedFilterData,
    clickedFilterDataMaster,
    tableDetails,
    reportList,
    exportToExcel,
    totalTableRow,
    selectedTab,
    DisplayNoDatatlabel,
    isDonation,
    isTableCustomStriped,
  } = props;
  useEffect(() => {
    setAllFilteredData(reportList);
  }, [reportList]);
  const [allFilteredData, setAllFilteredData] = useState([]);
  const getDepartmentName = () => {
    let dept = "All";
    if (clickedFilterData && clickedFilterData.store_ID !== "") {
      dept = clickedFilterData.department
        ? clickedFilterData.department.trim()
        : "";
    }
    return dept;
  };
  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllFilteredData(
          reportList.filter((item) =>
            item.upc
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllFilteredData(
          reportList.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllFilteredData(reportList);
    }
  };
  console.log(reportList);
  return (
    <>
      <div className="bg-container row m-0 mt-3 p-3">
        <div className="clo-12 col-md-2 p-2 reportDescItem">
          <div className="icon">
            <Icon icon="material-symbols:storefront-outline" />
          </div>
          <div className="label">
            <p className="m-0">
              {selectedTab.value === "6" ? "Transferred From" : "Store ID"}
            </p>
            <p className="m-0">
              {clickedFilterDataMaster
                ? clickedFilterDataMaster.store_ID ?? "All"
                : clickedFilterData
                  ? clickedFilterData.store_ID === ""
                    ? "All"
                    : clickedFilterData.store_ID
                  : "All"}
            </p>
          </div>
        </div>
        <div className="clo-12 col-md-2 p-2 reportDescItem">
          {!isDonation && (
            <>
              <div className="icon">
                <Icon icon="material-symbols:person-outline-rounded" />
              </div>
              <div className="label">
                <p className="m-0">DM</p>
                <p className="m-0">
                  {clickedFilterDataMaster
                    ? clickedFilterDataMaster.store_ID === "" &&
                      clickedFilterDataMaster.dm === ""
                      ? "All"
                      : clickedFilterDataMaster.dm
                    : clickedFilterData
                      ? clickedFilterData.dm === ""
                        ? "All"
                        : clickedFilterData.dm
                      : "All"}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="clo-12 col-md-2 p-2 reportDescItem">
          {!isDonation && (
            <>
              <div className="icon">
                <Icon icon="material-symbols:store-outline" />
              </div>
              <div className="label">
                <p className="m-0">Department</p>
                <p className="m-0">{getDepartmentName()}</p>
              </div>
            </>
          )}
        </div>
        <div className="clo-12 col-md-2 p-2 reportDescItem">
          <div className="label">
            <p className="m-0">
              <InputGroup
                onChange={(event) => {
                  filterList(event.target.value);
                }}
                style={{

                  height: 40,
                  fontWeight: 800,
                }}
              >
                <Input placeholder="Filter Description or UPC" />
                <InputGroup.Button>
                  <SearchIcon />
                </InputGroup.Button>
              </InputGroup>
            </p>
          </div>
        </div>
        {/* <div className="col-2 p-2 reportDescItem">
        {clickedFilterData.Damage && <div className="icon">
          <Icon icon="material-symbols:file-copy-outline" />
        </div>}
        {clickedFilterData.Damage && <div className="label">
          <p className="m-0">Damage</p>
          <p className="m-0">{clickedFilterData.Damage}</p>
        </div>}
       </div> */}
        <div className="clo-12 col-md-2 p-2 reportDescItem">
          <div className="d-flex">
            <Dropdown
              className="mx-2 button-fixed"
              title="Export"
              appearance="primary"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(reportList, "Detailed Order Report");
                }}
              >
                Export to Excel
              </Dropdown.Item>
              {/* <Dropdown.Item icon={<DetailIcon />}>Export to PDF</Dropdown.Item>
            <Dropdown.Item divider />
            <Dropdown.Item icon={<PageIcon />}>Share to Email</Dropdown.Item>
            <Dropdown.Item icon={<PageIcon />}>Send Link to Email</Dropdown.Item> */}
            </Dropdown>
          </div>
        </div>
      </div>

      <TableComponent
        isFromModal={true}
        tableDetails={tableDetails}
        reportList={allFilteredData.sort((a, b) => {
          return a["store_ID"]
            .toString()
            .localeCompare(b["store_ID"].toString(), undefined, {
              numeric: true,
            });
        })}
        iconColumn={""}
        onTableRowClick={() => { }}
        totalTableRow={totalTableRow}
        isTotalRowRequired={true}
        DisplayNoDatatlabel={DisplayNoDatatlabel}
        isTableCustomStriped={isTableCustomStriped}
      />
    </>
  );
};

export default ReportTrackItDetailComponent;
