import secureLocalStorage from "react-secure-storage";

export const StorageService = {
  setSLSItem: (key: string, value: any, isString: boolean = false) => {
    const valueAsString: string = isString ? value : JSON.stringify(value);
    secureLocalStorage.setItem(key, valueAsString);
  },
  getSLSItem: (key: string, isString: boolean = false) => {
    const slsString: string = secureLocalStorage.getItem(key) as string;
    if (slsString) {
      const value: any = isString ? slsString : JSON.parse(slsString);
      return value;
    }
    return null;
  },
  clearSLSItem: (key: string) => {
    secureLocalStorage.removeItem(key);
  },
  clearAllSLSItems: () => {
    secureLocalStorage.clear();
  },
};
