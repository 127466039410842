import { useState } from "react";
import ConfirmationListComponent from "./ConfirmationListAll/ConfirmationListComponent";
import DepartmentMain from "./ConfirmationDepartment/DepartmentMain";
import "../../../../src/App.scss";

const ConfirmationListMain = (props: any) => {
  const { userContext, handleSpinner } = props;

  const [isGrindOrMain, setIsGrindOrMain] = useState("1");

  return (
    <>
      <div>
        {isGrindOrMain === "1" ? (
          <ConfirmationListComponent
            userContext={userContext}
            handleSpinner={handleSpinner}
            setIsGrindOrMain={setIsGrindOrMain}
          />
        ) : (
          <DepartmentMain
            userContext={userContext}
            handleSpinner={handleSpinner}
            setIsGrindOrMain={setIsGrindOrMain}
          />
        )}
      </div>
    </>
  );
};

export default ConfirmationListMain;
