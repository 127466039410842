import React from "react";
import { CheckPicker, Button, IconButton } from "rsuite";

interface FiltersComponentProps {
  columnFilters: any[];
  selectedColumnFilters: { [key: string]: any[] };
  setSelectedColumnFilters: (filters: { [key: string]: any[] }) => void;
  filterCount: any;
  filterSelected: (count: number, filters: { [key: string]: any[] }) => void;
  onFilterClick: (filters: { [key: string]: any[] }) => void;
  clearFilter: () => void;
  getCheckPickerData: (result: any) => any[];
  isfilterSelected: boolean;
  picker: React.RefObject<any>;
}

const FiltersComponent: React.FC<FiltersComponentProps> = ({
  columnFilters,
  selectedColumnFilters,
  setSelectedColumnFilters,
  filterCount,
  filterSelected,
  onFilterClick,
  clearFilter,
  isfilterSelected,
  getCheckPickerData,
  picker,
}) => {
  return (
    <div className="p-2 mt-3">
      <div className="card card-body totalCard">
        {columnFilters.map((result) => (
          <div className="collapsedData pe-2" key={result.key}>
            <CheckPicker
              sticky
              preventOverflow
              className="w-100 filtersPicker"
              menuMaxHeight={190}
              appearance="default"
              ref={picker}
              size="xs"
              renderValue={(value, items) => {
                const _value = [...value].sort((a, b) => {
                  let x = a ? a : "";
                  let y = b ? b : "";
                  return x.toString().localeCompare(y.toString(), undefined, {
                    numeric: true,
                  });
                });
                _value[0] === "" && _value.splice(0, 1, "(Empty)");
                return (
                  <>
                    {_value.length < 3
                      ? _value.splice(0, 2).join(", ")
                      : _value[0] !== "(Empty)"
                      ? _value.splice(0, 2).join(", ")
                      : _value.splice(1, 2).join(", ")}{" "}
                    {_value.length > 0 ? (
                      <span className="roundOff">{`+${_value.length}`}</span>
                    ) : (
                      ``
                    )}
                  </>
                );
              }}
              data={getCheckPickerData(result)}
              style={{ width: 224 }}
              value={selectedColumnFilters[result.key]}
              placeholder={result.label}
              renderMenuItem={(label, item) => (
                <div className="d-flex flex-column">
                  <i className="rs-icon rs-icon-user" /> {label}
                </div>
              )}
              onChange={(value, event) => {
                let selectedData = { ...selectedColumnFilters };
                selectedData[result.key] = value;
                setSelectedColumnFilters(selectedData);
                filterSelected(filterCount, selectedData);
                onFilterClick(selectedData);
              }}
            />
          </div>
        ))}
      </div>
      <div className="footer text-end boxdetails">
        <Button
          appearance="ghost"
          className="mx-2"
          size="xs"
          disabled={!isfilterSelected}
          onClick={clearFilter}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
};

export default FiltersComponent;
