import { useState, useEffect } from "react";
import * as AuthService from "../../services/authService";
import { StorageService } from "../../utils/sessionService";

const Checkgroups = (props: any) => {
  const { userContext, setUserContext, setIsMultipleGroup } = props;
  const [usersGroups, setUsersGroups] = useState([]);


  const getUsersGroupsData = (user_ID: number) => {
    AuthService.getUsersGroups(user_ID)
      .then((result) => {
        const { records } = result;
        if (records && records[0].description === "Docs") {
          setIsMultipleGroup(true);
        }
        setUsersGroups(records);
      })
      .catch((err) => {
        throw err;
      });
  };

  const selectedGroup = (value) => {
    var allInfo = [];
    var groupDescription = "";
    var groupStoreID = null;

    if (value) {
      allInfo = value.target.innerHTML.split("-");

      groupDescription = allInfo[0];
      groupStoreID = Number(allInfo[1]);

      var user = StorageService.getSLSItem("UserContext");
      StorageService.clearSLSItem("UserContext");
      user.groupTypeName = groupDescription;
      user.groupTypeId = groupStoreID;
      StorageService.setSLSItem("UserContext", user, false);
      const _context = StorageService.getSLSItem("UserContext");

      const redirectToPortal = () => {
        return (window.location.href = "https://portal.gotsystems.net");
      };

      _context && redirectToPortal();
    }
  };

  useEffect(() => {
    getUsersGroupsData(userContext && userContext.user_ID);
  }, [userContext]);
  const groupList = () =>
    usersGroups.map((item, idx) => (
      <>
        <li
          className="li-groups"
          onClick={(value) => selectedGroup(value)}
          key={idx}
        >
          {item.description} - {item.groupStore_UID}
        </li>
        <hr />
      </>
    ));

  if (usersGroups) {
    return <div className="">{groupList()}</div>;
  } else {
    <></>;
  }
};

export default Checkgroups;
