/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import * as ReportService from "../../../../services/reportServices";
import moment from "moment";
import { ReportLDTSConstants } from "../ReportLastDayToSaveConstants";

import { Modal, Table, Button, ButtonToolbar } from "rsuite";
import DiscountTable from "./DiscountTable";
const CostPrebookDetails = (props: any) => {
  const { storeID, UPC, vendorID, ItemID, fromDate, userContext } = props;

  const [costPrebookDetailData, setCostPrebookDetailData] = useState([]);
  useEffect(() => {
    GetCostPrebookDetails(storeID, UPC, vendorID, ItemID, fromDate);
  }, [storeID, UPC, vendorID, ItemID, fromDate]);

  const modifyReportData = (data) => {
    data.map((item) => {
      item.shipDate_Start
        ? (item.shipDate_Start = moment(item.shipDate_Start).format(
            "MM/DD/YYYY"
          ))
        : item.shipDate_Start;
      item.add_Date
        ? (item.add_Date = moment(item.add_Date).format("MM/DD/YYYY"))
        : item.add_Date;
      item.shipDate_End
        ? (item.shipDate_End = moment(item.shipDate_End).format("MM/DD/YYYY"))
        : item.shipDate_End;

      return item;
    });
  };

  const GetCostPrebookDetails = async (
    storeID,
    UPC,
    vendorID,
    ItemID,
    fromDate
  ) => {
    const { handleSpinner } = props;

    handleSpinner && handleSpinner(true);
    ReportService.GetCostPrebookDetails(
      storeID,
      UPC,
      vendorID,
      ItemID,
      fromDate,
      3
    )
      .then((result) => {
        //console.log(result.records);
        modifyReportData(result.records);
        setCostPrebookDetailData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  return (
    <div>
      <DiscountTable
        isFromModal={false}
        tableDetails={ReportLDTSConstants["CostPrebookDetails"]}
        reportList={costPrebookDetailData}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </div>
  );
};

export default CostPrebookDetails;
