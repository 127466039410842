export const ViewOrderByDepartmentConstants = {
    "ViewOrderByDepartmentConstants":  [
        
        { Header: "Department", DataKey: "department", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "text", isSearch: false , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Reg Ord", DataKey: "qty_Reg_Ord", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Adv Ord", DataKey: "qty_ADV_Ord", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items Sent", DataKey: "items_Sent", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cases Sent", DataKey: "qty_Sent", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        
        { Header: "Total", DataKey: "total", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Invoiced Total", DataKey: "invoiced_Total", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        //{ Header: "Department ID", DataKey: "department_ID", Width: 0, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        //{ Header: "Not on Alloc Count", DataKey: "not_Alloc_Count", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, isSubHeader: false, colSpan: 1},
        //{ Header: "Today Allow Max", DataKey: "today_Allow_Max", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "Diff", DataKey: "diff_Max", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],
    "ViewOrderByDepartmentDetailConstants":  [

        //{ Header: "Department ID", DataKey: "department_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Order Type", DataKey: "order_Type", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total", DataKey: "total", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        
       // { Header: "Invoiced Total", DataKey: "invoiced_Total", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Status", DataKey: "status", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Got Conf #", DataKey: "confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items", DataKey: "items", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        
        { Header: "Cases In", DataKey: "qty_Loaded", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Off by PB/Inv", DataKey: "qty_TakenOff", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, isSubHeader: false, colSpan: 1},
        { Header: "Cut Off", DataKey: "qty_CutOff", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "Alctn Cut", DataKey: "qty_on_Allocation", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cases Sent", DataKey: "qty_Updated", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Received", DataKey: "dateTime_In", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Sent Time", DataKey: "sent_DateTime", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Department ID", DataKey: "department_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        
    ],
    "ViewOrderByDepartmentDetailbyDeptConstants":  [
        
        //{ Header: "Shelf Count", DataKey: "shelf_Count", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Got Conf #", DataKey: "confirmation_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User", DataKey: "user_ID", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total", DataKey: "total", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Invoiced Total", DataKey: "invoiced_Total", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Item", DataKey: "item_ID", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "POST MVT ", DataKey: "poS_MVT", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Days To Wait", DataKey: "days_To_Wait", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, isSubHeader: false, colSpan: 1},
        { Header: "Why Wait", DataKey: "why_Wait", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Save Amt", DataKey: "wait_Saving_Amt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "PB Qty", DataKey: "pB_Wait_Pack", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Delivery Day Total", DataKey: "dD_Total_Qty", Width: 10, isFixed: false, isSortable: true, Align: "Center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "User Adj Ord", DataKey: "user_Ord_Adj_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Adj CAO", DataKey: "system_Adj_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Same Day Pre Book", DataKey: "pB_SameDay_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Wait to Save", DataKey: "wait_To_Save", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pull List Qty", DataKey: "pull_List_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Adj Pull List", DataKey: "pull_List_Qty_Adj", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Back Stock", DataKey: "back_Stock_SameDay_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "In W/H", DataKey: "wh_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Max O/H", DataKey: "max_Qty_onHand", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Qty Block", DataKey: "qty_Blocked", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Block Reason", DataKey: "block_Reason", Width: 10, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Price Type", DataKey: "price_Type", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Ad Future Days", DataKey: "ad_Future", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pnt", DataKey: "pnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Today on Hand", DataKey: "today_OnHand_Qty", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Today on Hand Since Last", DataKey: "today_OnHand_Qty_SLast", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "ORD PB", DataKey: "pB_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "PB EBA", DataKey: "prebook_EBA", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Ship Ends", DataKey: "pB_ShipDate_Ends", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Qty on Alloct", DataKey: "qty_on_Allocation", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Qty Cut", DataKey: "qty_CutOff", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "flag_OOS", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "statusView", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User", DataKey: "status", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User", DataKey: "deadLine_DateTime", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "filter_Adj_CAO", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User", DataKey: "filter_Pull_list", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "filter_Wh_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User", DataKey: "filter_Block", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Blind Ord", DataKey: "filter_Blind", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User", DataKey: "filter_MAStHave", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "filter_Wh_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "User", DataKey: "filter_Wh_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ]
}