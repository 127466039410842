import { TableHeaderConfig } from "../../../models"

export const OrderReceiptConstants = {
    "OrderReceiptConstants": [
        {...new TableHeaderConfig(), Header: "Delivery Date", DataKey: "deadLine_4", Width: 100, isFixed: false, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Item", DataKey: "item_ID", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Description", DataKey: "description", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Size", DataKey: "size", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Pack", DataKey: "pack1", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Ord Qty", DataKey: "f_Qty_0", Width: 100, isSearch: true, searchGroup: [1, 2]},



        {...new TableHeaderConfig(), Header: "Delivery Cost", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 4 },
        {...new TableHeaderConfig(), Header: "Cost",TypeOfData: "Price", DataKey: "cost_W_4", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "AMP",TypeOfData: "Price", DataKey: "amP_4", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "BB", TypeOfData: "Price",DataKey: "bB_4", Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Net",TypeOfData: "Price", DataKey: "Net", Width: 70,  isSubHeader: true},

        {...new TableHeaderConfig(), Header: "", DataKey: "", Width: 10, isFixed: false,isSortable: false, isSearch: false},

        {...new TableHeaderConfig(), Header: "Next Delivery Cost", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 5 },
        {...new TableHeaderConfig(), Header: "Date", DataKey: "cost_Up_Start", TypeOfData: "Date", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Cost",TypeOfData: "Price", DataKey: "cost_W_4", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "AMP",TypeOfData: "Price", DataKey: "amP_4", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "BB",TypeOfData: "Price",  DataKey: "bB_4", Width: 70,  isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Net", DataKey: "NetNext", Width: 70, TypeOfData: "Price", isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Savings",Align:"right", DataKey: "Savings", TypeOfData: "Price", Width: 100, isSearch: true, searchGroup: [1, 2]},



    ]


}