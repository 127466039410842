import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as ReportService from "../../../../services/reportServices";
import TableComponent from "../../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportConstants } from "../../../reports/MarkdownReport/MarkdownStore/reportMarkdownConstants";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "../../../../models"

const DetailShrinkComponent = (props: any) => {
  const {
    fromDate,
    toDate,
    userContext,
    departmentIDLabel,
    storeIDLabel,
    showDepartment,
    MainLabel,
    userStore,
    groupTypeID
  } = props;

  const { groupType } = userContext ?? {};
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "3",
    label: "Shrink",
  });

  //#region Reports Master Lists
  const [reportData, setReportData] = useState(new ReportMaster());
  //#endregion

  //#region Reports Tables Config
  // Headers
  const [groupTableHeaders, setGroupTableHeaders] = useState([]);
  const [listTableHeaders, setListTableHeaders] = useState([]);
  const [detailTableHeaders, setDetailTableHeaders] = useState([]);

  // Totals
  const [groupTableTotals, setGroupTableTotals] = useState([]);
  const [listTableTotals, setListTableTotals] = useState([]);

  // reports List
  const [groupTableReports, setGroupTableReports] = useState([]);
  const [groupTableMasterReports, setGroupTableMasterReports] = useState([]);
  const [listTableReports, setListTableReports] = useState([]);
  const [detailTableReports, setDetailTableReports] = useState([]);
  //#endregion

  //#region Filters
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
  const [filterCount, setFilterCount] = useState({});
  const [isfilterSelected, setFilterSelected] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [clickedFilterData, setClickedFilterData] = useState(null);
  const [clickedFilterDataMaster, setClickedFilterDataMaster] = useState(null);
  const [isDataloaded, setIsDataloaded] = useState(false);
  //#endregion

  useEffect(() => {
    setGroupTableHeaders([]);
    setGroupTableTotals([]);
    setGroupTableReports([]);
    getReportData();
  }, [userContext, fromDate, toDate,groupTypeID,userStore]);
  useEffect(() => {
    handleDataChange();
  }, [reportData, selectedTab]);
  useEffect(() => {
    onDetailReportRowClick(reportData.reportList, true);
  }, [reportData]);
  //#region Common Functions
  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              )
          : "";
    });
    return totalObj;
  };

  const newMainLabel = MainLabel.replaceAll("_", " ");
  //#region Markdown Report
  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = [];
    headers = getMarkdownHeaders(reportData.headers, false);
    data = handleMarkdownData(false);
    totals = getTotalRowData(data, headers);
    setGroupTableHeaders(headers);
    setGroupTableTotals(totals);
    setGroupTableMasterReports(data);
    setGroupTableReports(data);
  };

  const getReportData = async () => {
    var { groupType, groupTypeId, store, roleName } = userContext;
    if (roleName === "Super Admin" && userStore === "") {
      ReportService.getReportMarkdown(
        groupTypeID,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
        });
    } else if (
      roleName === "Super Admin" ||
      (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === "")
    ) {
      ReportService.getReportMarkdown(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
        });
    } else {
      if (store !== 0) {
        groupTypeId = store;
        groupType = 1;
      } else {
        groupType = 2;
      }
      ReportService.getReportMarkdown(
        groupTypeId,
        groupType,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          result.records.map(
            (record) =>
              (record["MarkDownLoss"] =
                (record["reg_Total"] * record["disc_Percent"]) / 100)
          );

          const _reportData = handleReportData(result);
          setReportData(_reportData);
          setIsDataloaded(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
        });
    }
  };

  const handleReportData = (response: any) => {
    const { records = [], uniqueReasons = [] } = response;
    const _reportData = { ...reportData };
    _reportData.headers = uniqueReasons;
    _reportData.reportList = records.map((item) => {
      item["FormatedPrintedDate"] =
        item.date_Printed && moment(item.date_Printed).format("MM-DD-YYYY");
      item["Badge_Type"] = item.reg_Price_Keyed > 0 ? " Yes" : "No";
      item["Description"] = item.description !== "" && item.description.trim();
      item["shrink_Scan_Time"] =
        item.shrink_Scan_Time && item.shrink_Scan_Time.trim() !== "00:00:00"
          ? moment(item.shrink_Scan_Time, ["HH:mm:ss"]).format("hh:mm:ss A")
          : "";
      return item;
    });
    return _reportData;
  };

  const getMarkdownHeaders = (reasonHeaders, isGroup = false) => {
    const headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    headers.push({
      ...new TableHeaderConfig(),
      Header: "Total",
      DataKey: "total",
      Width: 150,
      TypeOfData: "Price",
      Icon: "reg_price_keyed",
    });
    const headerStart =
      ReportConstants[isGroup ? "GroupDeptDataTable" : "GroupDataTable"];
    return [...headerStart, ...headers];
  };

  const handleMarkdownData = (isGroup = false, isDetailed = false) => {
    let _reportData = [];
    const { value: selectedTabValue } = selectedTab;
    switch (selectedTabValue) {
      case "2":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID === "";
        });
        break;
      case "3":
        _reportData = reportData.reportList.filter((res) => {
          return res.shrink_Confirmation_ID !== "";
        });
        break;
      case "4":
        _reportData = reportData.reportList.filter((res) => {
          return res.reclaim && res.reclaim.trim() === "Y";
        });
        break;
      default:
        _reportData = [...reportData.reportList];
        break;
    }

    if (!isDetailed) return groupMarkdownData(_reportData, isGroup);
    else return _reportData;
  };

  const groupMarkdownData = (_reportData: Array<any>, isGroup = false) => {
    const { value: selectedTabValue } = selectedTab;
    const reportGrpArr = Object.values(
      _reportData.reduce((repGrp, report) => {
        /*  const { store_ID, department } = report; */
        const store_ID = storeIDLabel;
        const department = departmentIDLabel;
        const _key = isGroup ? `${store_ID}_${department}` : store_ID;
        if (repGrp[_key]) {
          repGrp[_key].push(report);
        } else {
          repGrp[_key] = [report];
        }
        return repGrp;
      }, {})
    ).map((rep: Array<any>) => {
      const obj: any = {
        store_ID: rep[0].store_ID,
        store_ID1: rep[0].client_Store_N,
        total: 0,
        Badge_Type: "No",
      };
      if (isGroup) obj.department = rep[0].department;
      const priceKeyed = rep.reduce(
        (acc, item) => acc + (item["reg_Price_Keyed"] ?? 0),
        0
      );
      obj.Badge_Type = priceKeyed > 0 ? " Yes" : "No";
      obj.reg_price_keyed = priceKeyed > 0 ? true : false;
      reportData.headers.forEach((head) => {
        const _reasonPrice = rep
          .filter((_reportFltr) => _reportFltr.reason === head)
          .reduce(
            (acc, item) =>
              acc +
              (item[selectedTabValue === "3" ? "reg_Price" : "reg_Total"] ?? 0),
            0
          ); //).toFixed(2)
        obj[head] = _reasonPrice;
        obj.total += _reasonPrice;
      });
      return obj;
    });
    return reportGrpArr ?? [];
  };
  //#endregion

  const onDetailReportRowClick = (data: any, isShowAll = false) => {
    setClickedFilterData(data);
    const _reportsList = handleMarkdownData(false, true);
    const _filteredList = _reportsList.filter(
      (data) => data.department.trim() === String(showDepartment).trim()
    );
    if (storeIDLabel !== null && storeIDLabel !== undefined) {
      var LastFiltered = _filteredList.filter(
        (data) => data.store_ID === Number(storeIDLabel)
      );
    }
    var LastFiltered2 = LastFiltered.filter(
      (data) => data.reason === newMainLabel
    );
    const _data = { ...data };
    delete _data.store_ID1;
    const _headers = ReportConstants[selectedTab.label]; // getMarkdownHeaders(reportData.headers, true);
    setDetailTableReports(LastFiltered2);
    setDetailTableHeaders(_headers);
  };

  return (
    <>
      <div className="bg-container row m-0 mt-3 p-3">
        <div className="col-4 p-2 reportDescItem">
          <div className="icon">
            <Icon icon="material-symbols:storefront-outline" />
          </div>
          <div className="label">
            <p className="m-0">
              {
                /* selectedTab.value === "6" */ false
                  ? "Transferred From"
                  : "Store ID"
              }
            </p>
            <p className="m-0">{storeIDLabel}</p>
          </div>
        </div>

        <div className="col-3 p-2 reportDescItem">
          {!(/* isDonation */ false) && (
            <>
              <div className="icon">
                <Icon icon="material-symbols:store-outline" />
              </div>
              <div className="label">
                <p className="m-0">Department</p>
                <p className="m-0">{showDepartment}</p>
              </div>
            </>
          )}
        </div>

        <div className="col-1 p-2 reportDescItem">
          <div className="d-flex">
            <Dropdown
              className="mx-2 button-fixed"
              title="Export"
              appearance="primary"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                /*                 onClick={() => {
                  exportToExcel(detailTableReports, "Detailed Order Report");
                }} */
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>

      {
        <TableComponent
          isFromModal={true}
          tableDetails={detailTableHeaders}
          reportList={detailTableReports.sort((a, b) => {
            return a["store_ID"]
              .toString()
              .localeCompare(b["store_ID"].toString(), undefined, {
                numeric: true,
              });
          })}
          iconColumn={""}
          onTableRowClick={() => {}}
          totalTableRow={{}}
          isTotalRowRequired={false}
        />
      }
    </>
  );
};

export default DetailShrinkComponent;
