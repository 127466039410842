/* import { AxiosService } from "../../src/utils/axiosService"; */
import axios, { AxiosRequestConfig } from "axios";
import { stringDecompression } from "./stringLZService";

import Constants from "../../src/utils/constants";
const API_URL = {
  ordersList: "https://api.gotsystems.net/api/MainScreen/GetOrders",
  ordersByConfirmations:
    "https://api.gotsystems.net/api/Confirmation/CheckConfirmation",
  ordersByConfirmationsDetail:
    "https://api.gotsystems.net/api/Confirmation/CheckConfirmationDetail",
  // ordersByDepartment:
  //   "https://api.gotsystems.net/api/Orders/ViewOrdersByDepartment",
  ordersByDepartment2:
    "https://api.gotsystems.net/api/Orders/ViewOrdersByDepartment2",
  // ordersByDepartmentDetail:
  //   "https://api.gotsystems.net/api/Orders/ViewOrdersByConfirmation",
  ordersByDepartmentDetail2:
    "https://api.gotsystems.net/api/Orders/ViewOrdersByConfirmation2",
  // ordersByDepartmentByDeptDetail:
  //   "https://api.gotsystems.net/api/Orders/ViewOrdersDetails",
  ordersByDepartmentByDeptDetail2:
    "https://api.gotsystems.net/api/Orders/ViewOrdersDetails2",
  badItems: "https://api.gotsystems.net/api/Orders/BadItems",
  SaveOutOfStock: "https://api.gotsystems.net/api/Reports/SaveOutOfStock",
};

const createConfig = (): any => {
  // let ContextInfo = this.authService.ContextInfo().context;
  const config: AxiosRequestConfig = {
    baseURL: Constants.baseApiUrl,
    headers: {
      // Authorization: 'Bearer ' + ContextInfo.token,
      Accept: "application/json, text/javascript, */*; q=0.01",
      "Content-Type": "application/json",
    },
    // timeout: 30000, // 30 seconds

    withCredentials: false,
  };
  return config;
};

const AxiosService = {
  Post: (url: string, data: any): Promise<any> => {
    return axios.post(url, data, createConfig());
  },
};

export const SaveOutOfStock = async (data) => {
  debugger;
  const apiUrl = API_URL.SaveOutOfStock;

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return await response.json();
    } else {
      const errorData = await response.json(); // Get the error message from response body
      console.error("API response error:", errorData);
      throw new Error(`API request failed: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error in saveReportDonations:", error);
    throw error;
  }
};

export const getOrdersList = (requestObject) => {
  //console.log("Request Object - ", requestObject);
  /*      const requestObject1 = {
    _startDate: "2023-11-25",
    user_store_type: 0,
    _HeaderMenu: "MO,MO1,MT,MT1,MI,MP,MPI,MD,MR,M$,MS,MX,MB,MJ,MNC,MNO,S1,TRK",
    _headerType: "a,b,c",
    _status: "StatusList_Issues",
    _isMove: false,
  }; */
  return AxiosService.Post(API_URL.ordersList, requestObject);
};



// export const GetOrdersByDepReport = (
//   StoreID,
//   StartDate,
//   End_Date,
//   vendorId
// ) => {
//   const requestObject = {
//     Store_ID: StoreID,
//     Start_Date: StartDate,
//     End_Date: End_Date,
//     VendorList: vendorId,
//   };
//   return new Promise<any>((resolve, reject) => {
//     AxiosService.Post(API_URL.ordersByDepartment2, requestObject)
//       .then((response) => {
//         const { data = null } = response;
//         if (data) {
//           const jsonString = stringDecompression(data);
//           if (jsonString) resolve(JSON.parse(jsonString));
//         }
//         resolve([]);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };


// Main Order Layer1 View Order 2
export const GetOrdersMain = (
  StoreID,
  StartDate,
  End_Date,
  vendorId
) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: StartDate,
    End_Date: End_Date,
    VendorList: vendorId,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ordersByDepartment2, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const GetOrdersByDepDetailReport = (
//   StoreID,
//   StartDate,
//   End_Date,
//   vendorId,
//   DepartmentID
// ) => {
//   const requestObject = {
//     Store_ID: StoreID,
//     Start_Date: StartDate,
//     End_Date: End_Date,
//     VendorList: vendorId,
//     DepartmentList: DepartmentID.toString(),
//   };
//   return new Promise<any>((resolve, reject) => {
//     AxiosService.Post(API_URL.ordersByDepartmentDetail, requestObject)
//       .then((response) => {
//         const { data = null } = response;
//         if (data) {
//           const jsonString = stringDecompression(data);
//           if (jsonString) resolve(JSON.parse(jsonString));
//         }
//         resolve([]);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };


// Main Order Layer2 View Order 2
export const GetOrdersForDepartment = (
  StoreID,
  StartDate,
  End_Date,
  vendorId,
  DepartmentID
) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: StartDate,
    End_Date: End_Date,
    VendorList: vendorId,
    DepartmentList: DepartmentID.toString(),
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ordersByDepartmentDetail2, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const GetOrdersByDepDetailbyDepReport = (
//   StoreID,
//   StartDate,
//   End_Date,
//   vendorId,
//   DepartmentID
// ) => {
//   const requestObject = {
//     Store_ID: StoreID,
//     Start_Date: StartDate,
//     End_Date: End_Date,
//     VendorList: vendorId,
//     DepartmentList: DepartmentID.toString(),
//   };
//   return new Promise<any>((resolve, reject) => {
//     AxiosService.Post(API_URL.ordersByDepartmentByDeptDetail, requestObject)
//       .then((response) => {
//         const { data = null } = response;
//         if (data) {
//           const jsonString = stringDecompression(data);
//           if (jsonString) resolve(JSON.parse(jsonString));
//         }
//         resolve([]);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

//Main Order Layer3 View Order2

export const GetOrdersForConfID = (
  StoreID,
  StartDate,
  End_Date,
  vendorId,
  DepartmentID,
  ConfID
) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: StartDate,
    End_Date: End_Date,
    VendorList: vendorId,
    DepartmentList: DepartmentID.toString(),
    ConfList: ConfID.toString(),
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ordersByDepartmentByDeptDetail2, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetOrdersConfirmationList = (StoreID, fromDate, toDate) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: fromDate,
    End_Date: toDate,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ordersByConfirmations, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const GetOrdersConfirmationDetailList = (StoreID, ConfID) => {
  const requestObject = {
    Store_ID: StoreID,
    ConfIDList: ConfID,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ordersByConfirmationsDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// Bad Items
export const GetBadItems = (StoreID, Start_Date, End_Date, UpcItemNo) => {
  const requestObject = {
    Store_ID: StoreID,
    Start_Date: Start_Date,
    End_Date: End_Date,
    UpcItemNo: UpcItemNo
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.badItems, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
