import { IUserContext } from "../../models";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RoutingConstants from "../../utils/routingConstants";
import { StorageService } from "../../utils/sessionService";
import * as AuthService from "../../services/authService";
import * as UMSService from "../../services/umsService";
import "./style.login.css";

import {
  Container,
  Content,
  FlexboxGrid,
  Panel,
  Form,
  ButtonToolbar,
  Button,
} from "rsuite";
import "rsuite/dist/rsuite.min.css";
import Card from "components/dashboard/Card";

const SigninCallbackComponent1 = (props) => {
  const [data, setData] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  //
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    fetchUserList();
  }, []);

  const fetchUserList = async () => {
    try {
      const data = await UMSService.GetUserListForSuperAdmin();

      setData(data);
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };
  const validateEmail = (email) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const handleSubmit = () => {
    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
      return;
    }
    if (!password) {
      setPasswordError("Password is required");
      return;
    } else setPasswordError("");

    getUserDataByEmail(email, (result) => {
      const matchedUser = data.find(
        (user) => user.user_ID === result.user_ID && user.password === password
      );

      if (matchedUser) {
        if (result.role_Name !== "Super Admin") {
          if (result && result.store_ID !== 0) {
            getUserFileType(result.store_ID, "StoreGroup");
          } else {
            if (true) {
              getUserFileType(result.nID);
            }
          }
        }

        handleResultToken(email, result, (context) => {
          console.log("User Context Object - ", context);

          StorageService.setSLSItem("UserContext", context, false);
          props.setUserContext(context);
          navigate(RoutingConstants.default, { replace: true });
        });
      } else {
        alert("User not found");
      }
    });
  };
  const navigate = useNavigate();
  const { azureAuth } = props;

  const getUserDataByEmail = (email: any, callback?: any | void) => {
    if (email !== "")
      AuthService.getUserDataByEmail(email)
        .then((result) => {
          const { data } = result;

          // call back ile fonksiyondan gerekli veriyi alıyor getUserDataByEmail burada çağırma nedenim ilk olarak bu execute oluyor.
          //   getUsersGroupsData(data.user_ID, (nID) => {
          //     // var newData = nID.map((item) =>
          //     //   item.additional_Information.split(",")
          //     // );
          //     data.nID = Number(nID[0].groupStore_UID)
          //       ? Number(nID[0].groupStore_UID)
          //       : data.nID;
          //     data.nName = nID[0].description ? nID[0].description : data.nName;
          //     callback && callback(data);
          //   });
          getPagePrivilegesByRoleId(data.role_ID, (privilegeResult) => {
            data.privileges = privilegeResult;
            callback && callback(data);
          });

          InsertLoginHistoryData(data.user_ID);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            navigate(RoutingConstants.userDoesNotExist, { replace: true });
          }
          throw err;
        });
  };

  const handleResultToken = (
    authResult: any,
    result: any,
    callback?: any | void
  ) => {
    console.log("Handle Auth Token - ", authResult);
    console.log("Auth API Object - ", result);
    // const { accessToken, account } = authResult;
    const userContext: IUserContext = {
      token: "",
      userId: "b957d65d-48e7-448c-9762-300765fac5d2",
      userName: result.first_Name + " " + result.last_Name,
      store: result["store_ID"] ?? "",
      email: authResult ?? "",
      roleId: result["role_ID"],
      roleName: result["role_Name"],
      isAdmin: result["isAdmin"],
      isUser: result["isUser"],
      groupType: result["nID_Type"] ?? 0,
      groupTypeId: result["nID"] ?? 0,
      groupTypeName: result["nName"] ?? "",
      user_ID: result["user_ID"] ?? 0,
      privileges: result["privileges"] ?? [],
    };
    callback && callback(userContext);
  };

  const getPagePrivilegesByRoleId = (roleId: string, callback?: void | any) => {
    if (roleId)
      AuthService.getPagePrivilegesByRoleId(roleId)
        .then((result) => {
          const { data } = result;
          callback && callback(data);
        })
        .catch((err) => {
          throw err;
        });
  };

  const getUsersGroupsData = (user_ID: number, callback?: void | any) => {
    if (user_ID)
      AuthService.getUsersGroups(user_ID)
        .then((result) => {
          const { records } = result;
          //   var newData = records.map((item) =>
          //     item.additional_Information.split(",")
          //   );
          callback && callback(records);
        })
        .catch((err) => {
          throw err;
        });
  };

  const getUserFileType = (StoreOrIdGroup: number, UserType = null) => {
    AuthService.getUserFileType(StoreOrIdGroup, UserType)
      .then((result) => {
        const { records } = result;
        StorageService.setSLSItem("UserType", records, false);
        props.setUserFileType(records);
      })
      .catch((err) => {
        throw err;
      });
  };

  const InsertLoginHistoryData = (user_ID) => {
    const InsertLoginTime = {
      Login_UserID: user_ID,
      Status: 1,
      Login_DateTime: new Date(),
      Logout_DateTime: null,
    };

    UMSService.InsertLoginHistoryPortal(Array(InsertLoginTime))
      .then((result) => {
        console.log(result);
        //result && toast.success(` Successfully Logged in`);
      })
      .catch((err) => {
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Container>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <h2>Got Portal</h2>
        </div>
        <Content>
          <div className="login-container">
            <div className="card-wrapper">
              <Card>
                <Panel header={<h3>Login</h3>} style={{ padding: 20 }}>
                  <Form fluid onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.ControlLabel>Email</Form.ControlLabel>
                      <Form.Control
                        name="email"
                        type="email"
                        value={email}
                        onChange={(value) => {
                          setEmail(value);
                          setEmailError(
                            validateEmail(value) ? "" : "Invalid email format"
                          );
                        }}
                      />
                      {emailError && (
                        <span style={{ color: "red" }}>{emailError}</span>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.ControlLabel>Password</Form.ControlLabel>
                      <Form.Control
                        name="password"
                        type="password"
                        value={password}
                        onChange={(value) => setPassword(value)}
                      />
                      {passwordError && (
                        <span style={{ color: "red" }}>{passwordError}</span>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <ButtonToolbar>
                        <Button appearance="primary" color="blue" type="submit">
                          Sign in
                        </Button>
                        <Button appearance="link" disabled>
                          Forgot password?
                        </Button>
                      </ButtonToolbar>
                    </Form.Group>
                  </Form>
                </Panel>
              </Card>
            </div>
          </div>
        </Content>
      </Container>
      {/* <Container>
        <div style={{ textAlign: "center", marginTop: "100px" }}>
          <h2>Got Portal</h2>
        </div>
        <Content>
          {/* <FlexboxGrid justify="center" style={{ margin: 20 }}>
            <FlexboxGrid.Item> 
          <Card>
            <Panel header={<h3>Login</h3>} style={{ padding: 20 }}>
              <Form fluid>
                <Form.Group>
                  <Form.ControlLabel>Email</Form.ControlLabel>
                  <Form.Control
                    name="email"
                    type="email"
                    value={email}
                    // onChange={(value) => setEmail(value)}
                    onChange={(value) => {
                      setEmail(value);
                      setEmailError(
                        validateEmail(value) ? "" : "Invalid email format"
                      );
                    }}
                  />
                  {emailError && (
                    <span style={{ color: "red" }}>{emailError}</span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Password</Form.ControlLabel>
                  <Form.Control
                    name="password"
                    type="password"
                    value={password}
                    onChange={(value) => setPassword(value)}
                  />
                  {passwordError && (
                    <span style={{ color: "red" }}>{passwordError}</span>
                  )}
                </Form.Group>
                <Form.Group>
                  <ButtonToolbar>
                    <Button
                      appearance="primary"
                      color="blue"
                      onClick={handleSubmit}
                      onKeyPress={handleKeyPress}
                    >
                      Sign in
                    </Button>
                    <Button appearance="link" disabled>
                      Forgot password?
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Form>
            </Panel>
          </Card>
          {/* </FlexboxGrid.Item>
          </FlexboxGrid> 
        </Content>
      </Container> */}
    </>
  );
};

export default SigninCallbackComponent1;
