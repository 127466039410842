import React from "react";
import { Grid, Row, Col, Form, Button, ButtonToolbar } from "rsuite";
import { SelectPicker } from "rsuite";

const GenericForm = ({
  formTitle,
  formFields,
  buttons,
  formRef,
  model,
  setFormValue,
  handleSubmit,
  additionalProps,
}) => {
  return (
    <Form
      ref={formRef}
      model={model}
      onChange={setFormValue}
      onSubmit={handleSubmit}
      {...additionalProps}
    >
      <h3 style={{ marginBottom: 20 }}>{formTitle}</h3>
      <Grid fluid>
        <Row>
          {formFields.map((field) => {
            return (
              <Col key={field.controlId} xs={24} sm={24} md={12} lg={12}>
                <Form.Group controlId={field.controlId}>
                  <Form.ControlLabel>
                    {field.label}
                    {field.required && <span className="required">*</span>}
                  </Form.ControlLabel>
                  {field.type === "select" ? (
                    <Form.Control
                      name={field.name}
                      style={{ width: 307 }}
                      data={field.data}
                      accepter={SelectPicker}
                      {...field.extraProps}
                    />
                  ) : (
                    <Form.Control
                      name={field.name}
                      type={field.type || "text"}
                      style={{ width: 307 }}
                      {...field.extraProps}
                    />
                  )}
                  {field.helpText && (
                    <Form.HelpText tooltip>{field.helpText}</Form.HelpText>
                  )}
                </Form.Group>
              </Col>
            );
          })}

          {/* Super Admin */}

          <Col xs={24} sm={24} md={12} lg={12}>
            {additionalProps.isShowGroupOrStore && !additionalProps.isAWG && (

              <Form.Group controlId="Group_Type_ID">
                <Form.ControlLabel>
                  Select Group
                  <span className="required">*</span>
                </Form.ControlLabel>
                <Form.Control
                  style={{ width: 307 }}
                  name="Group_Type_ID"
                  valueKey="groupStore_uid"
                  labelKey="groupStore_desc"
                  data={additionalProps.groupAndGroupID}
                  accepter={SelectPicker}
                />
              </Form.Group>

            )}
          </Col>
        </Row>
        {/* ADMIN USER */}
        <Row>
          {!additionalProps.isAWG &&
            additionalProps.isShowGroupOrStore &&
            additionalProps.RoleID === 2 && (
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Group controlId="Group_Type_ID">
                  <Form.ControlLabel>
                    Select Store:
                    <span className="required">*</span>
                  </Form.ControlLabel>
                  <Form.Control
                    style={{ width: 307 }}
                    name="Group_Type_ID"
                    valueKey="groupStore_uid"
                    labelKey="groupStore_desc"
                    data={additionalProps.groupAndGroupID}
                    accepter={SelectPicker}
                  />
                </Form.Group>
              </Col>
            )}
        </Row>
        {/* GROUP USER */}
        <Row>
          {additionalProps.isShowGroupOrStore && additionalProps.isAWG && (
            <Col xs={24} sm={24} md={12} lg={12}>
              {" "}
              <Form.Group controlId="Group_Type_ID">
                <Form.ControlLabel>
                  {additionalProps.RoleID === 1
                    ? "Select Group"
                    : "Select Store"}
                  <span className="required">*</span>
                </Form.ControlLabel>
                <Form.Control
                  style={{ width: 307 }}
                  name="Group_Type_ID"
                  valueKey="groupStore_uid"
                  labelKey="groupStore_desc"
                  data={additionalProps.groupAndGroupID}
                  accepter={SelectPicker}
                />
              </Form.Group>
            </Col>
          )}
        </Row>
      </Grid>
      <ButtonToolbar>
        {buttons.map((button, index) => (
          <Button key={index} {...button.props}>
            {button.label}
          </Button>
        ))}
      </ButtonToolbar>
    </Form>
  );
};

export default GenericForm;
