export const ConfirmationListConstants = {
    "ConfirmationListConstants":  [
        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Delivery", DataKey: "delivery_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Day", DataKey: "day", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Items", DataKey: "items", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pieces", DataKey: "quantity", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Received Time", DataKey: "sent_DateTime", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Deadline Time", DataKey: "received_Time", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Conf #", DataKey: "got_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "AWG Conf #", DataKey: "awG_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Serial Number", DataKey: "serial_Number", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    "ConfirmationListConstantsDepartment":  [
        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        //{ Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Delivery", DataKey: "delivery_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Day", DataKey: "day", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Items", DataKey: "items", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pieces", DataKey: "quantity", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Received Time", DataKey: "sent_DateTime", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Deadline Time", DataKey: "received_Time", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Conf #", DataKey: "got_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "AWG Conf #", DataKey: "awG_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Serial Number", DataKey: "serial_Number", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],
 
    "ConfirmationListDetailConstants":  [
       // { Header: "Store ID", DataKey: "store_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       
       { Header: "Key In", DataKey: "key_in", Width: 50, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       { Header: "Item ID ", DataKey: "item_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        
       { Header: "UPC", DataKey: "upc", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "Conf #", DataKey: "confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User ID ", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        { Header: "Description", DataKey: "description", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Size", DataKey: "size", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pieces", DataKey: "quantity", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Serial Number", DataKey: "serial_Number", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],


    "ConfirmationListDetailFilteredConstants":  [
        // { Header: "Store ID", DataKey: "store_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
         
        { Header: "Key In", DataKey: "key_in", Width: 50, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID ", DataKey: "user_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Conf #", DataKey: "confirmation_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        
        { Header: "Item ID ", DataKey: "item_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        
        { Header: "UPC", DataKey: "upc", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
 
         { Header: "Description", DataKey: "description", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
         { Header: "Size", DataKey: "size", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
         { Header: "Pieces", DataKey: "quantity", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
         //{ Header: "Serial Number", DataKey: "serial_Number", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
 
     ],
     "DepartmentConstants":  [
        // { Header: "Store ID", DataKey: "store_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
         
        { Header: "Department", DataKey: "department", Width: 50, isFixed: true, isSortable: true, Align: "Center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Items ", DataKey: "length", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       
 
     ],


}