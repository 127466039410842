import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  CheckPicker,
  Dropdown,
  IconButton,
  Modal,
  PickerHandle,
} from "rsuite";
import { Icon } from "@iconify/react";
import DetailStoreUseComponent from "./DetailStoreUseComponent";

const DepToStoreStore = (props: any) => {
  const {
    value,
    toDate,
    fromDate,
    showDepartment,
    MainLabel,
    ref4,
    departmentData,
    userContext,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [storeIDLabel, setStoreIDLabel] = useState("");
  const [departmentIDLabel, setDepartmentIDLabel] = useState("1");
  const [isDetailView, setDetailView] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [specificDepartment, setSpecificDepartment] = useState(showDepartment);
  if (value !== undefined && value !== null) {
    var labels = Object.keys(value);
  }
  if (value !== undefined && value !== null) {
    var percentageLabel = labels.map((label) => `Store: ${label}`);
  }
  useEffect(() => {
    setSpecificDepartment(showDepartment);
  }, [showDepartment]);

  useEffect(() => {
    setDepartmentIDLabel(
      departmentData[specificDepartment].poS_Department_ID.toString()
    );
  }, [specificDepartment, departmentData]);

  if (value !== undefined && value !== null) {
    var showRightDataNoPerSign = labels.map((label) =>
      parseFloat(value[label][MainLabel])
    );
    var combineObject = labels.map((label, index) => ({
      label,
      value: showRightDataNoPerSign[index],
    }));
    combineObject.sort((a, b) => b.value - a.value);
  }

  if (value !== undefined && value !== null) {
    var newSortLabels = [];
    var newSortValues = [];
    combineObject.map((object) => {
      return newSortLabels.push(object.label);
    });
    combineObject.map((object) => {
      return newSortValues.push(
        object.value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })
      );
    });
  }
  if (value !== undefined && value !== null) {
    var showRightDataNoCurr = labels.map((label) =>
      parseFloat(value[label][MainLabel])
    );
    const numberValue = showRightDataNoCurr.map((value) => Number(value));
    var newPercentages = calculatePercentages(numberValue);
  }
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  const newMainLabel = MainLabel.includes("_")
    ? MainLabel.replaceAll("_", " ")
    : MainLabel;

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,
    plugins: {
      legend: {
        position: "right" as const,
        display: false,
      },
      title: {
        display: true,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  const data = {
    labels: percentageLabel,
    datasets: [
      {
        data: newPercentages,
        backgroundColor: [
          "rgba(20, 36, 89, 0.5)",
          "rgba(23, 107, 160, 0.5)",
          "rgba(25, 170, 222, 0.5)",
          "rgba(26, 201, 230, 0.5)",
          "rgba(27, 212, 212, 0.5)",
          "rgba(29, 228, 189, 0.5)",
          "rgba(199, 249, 238, 0.5)",
        ],
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 15,
        hoverOffset: 5,
      },
    ],
  };

  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];

  return (
    <div ref={ref4}>
      <div className="chart-heading">
        {`${newMainLabel} in ${specificDepartment} Store Breakdown`}
      </div>
      <div className="chart-container">
        {<Doughnut options={options} data={data} />}
      </div>
      <div className="details-chart">
        <ul>
          {newSortLabels?.map((label, index) => {
            return (
              <li className="chart-li">
                <div
                  onClick={() => {
                    setOpen(true);
                    setStoreIDLabel(label);
                  }}
                  style={{
                    cursor: "auto",
                    color: charTextColor[index],
                    fontWeight: "700",
                    filter: "contrast(100)",
                  }}
                  className="chart-label"
                >
                  <span>Store:</span>
                  {`${label}`}
                </div>
                <span className="percantage">{` ${newSortValues[index]}`}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        {" "}
        <Button
          appearance="primary"
          onClick={(e) => {
            setSecondLayer(true);
            setThirdLayer(false);
          }}
        >
          Previous
        </Button>
      </div>
      <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isDetailView && (
              <span
                className="mx-2 filter"
                onClick={() => {
                  setDetailView(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
            )}
            Track IT Store Use Record
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DetailStoreUseComponent
            fromDate={props.fromDate}
            toDate={props.toDate}
            userContext={userContext}
            storeIDLabel={storeIDLabel}
            departmentIDLabel={departmentIDLabel}
            showDepartment={showDepartment}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DepToStoreStore;
