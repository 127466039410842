/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from "@iconify/react";
import { NavLink, useLocation } from "react-router-dom";
import RoutingConstants from "../../utils/routingConstants";
import GOTLogo from "../../assets/images/GOTLogo.png";
import { IUserContext, IUserFile, SideNavConfig } from "../../models";
import { StorageService } from "../../utils/sessionService";
import { useEffect } from "react";

const SideNavComponent = (props) => {
  const { isMenuCollapsed, userFileType, setMenuCollapse } = props;

  useEffect(() => {
    const handleResize = () => {
      setMenuCollapse(window.innerWidth <= 768); // Adjust the threshold as needed
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [setMenuCollapse]);

  const location = useLocation();
  const redirectURL: string = process.env.REACT_APP_GOT_RPT_URL;
  const {
    privileges = null,
    roleName = null,
    groupTypeName = null,
    store = null,
    groupType = null,
    groupTypeId = null,
    isAdmin = null,
  }: IUserContext = StorageService.getSLSItem("UserContext");
  userFileType &&
    userFileType.push({
      description: "Settings",
      file_Type: "Settings",
    });
  const sideNavConfig: SideNavConfig[] = [
    {
      key: "dashboard",
      label: "Dashboard",
      route: RoutingConstants.default,
      type: "nav-item",
      icon: "material-symbols:home-outline",
      file_Type: "",
      shouldRedirect: false,
      isCollapse: false,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
    },
    // groupType === 2 && {
    //   key: "main_screen",
    //   label: "Main Screen",
    //   route: RoutingConstants.dashboard,
    //   type: "nav-item",
    //   icon: "mdi:users",
    //   shouldRedirect: false,
    //   isCollapse: false,
    //   menuId: 2,
    //   forRoles: [],
    //   exceptionRoles: [],
    // },
    /*     {
      key: "main_screen_portal",
      label: "Main Screen Portal",
      route: RoutingConstants.dashboard,
      type: "nav-item",
      icon: "mdi:users",
      shouldRedirect: false,
      isCollapse: false,
      menuId: 2,
      forRoles: [],
      exceptionRoles: [],
    }, */

    // Horizon grubu için gösterme
    groupTypeId !== 176 &&
    groupType !== 3 && {
      key: "orders",
      label: "Orders",
      route: "#",
      type: "nav-item",
      icon: "material-symbols:list",
      shouldRedirect: false,
      isCollapse: true,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
      exceptionStore: [],

      childRoutes: [
        {
          key: "View_orders_by_department",
          label: "View Orders by Department",
          route: RoutingConstants.reportOrdersByDepartment,
          type: "nav-item",
          icon: "map:department-store",
          file_Type: "MO",

          shouldRedirect: false,
          isCollapse: false,
          menuId: 16,
          forRoles: [],
          exceptionRoles: [],
        },
        {
          key: "ordersonhold",
          label: "Orders On HOLD",
          route: RoutingConstants.ordersonhold,
          type: "nav-item",
          icon: "carbon:view-filled",
          file_Type: "MO",

          shouldRedirect: false,
          isCollapse: false,
          menuId: 16,
          forRoles: [],
          exceptionRoles: [],
        },
        {
          key: "viewconfirmations",
          label: "View Confirmations",
          route: RoutingConstants.ordersConfirmationListPortal,
          type: "nav-item",
          icon: "carbon:view-filled",
          file_Type: "MO",

          shouldRedirect: false,
          isCollapse: false,
          menuId: 16,
          forRoles: [],
          exceptionRoles: [],
        },
        {
          key: "last_day_to_save",
          label: "Last Day To Save",
          route: RoutingConstants.reportLastDayToSave,
          type: "nav-item",
          icon: "icomoon-free:price-tag",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          file_Type: "Settings",
          forRoles: [],
          exceptionRoles: ["User"],
        },
        {
          key: "iteminquiry",
          label: "Item Inquiry",
          route: RoutingConstants.ordersBadItems,
          type: "nav-item",
          icon: "carbon:query",
          file_Type: "MO",

          shouldRedirect: false,
          isCollapse: false,
          menuId: 16,
          forRoles: [],
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "out_of_stock",
          label: "Out Of Stock",
          route: RoutingConstants.ordersOutOfStock,
          type: "nav-item",
          icon: "healthicons:stock-out",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          file_Type: "Settings",
          forRoles: [],
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "order_savings",
          label: "Order Savings",
          route: RoutingConstants.order_savings,
          type: "nav-item",
          icon: "mdi:invoice-arrow-right",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          file_Type: "GRD",
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "product_mix_optimization",
          label: "Product Mix Optimization",
          route: RoutingConstants.reportProductMixOptimization,
          type: "nav-item",
          icon: "fluent-mdl2:product",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          file_Type: "Settings",
          forRoles: [],
          exceptionRoles: [],
        },
        /*         {
      key: "ordering",
      label: "Ordering",
      route: RoutingConstants.reportOrdering,
      type: "nav-item",
      icon: "icon-park-outline:order",
      shouldRedirect: true,
      isCollapse: false,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
    }, */
        /*         {
      key: "View_orders_by_department",
      label: "View Orders by Department",
      route: RoutingConstants.orders,
      type: "nav-item",
      icon: "map:department-store",
      shouldRedirect: true,
      isCollapse: false,
      menuId: 16,
      forRoles: [],
      exceptionRoles: [],
    },
    {
      key: "viewonfirmations",
      label: "View Confirmations",
      route: RoutingConstants.ordersConfirmationList,
      type: "nav-item",
      icon: "carbon:view-filled",
      shouldRedirect: true,
      isCollapse: false,
      menuId: 16,
      forRoles: [],
      exceptionRoles: [],
    }, */
      ],
    },
    /* 
    {
      key: "orders",
      label: "Orders",
      route: RoutingConstants.orders,
      type: "nav-item",
      icon: "material-symbols:list",
      shouldRedirect: true,
      isCollapse: false,
      menuId: 16,
      forRoles: [],
      exceptionRoles: [],
    }, */
    groupType !== 3 && {
      key: "reports",
      label: "Reports",
      route: RoutingConstants.utilities,
      type: "nav-item",
      icon: "material-symbols:file-copy-outline",
      shouldRedirect: false,
      isCollapse: true,
      menuId: null,
      forRoles: [],
      exceptionRoles: [groupType === 2 ? "2" : null],
      exceptionStore: [],

      childRoutes: [
        // {
        //     key: "order_book",
        //     label: "Order Book",
        //     route: RoutingConstants.reportOrders,
        //     type: "nav-item",
        //     icon: "material-symbols:menu-book",
        //     shouldRedirect: false,
        //     isCollapse: false,
        //     menuId: null,
        //     forRoles: [],
        //     exceptionRoles: [],
        // },
        // {
        //     key: "commodity",
        //     label: "Commodity",
        //     route: RoutingConstants.reportCommodity,
        //     type: "nav-item",
        //     icon: "fa-regular:newspaper",
        //     shouldRedirect: false,
        //     isCollapse: false,
        //     menuId: null,
        //     forRoles: [],
        //     exceptionRoles: [],
        // },
        // {
        //     key: "track_it",
        //     label: "Track IT",
        //     route: RoutingConstants.reportTrackIt,
        //     type: "nav-item",
        //     icon: "tabler:file-search",
        //     shouldRedirect: false,
        //     isCollapse: false,
        //     menuId: null,
        //     forRoles: [],
        //     exceptionRoles: [],
        // },

        {
          key: "track_it_group",
          label: "Track IT",
          route: RoutingConstants.reportTrackItByGroup,
          type: "nav-item",
          icon: "tabler:file-search",
          file_Type: "TRK",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        {
          key: "markdown",
          label: "Markdown",
          route: RoutingConstants.reportMarkdown,
          type: "nav-item",
          icon: "lucide:book-open-check",
          file_Type: "CPN",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        {
          key: "grind_log",
          label: "Grind Log",
          route: RoutingConstants.reportGrindLog,
          type: "nav-item",
          icon: "game-icons:manual-meat-grinder",
          file_Type: "GRD",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        /*                  {
                     key: "donation",
                     label: "Donation",
                     route: RoutingConstants.reportDonation,
                     type: "nav-item",
                     icon: "mdi:donation-outline",
                     shouldRedirect: false,
                     isCollapse: false,
                    menuId: null,
                     forRoles: [],
                     exceptionRoles: [],
                 }, */

        groupTypeId !== 176 && {
          key: "receiving",
          label: "Receiving",
          route: RoutingConstants.reportReceiving,
          type: "nav-item",
          icon: "mdi:invoice-receive",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "credits",
          label: "Credits",
          route: RoutingConstants.reportCredits,
          type: "nav-item",
          icon: "tabler:clock-dollar",
          file_Type: "M$",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },

        groupTypeId !== 176 && {
          key: "inventory_report",
          label: "Inventory",
          route: RoutingConstants.reportInventory,
          type: "nav-item",
          icon: "material-symbols:inventory",
          file_Type: "MP",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],

          exceptionRoles: [],
        },

        groupTypeId !== 176 && {
          key: "placement",
          label: "Placement",
          route: RoutingConstants.reportPlacement,
          type: "nav-item",
          icon: "arcticons:attestationdedeplacement",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          file_Type: "Settings",
          forRoles: [],
          exceptionRoles: [],
        },
      ],
    },

    groupType === 3 && {
      key: "reports",
      label: "Reports",
      route: RoutingConstants.utilities,
      type: "nav-item",
      icon: "material-symbols:file-copy-outline",
      shouldRedirect: false,
      isCollapse: true,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
      exceptionStore: [],

      childRoutes: [
        {
          key: "grind_log",
          label: "Grind Log",
          route: RoutingConstants.reportGrindLog,
          type: "nav-item",
          icon: "game-icons:manual-meat-grinder",
          file_Type: "GRD",
          shouldRedirect: false,
          isCollapse: false,
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
      ],
    },

    groupType !== 2 &&
    groupType !== 3 && {
      key: "settings",
      label: "Settings",
      route: RoutingConstants.utilities,
      type: "nav-item",
      icon: "mdi:cog-outline",
      shouldRedirect: false,
      isCollapse: true,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
      exceptionStore: [],

      childRoutes: [
        groupTypeId !== 176 && {
          key: "delivery_schedule",
          label: "Delivery Schedule",
          route: RoutingConstants.delivery_schedule,
          type: "nav-item",
          icon: "mdi:truck-delivery-outline",
          shouldRedirect: false,
          isCollapse: false,
          file_Type: "Settings",
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "equipment_by_store",
          label: "Equipment By Store",
          route: RoutingConstants.equipment_by_store,
          type: "nav-item",
          icon: "mdi:store-cog-outline",
          shouldRedirect: false,
          isCollapse: false,
          file_Type: "Settings",
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        groupTypeId !== 176 && {
          key: "markdown-setup",
          label: "Markdown Setup",
          route: RoutingConstants.markdown_setup,
          type: "nav-item",
          icon: "ic:baseline-discount",
          shouldRedirect: false,
          isCollapse: false,
          file_Type: "Settings",
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
        // groupTypeId !== 176 && {
        //   key: "milk_order_book",
        //   label: "Milk Order Book",
        //   route: RoutingConstants.milk_order_book,
        //   type: "nav-item",
        //   icon: "tabler:milk",
        //   file_Type: "Settings",
        //   shouldRedirect: false,
        //   isCollapse: false,
        //   menuId: null,
        //   forRoles: [],
        //   exceptionRoles: [],
        // },
        // groupTypeId !== 176 && {
        //   key: "milk_delivery_schedule",
        //   label: "Milk Delivery Schedule",
        //   route: RoutingConstants.milk_delivery_schedule,
        //   type: "nav-item",
        //   file_Type: "Settings",
        //   icon: "icon-park-outline:milk-one",
        //   shouldRedirect: false,
        //   isCollapse: false,
        //   menuId: null,
        //   forRoles: [],
        //   exceptionRoles: [],
        // },
        // groupTypeId !== 176 && {
        //   key: "order_receipt",
        //   label: "Order Receipt",
        //   file_Type: "Settings",
        //   route: RoutingConstants.order_receipt,
        //   type: "nav-item",
        //   icon: "material-symbols:receipt",
        //   shouldRedirect: false,
        //   isCollapse: false,
        //   menuId: null,
        //   forRoles: [],
        //   exceptionRoles: [],
        // },
        // groupTypeId !== 176 && {
        //   key: "order_detail_amp",
        //   label: "Order Detail Amp",
        //   file_Type: "Settings",
        //   route: RoutingConstants.order_detail_amp,
        //   type: "nav-item",
        //   icon: "carbon:order-details",
        //   shouldRedirect: false,
        //   isCollapse: false,
        //   menuId: null,
        //   forRoles: [],
        //   exceptionRoles: [],
        // },
      ],
    },

    groupType === 3 && {
      key: "settings",
      label: "Settings",
      route: RoutingConstants.utilities,
      type: "nav-item",
      icon: "mdi:cog-outline",
      shouldRedirect: false,
      isCollapse: true,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
      exceptionStore: [],

      childRoutes: [
        groupTypeId !== 176 && {
          key: "equipment_by_store",
          label: "Equipment By Store",
          route: RoutingConstants.equipment_by_store,
          type: "nav-item",
          icon: "mdi:store-cog-outline",
          shouldRedirect: false,
          isCollapse: false,
          file_Type: "Settings",
          menuId: null,
          forRoles: [],
          exceptionRoles: [],
        },
      ],
    },

    groupType === 2 && {
      key: "delivery_schedule",
      label: "Delivery Schedule",
      route: RoutingConstants.delivery_schedule,
      type: "nav-item",
      icon: "mdi:truck-delivery-outline",
      shouldRedirect: false,
      isCollapse: false,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
    },
    (groupType === 2 || groupTypeId === 176) && {
      key: "equipment_by_store",
      label: "Equipment By Store",
      route: RoutingConstants.equipment_by_store,
      type: "nav-item",
      icon: "mdi:store-cog-outline",
      shouldRedirect: false,
      isCollapse: false,
      menuId: null,
      forRoles: [],
      exceptionRoles: [],
    },
  ];

  const sideNavFooterConfig: SideNavConfig[] = [
    {
      key: "system_Settings",
      label: "System Settings",
      route: RoutingConstants.userManagementUsers,
      type: "nav-item",
      icon: "mdi:cog-outline",
      shouldRedirect: false,
      isCollapse: false,
      menuId: null,
      forRoles: ["Admin", "Super Admin"],
      exceptionRoles: ["AWG", "User"],
      childRoutes: [
        {
          key: "user_management",
          label: "User Management",
          route: RoutingConstants.userManagement,
          type: "nav-item",
          icon: "mdi:users",
          shouldRedirect: false,
          isCollapse: true,
          menuId: null,
          forRoles: ["Admin", "Super Admin"],
          exceptionRoles: ["AWG", "User"],
          childRoutes: [
            {
              key: "roles",
              label: "Roles",
              route: RoutingConstants.userManagementRoles,
              type: "nav-item",
              icon: "",
              shouldRedirect: false,
              isCollapse: false,
              menuId: 3,
              forRoles: [],
              exceptionRoles: [],
            },
            {
              key: "users",
              label: "Users",
              route: RoutingConstants.userManagementUsers,
              type: "nav-item",
              icon: "",
              shouldRedirect: false,
              isCollapse: false,
              menuId: 6,
              forRoles: [],
              exceptionRoles: [],
            },
            {
              key: "login_history",
              label: "LoginHistory",
              route: RoutingConstants.userManagementLoginHistory,
              type: "nav-item",
              icon: "",
              shouldRedirect: false,
              isCollapse: false,
              menuId: 9,
              forRoles: [],
              exceptionRoles: [],
            },
            /*             {
              key: "default_privileges",
              label: "Default Privileges",
              route: RoutingConstants.userManagementDefaultPrivileges,
              type: "nav-item",
              icon: "",
              shouldRedirect: true,
              isCollapse: false,
              menuId: 10,
              forRoles: [],
              exceptionRoles: [],
            }, */
          ],
        },
      ],
    },
  ];

  const getRedirectionURL = (url) => {
    debugger;
    window.open(redirectURL + url, "_blank");
    if (window.innerWidth <= 768) {
      setMenuCollapse(true);
      console.log(isMenuCollapsed);
    }
  };

  const renderSideNavItems = (itemArr, getRedirectionURL, setMenuCollapse) => {
    return itemArr
      .filter((_sideNav) => {
        let isValid = false;

        isValid =
          _sideNav.menuId !== null
            ? privileges
              ? privileges.findIndex(
                (_x) => _x.page_ID === _sideNav.menuId && _x.status === true
              ) > -1
              : true
            : true;
        if (isValid)
          isValid =
            (_sideNav.forRoles.length > 0
              ? _sideNav.forRoles.findIndex((_rl) => _rl === roleName) < 0
              : false) ||
              (_sideNav.exceptionRoles.length > 0
                ? _sideNav.exceptionRoles[0] === "2"
                : false)
              ? false
              : true;

        return isValid;
      })
      .map((_sideNav, _sideNavIdx) => {
        return renderNavItems(
          _sideNav,
          _sideNavIdx,
          false,
          getRedirectionURL,
          setMenuCollapse
        );
      });
  };

  const renderNavItems = (
    navItem,
    navItemIdx,
    isChild,
    getRedirectionURL,
    setMenuCollapse
  ) => {
    const { label, icon, route, shouldRedirect, isCollapse } = navItem;
    if (!isCollapse)
      return shouldRedirect ? (
        <div
          className={`leftNavMenuItem ${icon !== "" ? (isChild ? "indentIcon" : "") : "noIcon"
            }`}
          onClick={() => getRedirectionURL(route, setMenuCollapse)}
          key={navItemIdx}
        >
          {icon !== "" && (
            <div className="leftNavMenuItemIcon">
              <Icon icon={icon} />
            </div>
          )}
          <div className="leftNavMenuItemText">{label}</div>
        </div>
      ) : (
        <NavLink
          className={({ isActive }) =>
            [
              `leftNavMenuItem ${navItem.icon !== "" ? (isChild ? "indentIcon" : "") : "noIcon"
              }`,
              isActive ? `active` : null,
            ]
              .filter(Boolean)
              .join(" ")
          }
          state={{ ...(location.state ?? {}), headerTitle: label }}
          to={route}
          title={label}
          key={navItemIdx}
          onClick={() => {
            if (window.innerWidth <= 768) {
              setMenuCollapse(true);
            }
          }}
        >
          <div className="leftNavMenuItemIcon">
            <Icon icon={icon} />
          </div>
          <div className="leftNavMenuItemText">{label}</div>
        </NavLink>
      );
    else
      return collapseNavItem(
        navItem,
        navItemIdx,
        isChild,
        getRedirectionURL,
        setMenuCollapse
      );
  };

  const collapseNavItem = (
    navItem,
    navItemIdx,
    isChild,
    getRedirectionURL,
    setMenuCollapse
  ) => {
    const { key, label, icon, childRoutes } = navItem;
    return (
      <>
        <a
          href="#"
          className={`leftNavMenuItem ${icon !== "" ? (isChild ? "indentIcon" : "") : "noIcon"
            }`}
          data-bs-toggle="collapse"
          data-bs-target={`#${key}MenuCollapse`}
          aria-expanded="false"
          aria-controls={`${key}MenuCollapse`}
          title={label}
          key={navItemIdx}
        >
          <div className="leftNavMenuItemIcon">
            <Icon icon={icon} />
          </div>
          <div className="leftNavMenuItemText">{label}</div>
        </a>
        <div
          className={`collapse ${isChild ? "indentIcon" : ""}`}
          id={`${key}MenuCollapse`}
        >
          {childRoutes
            .filter((_sideNav) => {
              let isValid = false;
              if (roleName === "Super Admin") {
                isValid = true;
              } else if (
                groupTypeId === 0 &&
                groupTypeName === "" &&
                store === 0
              ) {
                isValid = true;
              } else if (
                roleName !== "Super Admin" ||
                (groupTypeName !== "" && store === 0 && groupTypeId !== 0)
              )
                isValid = _sideNav.file_Type
                  ? userFileType
                    ? userFileType.findIndex(
                      (_x) => _x.file_Type === _sideNav.file_Type
                    ) > -1
                    : false
                  : false;
              if (isValid)
                isValid =
                  (_sideNav.forRoles.length > 0
                    ? _sideNav.forRoles.findIndex((_rl) => _rl === roleName) < 0
                    : false) ||
                    (_sideNav.exceptionRoles.length > 0
                      ? _sideNav.exceptionRoles[0] === "2"
                      : false)
                    ? false
                    : true;
              return isValid;
            })
            .map((_chldNav, _chldNavIdx) => {
              return renderNavItems(
                _chldNav,
                _chldNavIdx,
                true,
                getRedirectionURL,
                setMenuCollapse
              );
            })}
        </div>
      </>
    );
  };

  return (
    <>
      {/* change collapse and expcand */}
      <div
        className={`leftNav ${isMenuCollapsed ? `showCollapse` : `showExpand`}`}
      >
        <div className="leftNavHeader">
          <img src={GOTLogo} alt={`GOT Logo`} />
          {isMenuCollapsed ? (
            <></>
          ) : (
            <div className="leftNavHeaderText">G.O.T. Systems </div>
          )}
        </div>
        <div className="leftNavContainer">
          <div className="leftNavMenu leftNavBody">
            {renderSideNavItems(
              sideNavConfig,
              getRedirectionURL,
              setMenuCollapse
            )}
          </div>
          <div className="leftNavMenu leftNavFooter">
            {renderSideNavItems(
              sideNavFooterConfig,
              getRedirectionURL,
              setMenuCollapse
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SideNavComponent;
