export const ReportConstants = {
    "SectionsToScan": [
        { Header: "Aisle / Side", DataKey: "aisleSide", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Section", DataKey: "section_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        { Header: "Week to Scan", DataKey: "need_To_Scan", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    "PlacementHistory": [
        { Header: "Store ID", DataKey: "store_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        { Header: "Aisle / Side", DataKey: "aisleSide", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Section", DataKey: "section_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Shelf", DataKey: "shelf_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Face", DataKey: "face", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Week to Scan", DataKey: "mod_Date", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Status", DataKey: "reason_Status", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Date Discontinue", DataKey: "date_Discontinue", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        

    ],

    "ActivePlacement": [
        { Header: "Store ID", DataKey: "store_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        { Header: "Aisle / Side", DataKey: "aisleSide", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Section", DataKey: "section_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Shelf", DataKey: "shelf_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Face", DataKey: "face", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Status", DataKey: "reason_Status", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Date Discontinue", DataKey: "date_Discontinue", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    "StorePlacementSummary": [
        //{ Header: "Store ID", DataKey: "store_ID", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID", DataKey: "user_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Confirmation ID", DataKey: "confirmation_ID", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Date", DataKey: "start_Date", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Start Time", DataKey: "start_Scan", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "End Scan", DataKey: "end_Scan", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Time", DataKey: "total_Min", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Scan Time", DataKey: "total_Scan", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Scan Seconds", DataKey: "scan_Seconds", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Avg ScanTime", DataKey: "avg_ScanTime1", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Items", DataKey: "cnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Sections", DataKey: "section_Cnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Shelves", DataKey: "shelf_Cnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

    ],

    "GroupPlacementSummary": [
        { Header: "Store ID", DataKey: "store_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Total Time", DataKey: "total_Time", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Scan Time", DataKey: "scan_Seconds", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Scan Seconds", DataKey: "scan_Seconds", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Avg ScanTime", DataKey: "avg_ScanTime", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Items", DataKey: "cnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Sections", DataKey: "section_Cnt", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "%", DataKey: "section_Perc", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

    ],

    "PlacementByConfID": [
        { Header: "Item", DataKey: "item_ID", Width: 10, isFixed: true, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Conf#", DataKey: "confirmation_ID", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "UPC", DataKey: "upc", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Aisle / Side", DataKey: "aisleSide", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Section", DataKey: "section_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Shelf", DataKey: "shelf_Order", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Face", DataKey: "face", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "K", DataKey: "key_in", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "2D / Scaned Item", DataKey: "barcode_2D", Width: 10, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },



    ],
    
}