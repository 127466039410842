/* eslint-disable array-callback-return */
import { useState } from "react";
import { SelectPicker } from "rsuite";
import ReportMarkdownComponent from "./MarkdownStore/reportMarkdownComponent";
import ReportMarkdownDepMain from "./MarkdownDepartment/ReportMarkdownDepMain";

const MarkdownMain = (props: any) => {
  const { userContext, handleSpinner } = props;

  const [isDepOrStore, setIsDepOrStore] = useState("1");

  const SelectDepOrStore = (event) => {
    setIsDepOrStore(event);
  };

  return (
    <>
      <div className="scrollable-div">
        {isDepOrStore === "1" ? (
          <ReportMarkdownComponent
            userContext={userContext}
            handleSpinner={handleSpinner}
          />
        ) : (
          <ReportMarkdownDepMain
            userContext={userContext}
            handleSpinner={handleSpinner}
          />
        )}
      </div>
    </>
  );
};

export default MarkdownMain;
