import { TableHeaderConfig } from "../../../models"

export const MilkDeliveryScheduleConstants = {
    "MilkDeliverySchedule": [
        {...new TableHeaderConfig(), Header: "Group", DataKey: "store_Group", Width: 150, isFixed: false, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Store", DataKey: "store_ID", Width: 100, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Regular Schedule", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 3 },
        {...new TableHeaderConfig(), Header: "Dead Line", DataKey: "order_DayName", Width: 50, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Hour", DataKey: "deadline_Hour", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Minute", DataKey: "deadline_Minute", Width: 70, TypeOfData: "text-center", isSubHeader: true},
        {...new TableHeaderConfig(), Header: "", DataKey: "", Width: 10, isFixed: false,isSortable: false, isSearch: false},


        {...new TableHeaderConfig(), Header: "Changes", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 3 },
        {...new TableHeaderConfig(), Header: "Dead Line", DataKey: "c_Order_Day_DayName", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Hour", DataKey: "c_Deadline_Hour", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Minute", DataKey: "c_Deadline_Minute", Width: 70, TypeOfData: "text-center", isSubHeader: true},
        {...new TableHeaderConfig(), Header: "", DataKey: "", Width: 50, isFixed: false,isSortable: false, isSearch: false},

        {...new TableHeaderConfig(), Header: "Add On Schedule", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 3 },
        {...new TableHeaderConfig(), Header: "Dead Line", DataKey: "order_Day1_DayName", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Hour", DataKey: "deadline_Hour1", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Minute", DataKey: "deadline_Minute1", Width: 70, TypeOfData: "text-center", isSubHeader: true},

        {...new TableHeaderConfig(), Header: "", DataKey: "", Width: 10, isFixed: false,isSortable: false, isSearch: false},


        {...new TableHeaderConfig(), Header: "Changes", DataKey: "", Width: 210, isSortable: false, TypeOfData: "", colSpan: 3 },
        {...new TableHeaderConfig(), Header: "Dead Line", DataKey: "c_Order_Day1_DayName", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Hour", DataKey: "c_Deadline_Hour1", Width: 70, isSubHeader: true},
        {...new TableHeaderConfig(), Header: "Minute", DataKey: "c_Deadline_Minute1", Width: 70, TypeOfData: "text-center", isSubHeader: true},


       
    ]

}