
import { useEffect } from "react";
import * as OrdersServices from "../../../../services/orderService";
import TableComponent from "../../../common/tableComponent";

const ConfirmationListDetail = (props: any) => {
  useEffect(() => {
    getConfDetailData();
  }, [props.ConfID]);


  const getConfDetailData = async () => {
    const {
      handleSpinner,
      ConfID,
      store_ID,
      setConfDetailData,
      setAllConfDataFilteredByUser,
    } = props;
    var ConfID1;
    handleSpinner && handleSpinner(true);
    if (ConfID.length >= 1) {
      function arrayToString(arr) {
        return arr.join(",");
      }

      ConfID1 = arrayToString(ConfID);
    } else {
      ConfID1 = ConfID.got_Confirmation_ID;
    }
    OrdersServices.GetOrdersConfirmationDetailList(store_ID, ConfID1)
      .then((result) => {
        setConfDetailData(result);
        setAllConfDataFilteredByUser(result);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  return (
    <div>
      <TableComponent
        isFromModal={false}
        tableDetails={props.tableDetails}
        reportList={props.confDetailData}
        iconColumn={""}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
      />
    </div>
  );
};

export default ConfirmationListDetail;
