import React from 'react';
import { useEffect, useState } from "react";
import Constants from "../../.././utils/constants";
import { Button, Dropdown} from "rsuite";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import TableComponent from "../OrderOnHold/TableComponent";
import { OrderListConstants } from "./OrderListConstants";
const OrdersOnHold = (props: any) => {
  const { userContext } = props;
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [allOrderData, setAllOrderData] = useState([]);
  const [allOrderDataFiltered, setAllOrederDataFiltered] = useState([]);
  
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  return (
    <div>
      {/* <div className="scrollable-div"> */}
      <div className="OrderInvoice">
        <div className="mt-3 last-div">
            <div></div>
            <div className="d-flex justify-content-end">
              <div
                className="mx-2 filter"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <Icon icon="material-symbols:filter-alt-outline-sharp" />
                Filters
              </div>
              <Dropdown
                renderToggle={(_props, _ref) => (
                  <div className="filter" ref={_ref} {..._props}>
                    <Icon icon="pajamas:export" /> Export
                  </div>
                )}
                className="ms-2 me-0 button-fixed"
                appearance="link"
                placement="bottomEnd"
              >
                <Dropdown.Item
                  icon={<FileDownloadIcon />}
                  onClick={() => {
                    exportToExcel(
                      allOrderDataFiltered,
                      `View Confirmations ${userStore}`
                    );
                  }}
                >
                  Export to Excel
                </Dropdown.Item>
              </Dropdown>
            </div>
        </div>
        </div>

        <TableComponent
        userContext={userContext}
        isFromModal={false}
        isTotalRowRequired={true}
        tableDetails={OrderListConstants["OrderListConstants"]}
        reportList={allOrderDataFiltered ? allOrderDataFiltered : allOrderData}
        >
        </TableComponent>
      {/* </div> */}
    </div>
  );
};

export default OrdersOnHold;
