export const ReportConstants = {
    "Inventory": [
        //{ Header: "Conf#", DataKey: "confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Location", DataKey: "location", Width: 120, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Scan Type", DataKey: "scanType", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items", DataKey: "items", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pieces", DataKey: "MaintotalPieces", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Cost", DataKey: "MaintotalCost", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Total Cost", DataKey: "EstMaintotalCost", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Retail", DataKey: "EstMaintotalRetail", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total Retail", DataKey: "MaintotalRetail", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Inventory  Date", DataKey: "delivery_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Vendor", DataKey: "vendor_Name", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

    ],

    "InventoryDetailConf": [
        { Header: "Conf#", DataKey: "confID", Width: 150, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Delivery Date", DataKey: "delivery_date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        
        

        //{ Header: "UPC", DataKey: "upc", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "UPC", DataKey: "upc", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       // { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Vendor", DataKey: "vendor_Name", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Items", DataKey: "items", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       // { Header: "Pack", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pieces", DataKey: "MaintotalPieces", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        //{ Header: "Cases", DataKey: "cases", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Weighted", DataKey: "weighted", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Cost", DataKey: "cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Cost", DataKey: "MaintotalCost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Total Cost", DataKey: "EstMaintotalCost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        //{ Header: "Retail", DataKey: "retail_Price", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Retail", DataKey: "MaintotalRetailPrice", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Total Retail", DataKey: "EstMaintotalRetailPrice", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        
    ],

    "InventoryDetail": [
        //{ Header: "Conf#", DataKey: "confirmation_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Type", DataKey: "type", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        //{ Header: "User ID", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor", DataKey: "vendor_Name", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Units", DataKey: "units", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Cases", DataKey: "cases", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Weighted", DataKey: "weighted", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cost", DataKey: "cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total Cost", DataKey: "total_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Cost", DataKey: "est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Cost", DataKey: "total_Est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Retail", DataKey: "retail_Price", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Retail", DataKey: "total_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Retail", DataKey: "est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Retail", DataKey: "total_Est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},        { Header: "Total Cost", DataKey: "total_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Disc", DataKey: "disc", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
    ],
    "InventoryDetailAll": [
        { Header: "Location", DataKey: "location", Width: 120, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Type", DataKey: "type", Width: 100, isFixed: false, isSortable: false, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Conf#", DataKey: "confirmation_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 100, isFixed: false, isSortable: false, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "UPC", DataKey: "upc", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       { Header: "Delivery Date", DataKey: "delivery_Date", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       { Header: "Received Date", DataKey: "add_Datetime", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },

        //{ Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor", DataKey: "vendor_Name", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Units", DataKey: "units", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Cases", DataKey: "cases", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Weighted", DataKey: "weighted", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cost", DataKey: "cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total Cost", DataKey: "total_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Cost", DataKey: "est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Cost", DataKey: "total_Est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Retail", DataKey: "retail_Price", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Retail", DataKey: "total_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Retail", DataKey: "est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Retail", DataKey: "total_Est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Disc", DataKey: "disc", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        
    ],
    "InventoryDetailAllLocation": [
        { Header: "Location", DataKey: "location", Width: 120, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        //{ Header: "User ID", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Type", DataKey: "type", Width: 100, isFixed: false, isSortable: false, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Conf#", DataKey: "confirmation_ID", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "UPC", DataKey: "upc", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
       // { Header: "UPC", DataKey: "upc", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        //{ Header: "Item", DataKey: "item_ID", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor", DataKey: "vendor_Name", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Size", DataKey: "size", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Pack", DataKey: "pack", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Units", DataKey: "units", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Cases", DataKey: "cases", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Weighted", DataKey: "weighted", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "text-center", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cost", DataKey: "cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Total Cost", DataKey: "total_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Cost", DataKey: "est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Cost", DataKey: "total_Est_Cost", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Retail", DataKey: "retail_Price", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Total Retail", DataKey: "total_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Retail", DataKey: "est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Est Total Retail", DataKey: "total_Est_Retail", Width: 120, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Disc", DataKey: "disc", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
    ]
}