export const FilteredDepartmentConstants = {


    "DepartmentConstants":  [
        // { Header: "Store ID", DataKey: "store_ID", Width: 50, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
         
        { Header: "Department", DataKey: "department", Width: 50, isFixed: true, isSortable: true, Align: "Center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Items ", DataKey: "length", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
       
 
     ],

     "DepartmentDetailFilteredConstants":  [
        { Header: "Vendor", DataKey: "vendor", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "User ID", DataKey: "user_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     

        { Header: "Department", DataKey: "department", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Delivery", DataKey: "delivery_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Day", DataKey: "day", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Items", DataKey: "items", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pieces", DataKey: "quantity", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Received Time", DataKey: "sent_DateTime", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Deadline Time", DataKey: "received_Time", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        
        { Header: "Conf #", DataKey: "got_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "AWG Conf #", DataKey: "awG_Confirmation_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
 
     ],



}