import { useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SideNavComponent from "./sideNavComponent";
import HeaderComponent from "./headerComponent";
import "react-toastify/dist/ReactToastify.css";
import SpinnerComponent from "./spinnerComponent";
import { useMediaQuery } from "usehooks-ts";
import "./styles/_page-layout.scss";

const PageLayoutComponent = (props) => {
  const [isMenuCollapsed, setMenuCollapse] = useState(
    useMediaQuery("(max-width: 768px)") ? true : false
  );
  const { showSpinner, spinnerMessage, userFileType } = props;

  return (
    <>
      <div className="pageLayout">
        <div className="pageLayoutContent">
          <SideNavComponent
            userFileType={userFileType}
            isMenuCollapsed={isMenuCollapsed}
            setMenuCollapse={setMenuCollapse}
          />
          <div
            className={`mainBody ${
              isMenuCollapsed ? `showExpand` : `showCollapse`
            }`}
          >
            <HeaderComponent
              menuCollapse={setMenuCollapse.bind(this)}
              isMenuCollapsed={isMenuCollapsed}
              userContext={props.userContext}
              azureAuth={props.azureAuth}
            />
            <div className="main-content p-3">
              <Outlet />
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </div>
        </div>
      </div>
      {showSpinner && <SpinnerComponent message={spinnerMessage} />}
    </>
  );
};
export default PageLayoutComponent;
