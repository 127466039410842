/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Dropdown, Modal, Button, Input } from "rsuite";
import { addDays } from "date-fns";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "./OrderInvoiceTableComponent";
import TableComponent1 from "./OrderInvoiceDetailTable";
import * as UMSService from "../../../services/umsService";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import { OrderInvoiceConstants } from "./OrderInvoiceConstants";
import * as AuthService from "../../../services/authService";
import CostPrebookDetails from "../LastDayToSave/DiscountDetails/CostPrebookDetails";
import PriceDetails from "../LastDayToSave/DiscountDetails/PriceDetails";
import DiscountDetails from "../LastDayToSave/DiscountDetails/DiscountDetails";
import * as ReportService from "../../../services/reportServices";

const OrderInvoice = (props: any) => {
  const { handleSpinner, userContext = {} } = props;
  const store = userContext ? userContext.store : " ";
  const [open, setOpen] = useState(false);
  const [itemIDorUPCValue, setItemIDorUPCValue] = useState("");
  const [isActiveDiscounts, setIsActiveDiscounts] = useState(false);
  const [itemOrUPCData, setItemOrUPCData] = useState([]);
  const [itemDetailRow, setItemDetailRow] = useState(null);
  const handleCloseDiscounts = () => {
    setIsActiveDiscounts(false);
    setItemOrUPCData([]);
  };
  const [VendorStoreName, setVendorStoreName] = useState([]);
  const [allStoreName, setAllStoreName] = useState([]);
  const [VendorName, setVendorName] = useState([
    { vendor_ID: 99998, vendor_Name: "VMC" },
    { vendor_ID: 70038000, vendor_Name: "AWG KC" },
    { vendor_ID: 70038014, vendor_Name: "AWG HN" },
  ]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [groupDepartmentData, setGroupDepartmentData] = useState([]);
  const [groupVendorData, setGroupVendorData] = useState([]);
  const [groupConfData, setGroupConfData] = useState([]);
  const [groupConfirmationData, setGroupConfirmationData] = useState([]);
  const [detailConfData, setDetailConfData] = useState([]);
  const [isDetailView, setDetailView] = useState(false);
  const [showDepartmentData, setShowDepartmentData] = useState([]);
  const [detailScreenData, setDetailScreenData] = useState([]);
  const [vendorID, setVendorID] = useState(" ");
  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const [showAllData, setShowAllData] = useState(true);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showVendor, setShowVendor] = useState(false);
  const [isAWG, setIsAWG] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const [totalSavings, setTotalSavings] = useState({});
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -7));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));

  const [selectedRow, setSelectedRow] = useState(null);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  useEffect(() => {
    const { userContext = {} } = props;

    if (
      userContext !== null &&
      userContext !== undefined &&
      userContext.store !== 0
    ) {
      getOrderReceipt(
        userContext.store.toString(),
        moment(fromDate).format("MM/DD/YYYY"),
        moment(toDate).format("MM/DD/YYYY")
      );
    } else {
      getOrderReceipt(
        userStore,
        moment(fromDate).format("MM/DD/YYYY"),
        moment(toDate).format("MM/DD/YYYY")
      );
    }
    setShowAllData(true);
  }, [props.userContext, userStore, fromDate]);

  //filter department and confirmation id
  useEffect(() => {
    groupDepartmentForData();
  }, [invoiceData]);

  //filter vendor
  useEffect(() => {
    groupVendorForData();
  }, [invoiceData]);

  //filter confirmation id
  useEffect(() => {
    groupforConf();
  }, [departmentName, selectedRow]);

  useEffect(() => {
    DepartmentDataFilter();
  }, [departmentName]);

  useEffect(() => {
    totalCost(invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    writeDetailData(selectedRow && selectedRow.confirmation_ID);
  }, [selectedRow]);

  const writeDetailData = (confirmationID) => {
    groupforConf();
    if (confirmationID !== undefined && confirmationID !== null) {
      confirmationID = confirmationID.toString();
      groupConfData.map((record) => {
        if (record.confID.toString() == confirmationID) {
          setDetailConfData(record.datas);
          setDetailData(record.datas);
          setOpen(true);
        }
      });
    }
  };

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  /*   const writeDetailData = (confirmationID) => {
    groupConfData.filter((record) => { return record.confID === confirmationID && setDetailConfData(record.datas)}))
  }; */
  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));

          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }
          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        if (storesArray.length >= 1) {
          result.records = result.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getOrderReceipt = async (Store_ID, FromDate, ToDate) => {
    const { handleSpinner, userContext = {} } = props;
    //const { groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    let total = 0;
    var totalpotential = 0;
    var totaltrue = 0;
    Store_ID = userContext && userContext.store ? userContext.store : Store_ID;
    SettingsServices.GetAdGroupandGroupID(Store_ID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description.trim());
        if (result.records) {
          setIsAWG(true);
        } else {
          setIsAWG(false);
        }
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
    SettingsServices.GetOrderInvoice(
      //groupTypeId,
      Store_ID,
      FromDate,
      ToDate
    )
      .then((result) => {
        result.records.map((record) => {
          // if (record.allow.toString() !== "0") {
          //   record.bb = 0;
          // }
          record.confirmation_ID = String(record.confirmation_ID);
          record.delivery_Date = moment(record.delivery_Date).format(
            "MM-DD-YYYY"
          );
          record.invoice_Date = moment(record.invoice_Date).format(
            "MM-DD-YYYY"
          );
          record.next_Date = moment(record.next_Date).format("MM-DD-YYYY");
          record.update_Date = moment(record.update_Date).format("MM-DD-YYYY");
          record.Net = record.quantity * (record.cost - record.bb - record.amp);
          record.netInvoice =
            record.quantityInvoice * (record.cost - record.bb - record.amp);
          record.savings1 = record.quantity * (record.bb + record.amp);
          if (record.next_Date === "Invalid date") {
            record.next_Date = "";
          }

          record.truebb = record.bb;
          record.trueamp = record.amp;
          record.savingspotential = record.quantity * (record.bb + record.amp);
          record.savingtrue = record.quantityInvoice * (record.bb + record.amp);
          totalpotential += record.savingspotential;
          totaltrue += record.savingtrue;
          total += record.savings1;
          //totalInvoice += record.retail_Unit * (record.retail-record.bb - record.amp);
        });
        // setTotalSavings(total);
        result.records.map((record) => (record.totaltruegeneral = totaltrue));
        result.records.map(
          (record) => (record.totalpotentialgeneral = totalpotential)
        );
        const filteredArray = result.records.filter(
          (item) => item.confirmation_ID
        );
        setInvoiceData(filteredArray);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const DepartmentDataFilter = () => {
    const matchedRecords = [];

    detailScreenData.forEach((record) => {
      if (
        record.department_Store_Name === departmentName ||
        departmentName === "All"
      ) {
        matchedRecords.push({ ...record });
      }
    });
    // Sonuç dizisini setShowDepartmentData ile güncelle
    setShowDepartmentData(matchedRecords);
    setShowAllData(false);
  };
  // Filter for Vendor
  const groupVendorForData = () => {
    var groupsForVendor = {};

    invoiceData.forEach(function (veri) {
      var description = veri.vendor_Name;
      if (!groupsForVendor[description]) {
        groupsForVendor[description] = [];
      }
      groupsForVendor[description].push(veri);
    });
    var GroupsAllVendor = [];
    GroupsAllVendor.push({ vendor_Name: "All", datas: invoiceData });

    for (var key in groupsForVendor) {
      if (groupsForVendor.hasOwnProperty(key)) {
        var depObject = {
          vendor_Name: key,
          datas: groupsForVendor[key],
        };
        GroupsAllVendor.push(depObject);
      }
    }

    setGroupVendorData(GroupsAllVendor);
    if (GroupsAllVendor) {
      setShowVendor(true);
    }
  };

  // Filter for Department
  const groupDepartmentForData = () => {
    var groupsForDepartment = {};

    invoiceData.forEach(function (veri) {
      var description = veri.department_Store_Name;
      if (!groupsForDepartment[description]) {
        groupsForDepartment[description] = [];
      }
      groupsForDepartment[description].push(veri);
    });

    var GroupsAllDepartment = [];
    GroupsAllDepartment.push({ department: "All", datas: invoiceData });

    for (var key in groupsForDepartment) {
      if (groupsForDepartment.hasOwnProperty(key)) {
        var depObject = {
          department: key,
          datas: groupsForDepartment[key],
        };
        GroupsAllDepartment.push(depObject);
      }
    }

    setGroupDepartmentData(GroupsAllDepartment);
    if (GroupsAllDepartment) {
      setShowDepartment(true);
    }
  };
  //filter for Confirmation ID
  const groupforConf = () => {
    var groupsForConf = {};

    invoiceData.forEach(function (data) {
      var confID = data.confirmation_ID;
      if (!groupsForConf[confID]) {
        groupsForConf[confID] = [];
      }

      groupsForConf[confID].push(data);
    });
    setGroupConfirmationData([groupsForConf]);

    var GroupsAllConfirmation = [];

    for (var key in groupsForConf) {
      if (groupsForConf.hasOwnProperty(key)) {
        var depObject = {
          confID: key,
          datas: groupsForConf[key],
        };
        GroupsAllConfirmation.push(depObject);
      }
    }
    // GroupsAllConfirmation.filter((record) => {
    //   if (
    //     record.confID.toString() === selectedRow &&
    //     selectedRow?.confirmation_ID.toString()
    //   ) {
    //     setDetailConfData(record.datas);
    //     setOpen(true);
    //   }
    // });

    var data = GroupsAllConfirmation.filter((record) => {
      return record.confID === selectedRow?.confirmation_ID
        ? record.datas
        : null;
    });

    setDetailConfData(data?.[0]?.["datas"]);
    setDetailData(data?.[0]?.["datas"]);
    data?.[0]?.["datas"] && setOpen(true);
  };

  const totalCost = (datas) => {
    var results = [];

    var groupsdata = {};

    for (var i = 0; i < datas.length; i++) {
      var data = datas[i];
      var confirmationID = data.confirmation_ID;
      var delivery_Date = data.delivery_Date;
      var user_ID = data.user_ID;
      var update_Date = data.update_Date;
      var department_Store_Name = data.department_Store_Name;
      var department_Store_ID = data.department_Store_ID;
      var pieces_New = data.pieces_New;
      var lines_New = data.lines_New;
      var amp = data.amp;
      var bb = data.bb;
      var Net = data.Net;
      var savings = data.savings;
      var quantity = data.quantity;
      var potentialsavings = data.quantity * (data.bb + data.amp);
      var cost = data.cost;
      var savingspotential = data.savingspotential;
      var truesavings = data.savingtrue;
      var totalpotentialgeneral = data.totalpotentialgeneral;
      var vendor_Name = data.vendor_Name;
      var vendor_ID = data.vendor_ID;
      var item_ID = data.item_ID;
      var upc = data.upc;
      var pack = data.pack;

      if (!groupsdata[confirmationID]) {
        groupsdata[confirmationID] = [];
      }

      groupsdata[confirmationID].push({
        delivery_Date: delivery_Date,
        user_ID: user_ID,
        update_Date: update_Date,
        department_Store_Name: department_Store_Name,
        department_Store_ID: department_Store_ID,
        pieces_New: pieces_New,
        lines_New: lines_New,
        amp: amp,
        bb: bb,
        cost: cost,
        Net: Net,
        vendor_Name: vendor_Name,
        vendor_ID: vendor_ID,
        savings: savings,
        potentialsavings: potentialsavings,
        quantity: quantity,
        truesavings: truesavings,
        savingspotential: savingspotential,
        totalpotentialgeneral: totalpotentialgeneral,
        pack: pack,
        upc: upc,
        item_ID: item_ID,
      });
    }

    for (var confirmationID1 in groupsdata) {
      var grup = groupsdata[confirmationID1];
      var totalCost = grup.reduce(function (toplam, veri) {
        return toplam + veri.cost * veri.quantity;
      }, 0);
      var totalBB = grup.reduce(function (toplam, veri) {
        return toplam + veri.quantity * veri.bb;
      }, 0);
      var totalAMP = grup.reduce(function (toplam, veri) {
        return toplam + veri.amp;
      }, 0);
      var TotalNet = grup.reduce(function (toplam, veri) {
        return toplam + veri.Net;
      }, 0);
      var TotalPotential = grup.reduce(function (toplam, veri) {
        return toplam + veri.quantity * (veri.amp + veri.bb);
      }, 0);
      var TotalSavings = grup.reduce(function (toplam, veri) {
        return toplam + veri.savings;
      }, 0);
      var TotalTrue = grup.reduce(function (toplam, veri) {
        return toplam + veri.truesavings;
      }, 0);
      var PotentialTrue = grup.reduce(function (toplam, veri) {
        return toplam + veri.totalpotentialgeneral;
      }, 0);

      results.push({
        vendor_ID: grup.length > 0 ? vendor_ID : "",
        confirmation_ID: confirmationID1,
        user_ID: grup.length > 0 ? user_ID : "",
        vendor_Name: grup.length > 0 ? vendor_Name : "",
        delivery_Date: grup.length > 0 ? grup[0].delivery_Date : "",
        update_Date: grup.length > 0 ? grup[0].update_Date : "",
        department_Store_Name:
          grup.length > 0 ? grup[0].department_Store_Name : "",
        department_Store_ID: grup.length > 0 ? grup[0].department_Store_ID : "",
        pieces_New: grup.length > 0 ? grup[0].pieces_New : "",
        lines_New: grup.length > 0 ? grup[0].lines_New : "",
        Net: grup.length > 0 ? grup[0].Net : "",
        totalAmp: totalAMP,
        TotalBB: totalBB,
        totalCost: totalCost,
        totalNet: TotalNet,
        TotalSavings: TotalSavings,
        PotentialTrue: TotalPotential,
        TotalTrue: TotalTrue,
        color:
          TotalPotential.toFixed(0) !== TotalTrue.toFixed(0) ? "red" : null,
        pack: pack,
        upc: upc,
        item_ID: item_ID,
      });
    }
    function TotalRowMain(objectsArray) {
      let total = {
        totalCost: 0,
        totalAmp: 0,
        TotalBB: 0,
        totalNet: 0,
        PotentialTrue: 0,
        TotalTrue: 0,
        lines_New: 0,
        pieces_New: 0,
      };

      objectsArray.forEach((obj) => {
        total.totalCost += obj.totalCost || 0;
        total.totalAmp += obj.totalAmp || 0;
        total.TotalBB += obj.TotalBB || 0;
        total.totalNet += obj.totalNet || 0;
        total.PotentialTrue += obj.PotentialTrue || 0;
        total.TotalTrue += obj.TotalTrue || 0;

        total.lines_New += obj.lines_New || 0;
        total.pieces_New += obj.pieces_New || 0;
      });

      return total;
    }
    setTotalSavings(TotalRowMain(results));
    setDetailScreenData(results);
  };

  const onOptionChangeDepartment = (event) => {
    const DepartmentName = event;
    setDepartmentName(DepartmentName);
    setShowAllData(false);
  };
  const getDepartmentName = () => {
    let dept = "All";
    if (
      detailConfData &&
      detailConfData[0] &&
      detailConfData[0].department_Store_Name !== ""
    ) {
      dept = detailConfData[0].department_Store_Name
        ? detailConfData[0].department_Store_Name.trim()
        : "";
    }
    return dept;
  };
  const getStoreNameTable = () => {
    let dept = "All";
    if (
      detailConfData &&
      detailConfData[0] &&
      detailConfData[0].store_ID !== ""
    ) {
      dept = detailConfData[0].store_ID ? detailConfData[0].store_ID : "";
    }
    return dept;
  };
  const getConfName = () => {
    let conf = " ";
    if (
      detailConfData &&
      detailConfData[0] &&
      detailConfData[0].confirmation_ID !== ""
    ) {
      conf = detailConfData[0].confirmation_ID
        ? detailConfData[0].confirmation_ID.trim()
        : "";
    }
    return conf;
  };
  const getDeliveryDate = () => {
    let deliverydate = "All";
    if (
      detailConfData &&
      detailConfData[0] &&
      detailConfData[0].delivery_Date !== ""
    ) {
      deliverydate = detailConfData[0].delivery_Date
        ? detailConfData[0].delivery_Date.trim()
        : "";
    }
    return deliverydate;
  };
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getAllDataDetail = () => {
    setDetailData(detailConfData);
  };
  const getMatchedDataDetail = () => {
    const MatchedData = detailConfData.filter(
      (item) => item.quantity === item.quantityInvoice
    );
    setDetailData(MatchedData);
  };

  const getNotMatchedDataDetail = () => {
    const NotMatchedData = detailConfData.filter(
      (item) => item.quantity !== item.quantityInvoice
    );
    setDetailData(NotMatchedData);
  };

  const GetItemIDorUPC = async (ItemOrUPC, VendorID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    handleSpinner && handleSpinner(true);
    ReportService.GetItemIDorUPC(ItemOrUPC, VendorID)
      .then((result) => {
        setItemOrUPCData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  return (
    <>
      <div className="scrollable-div">
        <>
          <PageHeaderComponent
            setGroupTypeID={setGroupTypeID}
            setAwgOrGotSelect={setAwgOrGotSelect}
            setUserStore={setUserStore}
            userContext={userContext}
            groupAndGroupID={groupAndGroupID}
            awgOrGot={awgOrGot}
            storeName={storeName}
            selectedDesc={selectedDesc}
            selectedID={selectedID}
            storeData={storeData}
            handleDescChange={handleDescChange}
            storeNameHeader={storeNameHeader}
            storeID={storeID}
            groupDepartmentData={groupDepartmentData}
            onOptionChangeDepartment={onOptionChangeDepartment}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            isVendor={true}
            allVendorName={groupVendorData}
            isCalendar={true}
            isDepartment={true}
          />
        </>
        {showAllData ? (
          <TableComponent
            isFromModal={false}
            tableDetails={OrderInvoiceConstants["OrderInvoiceConstants"]}
            reportList={detailScreenData}
            iconColumn={"confirmation_ID"}
            onTableRowClick={(e) => {
              setSelectedRow(e);
            }}
            reportType={""}
            totalTableRow={totalSavings}
            isTotalRowRequired={true}
          />
        ) : (
          <TableComponent
            isFromModal={false}
            tableDetails={OrderInvoiceConstants["OrderInvoiceConstants"]}
            reportList={showDepartmentData}
            iconColumn={"confirmation_ID"}
            onTableRowClick={(e) => {
              setSelectedRow(e);
            }}
            reportType={""}
            totalTableRow={totalSavings}
            isTotalRowRequired={false}
          />
        )}
      </div>

      <Modal size={"full"} open={open} onClose={handleClose}>
        <Modal.Header>
          <div>
            <span
              className="mx-2 filter"
              onClick={() => {
                setOpen(false);
              }}
            >
              <Icon icon="ep:back" />
            </span>
            <div style={{ textAlign: "center" }}>Order Savings Detail</div>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">{getStoreNameTable()}</p>
                </div>
              </>
            </div>
            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">{getDepartmentName()}</p>
                </div>
              </>
            </div>
            <div className="col-3 p-2 reportDescItem">
              <div className="icon">
                <Icon icon="material-symbols:storefront-outline" />
              </div>
              <div className="label">
                <p className="m-0">{"Conf#"}</p>
                <p className="m-0">{getConfName()}</p>
              </div>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Delivery Date</p>
                  <p className="m-0">{getDeliveryDate()}</p>
                </div>
              </>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="label">
                  <p className="m-0">
                    {
                      <Dropdown
                        renderToggle={(_props, _ref) => (
                          <div className="filter" ref={_ref} {..._props}>
                            <Icon icon="pajamas:export" /> Export
                          </div>
                        )}
                        className="ms-2 me-0 button-fixed"
                        appearance="link"
                        placement="bottomEnd"
                      >
                        <Dropdown.Item
                          icon={<FileDownloadIcon />}
                          onClick={() => {
                            exportToExcel(
                              detailConfData,
                              `Order Savings Report ${
                                userStore +
                                " " +
                                detailConfData[0].confirmation_ID.trim()
                              }`
                            );
                          }}
                        >
                          Export to Excel
                        </Dropdown.Item>
                      </Dropdown>
                    }
                  </p>
                </div>
              </>
            </div>
          </div>
          <div className="d-flex gap-3">
            <Button
              appearance="primary"
              onClick={() => {
                getAllDataDetail();
              }}
            >
              All
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                getMatchedDataDetail();
              }}
            >
              Mathced
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                getNotMatchedDataDetail();
              }}
            >
              Qty Adjusted
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {groupConfData && (
            <TableComponent1
              isFromModal={false}
              tableDetails={
                OrderInvoiceConstants["OrderInvoiceConstantsDetail"]
              }
              isAWG={isAWG}
              reportList={detailData}
              iconColumn={"upc"}
              onTableRowClickEye={(e) => {
                setItemDetailRow(e);
                setIsActiveDiscounts(true);
              }}
              // onTableRowClick={(e) => {
              //   setItemDetailRow(e);
              //   setIsActiveDiscounts(true);
              // }}
              reportType={""}
              totalTableRow={totalSavings}
              isTotalRowRequired={false}
            />
          )}
        </Modal.Body>
      </Modal>

      {/* Discount Side */}

      <Modal
        size={"full"}
        open={isActiveDiscounts}
        onClose={handleCloseDiscounts}
      >
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setIsActiveDiscounts(false);
              setItemOrUPCData([]);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div className="text-center">Discounts Detail</div>

          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">UPC: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].upc
                    : itemDetailRow?.upc
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Item ID: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                    : itemDetailRow?.item_ID
                }`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Description: </p>
                <p className="m-0">{` ${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].description
                    : itemDetailRow?.description
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Size: </p>
                <p className="m-0">{` ${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].size
                    : itemDetailRow?.size
                }`}</p>
              </>
            </div>

            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Pack: </p>
                <p className="m-0">{`${
                  itemOrUPCData.length >= 1
                    ? itemOrUPCData[0] && itemOrUPCData[0].pack
                    : itemDetailRow?.pack
                }`}</p>
              </>
            </div>
            <div className="col-1 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Store ID: </p>
                <p className="m-0">{`${itemDetailRow?.store_ID}`}</p>
              </>
            </div>
            <div className="col-1  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Vendor: </p>
                <p className="m-0">{`${itemDetailRow?.vendor_Name}`}</p>
              </>
            </div>
            <div className="col-1  px-1  p-2 reportDescItem">
              <>
                <p className="m-0 px-1 fw-bold">Date: </p>
                <p className="m-0">{`${moment(fromDate).format(
                  "MM/DD/YYYY"
                )}`}</p>
              </>
            </div>
            <div className="col-2 px-1  p-2 reportDescItem">
              <>
                <p className="m-0 fw-bold">Enter Item/UPC </p>
                <Input
                  style={{ maxWidth: "200px" }}
                  onChange={(e) => {
                    setItemIDorUPCValue(e);
                  }}
                ></Input>
                <Button
                  appearance="primary"
                  onClick={() => {
                    GetItemIDorUPC(itemIDorUPCValue, itemDetailRow?.vendor_ID);
                  }}
                >
                  Enter
                </Button>
              </>
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Discounts
          </div>
          <DiscountDetails
            userContext={userContext}
            storeID={itemDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={itemDetailRow?.vendor_ID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Price
          </div>
          <PriceDetails
            userContext={userContext}
            storeID={itemDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={itemDetailRow?.vendor_ID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
          <div style={{ fontSize: "16px" }} className="text-red text-center ">
            {" "}
            Cost Prebook
          </div>
          <CostPrebookDetails
            userContext={userContext}
            storeID={itemDetailRow?.store_ID}
            UPC={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].upc
                : itemDetailRow?.upc
            }
            vendorID={itemDetailRow?.vendor_ID}
            ItemID={
              itemOrUPCData.length >= 1
                ? itemOrUPCData[0] && itemOrUPCData[0].item_ID
                : itemDetailRow?.item_ID
            }
            fromDate={fromDate}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderInvoice;
