import { useEffect, useRef, useState } from "react";

import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "services/reportServices";
import Constants from "utils/constants";
import TableComponent from "../../../common/tableComponent";
import Calendar from "../../../common/CalendarComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { TrackItConstants } from "../../../reports/TrackItReport/reportTrackItConstants";
import ArrowLeftLineIcon from "@rsuite/icons/ArrowLeftLine";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "models";
import { add, addDays } from "date-fns";

const DetailStoreUseDepartment = (props: any) => {
  const { fromDate, toDate, userContext, departmentIDLabel, storeIDLabel,showDepartment } =
    props;

  const [reportTypeSelected, setReportTypeSelected] = useState("Reason");

  const [listTableReports, setListTableReports] = useState([]);

  const [detailTableReports, setDetailTableReports] = useState([]);

  const [detailTableTotals, setDetailTableTotals] = useState([]);

  const [detailTableHeaders, setDetailTableHeaders] = useState([]);

  const [reasonDetailReportData, setReasonDetailReportData] = useState(
    new ReportMaster()
  );





  // No Data found


  //#region Reports Master Lists
  const [reasonReportData, setReasonReportData] = useState(new ReportMaster());

  const [weekReportData, setWeekReportData] = useState(new ReportMaster());
  const [donationReportData, setDonationReportData] = useState(
    new ReportMaster()
  );
  //#endregion


  //#region Filters
  const [columnFilters, setColumnFilters] = useState([]);
  const [filterCount, setFilterCount] = useState({});
  const [clickedFilterData, setClickedFilterData] = useState(null);
  const [clickedFilterDataMaster, setClickedFilterDataMaster] = useState(null);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});



  const getDetailedReportData = async (storeId, departmentId, isAll) => {
    const { groupType, groupTypeId } = userContext;
    const _nID_Type = Number(storeId) > 0 ? 1 : groupType;
    const _nID = Number(storeId) > 0 ? Number(storeId) : groupTypeId;
    const _storeIds = storeId;
    ReportService.getDetailedReportTrackIt(
      "3",
      _nID,
      _nID_Type,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      Number(departmentId),
      isAll,
      _storeIds
    )
      .then((result) => {
        const _detailReportData = handleReasonDetailData(result);

        setReasonDetailReportData(_detailReportData);
        const _headers = getReasonDetailHeaders(_detailReportData.headers);
        const _totals = getTotalRowData(_detailReportData.reportList, _headers);

        setDetailTableReports(_detailReportData.reportList);
        setDetailTableTotals(_totals);
        _headers.map((header) => {
          if (header.Header === "Delivery") {
            header.Header = "Date";
          }
        });
        setDetailTableHeaders(_headers);
      })
      .catch((err) => {
        console.error("Error: Get Detailed Report - ", err);
      })
      .finally(() => {});
  };
  useEffect(()=> {
    getDetailedReportData(storeIDLabel, departmentIDLabel, false);
  },[storeIDLabel,departmentIDLabel,])

  const handleReasonDetailData = (reasonResponse: any) => {
    const { reasonHeaders = [], records = [] } = reasonResponse;
    const _reasonMaster = { ...reasonDetailReportData };
    _reasonMaster.headers = reasonHeaders;
    _reasonMaster.reportList = records.map((item) => {
      item["FormatedDate"] =
        item.date && moment(item.date).format("MM-DD-YYYY");
      item["Badge_Type"] =
        item.price_Adj !== 0 ? "Manual Price Keyed" : "POS Price entered";
      item.reasonPrice.forEach((value) => {
        item[value.reason_Description] = value.price ?? 0;
      });
      item["total"] = Number(
        item.reasonPrice.reduce((acc, item) => acc + item.price, 0).toFixed(2)
      );
      return item;
    });
    return _reasonMaster;
  };

  const getReasonDetailHeaders = (reasonHeaders) => {
    let headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    return [
      ...TrackItConstants["DetailCommonHeaderStart"],
      ...headers,
      ...TrackItConstants["CommomHeaderEnd"],
    ];
  };

  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList
                  ?.reduce((acc, item) => acc + (item[res.DataKey] ?? 0), 0)
                  .toFixed(2)
              )
          : "";
    });
    return totalObj;
  };


  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  return (
    <>
      <div className="bg-container row m-0 mt-3 p-3">
        <div className="col-4 p-2 reportDescItem">
          <div className="icon">
            <Icon icon="material-symbols:storefront-outline" />
          </div>
          <div className="label">
            <p className="m-0">
              {
                /* selectedTab.value === "6" */ false
                  ? "Transferred From"
                  : "Store ID"
              }
            </p>
            <p className="m-0">
            {storeIDLabel}
            </p>
          </div>
        </div>

        <div className="col-3 p-2 reportDescItem">
          {!(/* isDonation */ false) && (
            <>
              <div className="icon">
                <Icon icon="material-symbols:store-outline" />
              </div>
              <div className="label">
                <p className="m-0">Department</p>
                <p className="m-0">{showDepartment}</p>
              </div>
            </>
          )}
        </div>
        {/* <div className="col-2 p-2 reportDescItem">
      {clickedFilterData.Damage && <div className="icon">
        <Icon icon="material-symbols:file-copy-outline" />
      </div>}
      {clickedFilterData.Damage && <div className="label">
        <p className="m-0">Damage</p>
        <p className="m-0">{clickedFilterData.Damage}</p>
      </div>}
    </div> */}
        <div className="col-1 p-2 reportDescItem">
          <div className="d-flex">
            <Dropdown
              className="mx-2 button-fixed"
              title="Export"
              appearance="primary"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(detailTableReports, "Detailed Order Report");
                }}
              >
                Export to Excel
              </Dropdown.Item>
              {/* <Dropdown.Item icon={<DetailIcon />}>Export to PDF</Dropdown.Item>
          <Dropdown.Item divider />
          <Dropdown.Item icon={<PageIcon />}>Share to Email</Dropdown.Item>
          <Dropdown.Item icon={<PageIcon />}>Send Link to Email</Dropdown.Item> */}
            </Dropdown>
          </div>
        </div>
      </div>

      <TableComponent
        isFromModal={true}
        tableDetails={detailTableHeaders}
        reportList={detailTableReports.sort((a, b) => {
          return a["store_ID"]
            .toString()
            .localeCompare(b["store_ID"].toString(), undefined, {
              numeric: true,
            });
        })}
        iconColumn={""}
        onTableRowClick={() => {}}
        totalTableRow={detailTableTotals}
        isTotalRowRequired={true}

      />
    </>
  );
};

export default DetailStoreUseDepartment;
