import React, { useState, useEffect, useRef, useCallback } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";
import { Button } from "rsuite";

const DepToStoreDepartment = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const {
    value,
    toDate,
    fromDate,
    MainLabel,
    setShowDepartment,
    setDepToStoreStore,
    ref4,
    ref3,
    scrollTo,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;
  //const [specificLabel, setSpecificLabel] = useState("Spoilage");
  const [specificLabel, setSpecificLabel] = useState(MainLabel); // Initialize with MainLabel

  // useEffect(() => {
  //   setSpecificLabel(MainLabel);
  // }, [MainLabel]);
  
  const element = document.getElementById("depToStoreStore");
  // Data manipulation
  const labels = Object.keys(value);
  const showRightData = labels.map((label) =>
    parseFloat(value[label][specificLabel]).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    })
  );

  const showRightDataNoPerSign = labels.map((label) =>
    parseFloat(value[label][specificLabel])
  );
  const combineObject = labels.map((label, index) => ({
    label,
    value: showRightDataNoPerSign[index],
  }));
  combineObject.sort((a, b) => b.value - a.value);

  const newSortLabels = [];
  const newSortValues = [];
  combineObject.map((object) => {
    return newSortLabels.push(object.label);
  });
  combineObject.map((object) => {
    return newSortValues.push(
      object.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    );
  });
  const allOfData =  useCallback((state) => {
    switch (state) {
      case "":
        break;
      case "Spoilage":
        return labels.map((label) => value[label]["Spoilage"].toFixed(2));
      case "Damage":
        return labels.map((label) => value[label]["Damage"].toFixed(2));
      case "Markdown_Labels":
        return labels.map((label) =>
          value[label]["Markdown_Labels"].toFixed(2)
        );
      case "Out_of_Date":
        return labels.map((label) => value[label]["Out_of_Date"].toFixed(2));

      case "Recovery":
        return labels.map((label) => value[label]["Recovery"].toFixed(2));

      case "Theft":
        return labels.map((label) => value[label]["Theft"].toFixed(2));
      default:
        setSpecificLabel(state);
    }
  }, [specificLabel]);
  if (
    allOfData(specificLabel) !== undefined &&
    allOfData(specificLabel) !== null
  ) {
    const numberValue = allOfData(specificLabel).map((value) => Number(value));
    var newPercentages = calculatePercentages(numberValue);
  }
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  //Change labels "_" to " "
  const SortLabels = specificLabel.includes("_")
    ? specificLabel.replaceAll("_", " ")
    : specificLabel;

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,
    plugins: {
      legend: {
        position: "right" as const,
        display: false,
      },
      title: {
        display: true,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },
      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };
  const charTextColor = [
    "rgba(20, 36, 89, 0.6)",
    "rgba(23, 107, 160, 0.6)",
    "rgba(25, 170, 222, 0.6)",
    "rgba(26, 201, 230, 0.6)",
    "rgba(27, 212, 212, 0.6)",
    "rgba(29, 228, 189, 0.6)",
    "rgba(199, 249, 238, 0.6)",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentages,
        backgroundColor: charTextColor,
        borderColor: [
          "#186BA1",
          "#19ABDE",
          "#1AC9E6",
          "#1CD4D4",
          "#1DE4BD",
          "#6DF0D1",
          "#C7F9EE",
        ],
        spacing: 5,
        hoverOffset: 5,
      },
    ],
  };



  return (
    <div ref={ref3}>
      {/* <div className="d-flex justify-content-center align-items-center">
        {" "}
        <Button
          appearance="primary"
          onClick={(e) => {
            setFirstLayer(true);
            setSecondLayer(false);
          }}
        >
          Back
        </Button>
      </div> */}

      <div className="chart-heading">{`${SortLabels} Department Breakdown`}</div>
      <div className="center-chart">
        <Doughnut options={options} data={data} />
      </div>
      <div className="details-chart">
        <ul>
          {newSortLabels.map((label, index) => {
            return (
              <li key={index} className="chart-li">
                <div
                  style={{
                    color: charTextColor[index],
                    fontWeight: "700",
                    filter: "contrast(100)",
                  }}
                  className="chart-label"
                  onClick={(e) => {
                    setSecondLayer(false);
                    setThirdLayer(true);
                    setShowDepartment(e.currentTarget.innerHTML);
                    scrollTo(ref4);
                    setDepToStoreStore(true);
                  }}
                >
                  {label}
                </div>
                <span className="percantage">{" " + newSortValues[index]}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2 h-40">
        {" "}
        <Button
          appearance="primary"
          onClick={(e) => {
            setFirstLayer(true);
            setSecondLayer(false);
          }}
        >
          Previous
        </Button>
      </div>
    </div>
  );
};

export default DepToStoreDepartment;
