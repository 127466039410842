/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Modal } from "rsuite";
import * as AuthService from "../../../services/authService";
import Calendar from "../../../components/common/CalendarComponent";
import { addDays } from "date-fns";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "./InventoryTableComponent";
import TableComponent1 from "./InventoryDetailTable";
import { SelectPicker } from "rsuite";
import TableComponent2 from "./InventoryDetailbyConfTable";
import { Icon } from "@iconify/react";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import * as ReportService from "../../../services/reportServices";
import * as UMSService from "../../../services/umsService";
import { ReportConstants } from "./ReportInventoryConstants";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";

const ReportInventoryComponent = (props: any) => {
  const { handleSpinner, userContext = {} } = props;
  const refAll = useRef();
  const refAllDetail = useRef();

  function scrollTo(ref) {
    if (ref.current) {
      setOpen1(true);
    }
  }

  function scrollToDetailAll(ref) {
    if (ref.current) {
      setOpen2(true);
    }
  }
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowDetail, setSelectedRowDetail] = useState("");

  const [allStoreName, setAllStoreName] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [groupDepartmentData, setGroupDepartmentData] = useState([]);
  const [groupVendorData, setGroupVendorData] = useState([]);
  const [groupConfData, setGroupConfData] = useState([]);
  const [groupConfLocData, setGroupConfLocData] = useState([]);
  const [groupConfLocData1, setGroupConfLocData1] = useState([]);
  const [groupConfLocDetailData, setGroupConfLocDetailData] = useState([]);
  const [groupConfirmationData, setGroupConfirmationData] = useState([]);
  const [groupConfirmationLocData, setGroupConfirmationLocData] = useState([]);
  const [groupConfirmationLocData1, setGroupConfirmationLocData1] = useState(
    []
  );

  const [detailConfData, setDetailConfData] = useState([]);
  const [showDepartmentData, setShowDepartmentData] = useState([]);
  const [detailScreenData, setDetailScreenData] = useState([]);
  const [confirmationIDTable, setConfirmationIDTable] = useState("");
  const [confirmationIDDetailTable, setConfirmationIDDetailTable] =
    useState("");
  const [vendorID, setVendorID] = useState(" ");
  //const [storeID, setStoreID] = useState(" ");
  //const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  //const [storeName, setStoreName] = useState(" ");
  const [totalObj, setTotalObj] = useState({});
  const [totalObjConf, setTotalObjConf] = useState({});
  const [totalObjDetail, setTotalObjDetail] = useState({});
  const [totalObjAll, settotalObjAll] = useState({});

  const [selectedData, setSelectedData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedDataTotal, setSelectedDataTotal] = useState({});

  const [showAllData, setShowAllData] = useState(true);
  const [showVendor, setShowVendor] = useState(false);
  const [isAWG, setIsAWG] = useState(false);
  const [totalSavings, setTotalSavings] = useState(0);
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);

  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [groupTypeID, setGroupTypeID] = useState(null);

  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);

  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDetail(false);
    setConfirmationIDDetailTable("");
  };

  const handleClose1 = () => {
    setOpen1(false);
    setOpenDetail(false);
    setConfirmationIDDetailTable("");
  };
  const handleClose2 = () => {
    setOpen2(false);
    setOpenDetail(false);
    setConfirmationIDDetailTable("");
  };
  const handleClose4 = () => {
    setOpen4(false);
    setOpenDetail(false);
    setConfirmationIDDetailTable("");
  };

  const handleClose5 = () => {
    setOpen5(false);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };
  /*   useEffect(() => {
    getVendorStoreName();
  }, [setConfirmationIDTable]);
 */
  useEffect(() => {
    ShowDetail(selectedRow);
  }, [selectedRow]);
  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getInventoryData(
      userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD"),
      vendorID
    );
  }, [userStore, fromDate, toDate]);
  //filter department and confirmation id
  /*   useEffect(() => {
    groupDepartmentForData();
  }, [invoiceData]); */
  //filter vendor
  /*   useEffect(() => {
    groupVendorForData();
  }, [invoiceData]); */

  //filter confirmation id
  useEffect(() => {
    groupforConf();
  }, [confirmationIDTable]);

  useEffect(() => {
    groupforConfLoc();
  }, [groupConfData]);

  useEffect(() => {
    totalCost(invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    writeDetailData(confirmationIDTable);
  }, [confirmationIDTable]);

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  const ShowDetail = (confid) => {
    let data = [];

    if (confid) {
      data = groupConfLocData.filter(
        (item) => item.confID === confid.confID.toString()
      );
      if (data[0]) {
        function calculateTotals(data) {
          let totalpack = 0;
          let totalunits = 0;
          let totalRetail = 0;
          let EsttotalRetail = 0;
          let totalcases = 0;
          let totalcost = 0;
          let Esttotalcost = 0;
          let totaltotalcost = 0;
          let Esttotaltotalcost = 0;
          let totaltotalRetail = 0;
          let EsttotaltotalRetail = 0;

          for (let entry of data) {
            totalpack += entry.pack || 0;
            totalunits += entry.units || 0;
            totalcases += entry.cases || 0;
            totalcost += entry.cost || 0;
            Esttotalcost += entry.est_Cost || 0;
            totaltotalcost += parseFloat(entry.total_Cost) || 0;
            Esttotaltotalcost += parseFloat(entry.total_Est_Cost) || 0;

            totalRetail += entry.retail_Price || 0;
            EsttotalRetail += entry.est_Retail || 0;

            totaltotalRetail += parseFloat(entry.total_Retail) || 0;
            EsttotaltotalRetail += parseFloat(entry.total_Est_Retail) || 0;
          }

          return {
            pack: totalpack.toString(),
            units: totalunits.toString(),
            cases: totalcases.toString(),
            cost: totalcost.toFixed(2),
            est_Cost: Esttotalcost.toFixed(2),
            total_Est_Cost: Esttotaltotalcost.toFixed(2),
            total_Cost: totaltotalcost.toFixed(2).toString(),
            retail_Price: totalRetail.toString(),
            EsttotalRetail: EsttotalRetail.toString(),

            total_Retail: totaltotalRetail.toFixed(2).toString(),
            EsttotaltotalRetail: EsttotaltotalRetail.toFixed(2).toString(),

            location: "All",
          };
        }
        setTotalObjDetail(calculateTotals(data[0].datas));
        setGroupConfLocDetailData(data[0].datas);
      }
    }

    /*   if (groupConfLocData[confid]) {
      setGroupConfLocDetailData(groupConfLocData[confid].datas);
    } */
  };
  const writeDetailData = (confirmationID) => {
    groupforConf();
    if (confirmationID !== undefined && confirmationID !== null) {
      confirmationID = confirmationID.toString();
      groupConfData.map((record) => {
        if (record.confID.toString() == confirmationID) {
          record.datas.map(
            (item) => (item.total_Retail = Number(item.total_Retail).toFixed(2))
          );
          record.datas.map(
            (item) => (item.total_Cost = Number(item.total_Cost).toFixed(2))
          );

          setDetailConfData(record.datas);
          setOpen(true);
        }
      });
    }
  };

  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};
    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList
                  ?.reduce((acc, item) => acc + (item[res.DataKey] ?? 0), 0)
                  .toFixed(2)
              )
          : "";
    });
    return totalObj;
  };

  getTotalRowData(detailScreenData, ["Total Cost", "Total Retail"]);
  /*   const writeDetailData = (confirmationID) => {
    groupConfData.filter((record) => { return record.confID === confirmationID && setDetailConfData(record.datas)}))
  }; */

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId, store, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          const uniqueVendor = new Set();

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getInventoryData = async (Store_ID, FromDate, ToDate, Vendor_ID) => {
    const { handleSpinner, userContext = {} } = props;
    Store_ID = userContext.store ? userContext.store : Store_ID;
    handleSpinner && handleSpinner(true);
    function sortByLocation(array) {
      return array.sort((a, b) => {
        const location_A = a.location.toUpperCase();
        const location_B = b.location.toUpperCase();

        if (location_A < location_B) {
          return -1;
        } else if (location_A > location_B) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    ReportService.GetInventoryReport(Store_ID, FromDate, ToDate)
      .then((result) => {
        sortByLocation(result.records);
        result.records.map((record) => {
          record.delivery_Date = moment(record.delivery_Date).format(
            "MM-DD-YYYY"
          );
          record.add_Datetime = moment(record.add_Datetime).format(
            "MM-DD-YYYY"
          );
          record.location = record.location.trim();
          if (record.description === "") {
            record.description = record.description1;
          }
          if (record.size === "") {
            record.size = record.size1;
          }
          //record.total_Cost = Number(record.total_Cost).toFixed(2)
          //record.total_Retail = Number(record.total_Cost).toFixed(2)

          /* record.weighted === 0
            ? (record.weighted = "F")
            : (record.weighted = "T"); */
        });
        function calculateTotals(data) {
          let totalpack = 0;
          let totalunits = 0;
          let totalRetail = 0;
          let EsttotalRetail = 0;
          let totalcases = 0;
          let totalcost = 0;
          let Esttotalcost = 0;
          let totaltotalcost = 0;
          let Esttotaltotalcost = 0;
          let totaltotalRetail = 0;
          let EsttotaltotalRetail = 0;

          for (let entry of data) {
            totalpack += entry.pack || 0;
            totalunits += entry.units || 0;
            totalcases += entry.cases || 0;
            totalcost += entry.cost || 0;
            Esttotalcost += entry.est_Cost || 0;
            totaltotalcost += parseFloat(entry.total_Cost) || 0;
            Esttotaltotalcost += parseFloat(entry.total_Est_Cost) || 0;

            totalRetail += entry.retail_Price || 0;
            EsttotalRetail += entry.est_Retail || 0;

            totaltotalRetail += parseFloat(entry.total_Retail) || 0;
            EsttotaltotalRetail += parseFloat(entry.total_Est_Retail) || 0;
          }

          return {
            pack: totalpack.toString(),
            units: totalunits.toString(),
            cases: totalcases.toString(),
            cost: totalcost.toFixed(2),
            est_Cost: Esttotalcost.toFixed(2),
            total_Est_Cost: Esttotaltotalcost.toFixed(2),
            total_Cost: totaltotalcost.toFixed(2).toString(),
            retail_Price: totalRetail.toString(),
            EsttotalRetail: EsttotalRetail.toString(),

            total_Retail: totaltotalRetail.toFixed(2).toString(),
            EsttotaltotalRetail: EsttotaltotalRetail.toFixed(2).toString(),

            location: "All",
          };
        }
        settotalObjAll(calculateTotals(result.records));
        setInvoiceData(result.records);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };
  useEffect(() => {
    groupforConfLoc111();
  }, [invoiceData]);

  // show all confirmation data
  const groupforConfLoc111 = () => {
    var groupsForConfLoc = {};

    invoiceData.forEach(function (data) {
      var confID = data.confirmation_ID;
      if (!groupsForConfLoc[confID]) {
        groupsForConfLoc[confID] = [];
      }

      groupsForConfLoc[confID].push(data);
    });
    setGroupConfirmationLocData1([groupsForConfLoc]);
    var GroupsAllConfirmationLoc = [];

    for (var key in groupsForConfLoc) {
      var MaintotalCost = groupsForConfLoc[key].reduce(function (toplam, veri) {
        return toplam + Number(veri.total_Cost);
      }, 0);

      var EstMaintotalCost = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Est_Cost);
      },
      0);

      var MaintotalRetailPrice = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Retail);
      },
      0);

      var EstMaintotalRetailPrice = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Est_Retail);
      },
      0);
      var MaintotalPieces = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + veri.units;
      },
      0);
      if (groupsForConfLoc.hasOwnProperty(key)) {
        var depObject = {
          confID: key,
          MaintotalCost: MaintotalCost,
          EstMaintotalCost: EstMaintotalCost,
          MaintotalRetailPrice: MaintotalRetailPrice,
          EstMaintotalRetailPrice: EstMaintotalRetailPrice,
          MaintotalPieces: MaintotalPieces,
          weighted: groupsForConfLoc[key][0].weighted,
          add_Datetime: groupsForConfLoc[key][0].add_Datetime,
          delivery_date: groupsForConfLoc[key][0].delivery_Date,
          items: groupsForConfLoc[key].length,
          datas: groupsForConfLoc[key],
        };
        GroupsAllConfirmationLoc.push(depObject);
      }
    }
    setGroupConfLocData1(GroupsAllConfirmationLoc);
  };

  //filter for Confirmation ID
  const groupforConfLoc = () => {
    var groupsForConfLoc = {};

    detailConfData.forEach(function (data) {
      var confID = data.confirmation_ID;
      if (!groupsForConfLoc[confID]) {
        groupsForConfLoc[confID] = [];
      }

      groupsForConfLoc[confID].push(data);
    });
    setGroupConfirmationLocData([groupsForConfLoc]);
    var GroupsAllConfirmationLoc = [];

    for (var key in groupsForConfLoc) {
      var MaintotalCost = groupsForConfLoc[key].reduce(function (toplam, veri) {
        return toplam + Number(veri.total_Cost);
      }, 0);
      var EstMaintotalCost = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Est_Cost);
      },
      0);
      var MaintotalRetailPrice = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Retail);
      },
      0);
      var EstMaintotalRetailPrice = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + Number(veri.total_Est_Retail);
      },
      0);
      var MaintotalPieces = groupsForConfLoc[key].reduce(function (
        toplam,
        veri
      ) {
        return toplam + veri.units;
      },
      0);
      if (groupsForConfLoc.hasOwnProperty(key)) {
        var depObject = {
          confID: key,
          MaintotalCost: MaintotalCost,
          EstMaintotalCost: EstMaintotalCost,
          MaintotalRetailPrice: MaintotalRetailPrice,
          EstMaintotalRetailPrice: EstMaintotalRetailPrice,
          MaintotalPieces: MaintotalPieces,
          weighted: groupsForConfLoc[key][0].weighted,
          add_Datetime: groupsForConfLoc[key][0].add_Datetime,
          delivery_date: groupsForConfLoc[key][0].delivery_Date,
          items: groupsForConfLoc[key].length,
          datas: groupsForConfLoc[key],
        };
        GroupsAllConfirmationLoc.push(depObject);
      }
    }

    function calculateTotals(data) {
      let totalCost = 0;
      let EsttotalCost = 0;
      let totalPieces = 0;
      let totalItems = 0;
      let totalRetail = 0;
      let EsttotalRetail = 0;

      for (let entry of data) {
        totalCost += entry.MaintotalCost || 0;
        EsttotalCost += entry.EstMaintotalCost || 0;
        totalPieces += entry.MaintotalPieces || 0;
        totalItems += entry.items || 0;
        totalRetail += entry.MaintotalRetailPrice || 0;
        EsttotalRetail += entry.EstMaintotalRetailPrice || 0;
      }

      return {
        MaintotalCost: totalCost.toFixed(2).toString(),
        EstMaintotalCost: EsttotalCost.toFixed(2).toString(),

        MaintotalPieces: totalPieces.toString(),
        items: totalItems.toString(),
        MaintotalRetailPrice: totalRetail.toFixed(2).toString(),
        EstMaintotalRetailPrice: EsttotalRetail.toFixed(2).toString(),

        location: "All",
      };
    }
    setTotalObjConf(calculateTotals(GroupsAllConfirmationLoc));
    setGroupConfLocData(GroupsAllConfirmationLoc);
  };
  //filter for location
  const groupforConf = () => {
    var groupsForConf = {};

    invoiceData.forEach(function (data) {
      var confID = data.location;
      if (!groupsForConf[confID]) {
        groupsForConf[confID] = [];
      }

      groupsForConf[confID].push(data);
    });
    setGroupConfirmationData([groupsForConf]);

    var GroupsAllConfirmation = [];

    for (var key in groupsForConf) {
      if (groupsForConf.hasOwnProperty(key)) {
        var depObject = {
          confID: key,
          datas: groupsForConf[key],
        };
        GroupsAllConfirmation.push(depObject);
      }
    }
    setGroupConfData(GroupsAllConfirmation);
  };

  const totalCost = (datas) => {
    var results = [];

    var groupsdata = {};

    for (var i = 0; i < datas.length; i++) {
      var data = datas[i];
      var confirmationID = data.confirmation_ID;
      var delivery_Date = data.delivery_Date;
      var add_Datetime = data.add_Datetime;
      var location = data.location;
      var scanType = data.scan_Type;
      var pieces = data.units;
      var totalCost = data.total_Cost;
      var estTotalCost = data.total_Est_Cost;
      var totalRetail_price = data.total_Retail;
      var EsttotalRetail_price = data.total_Est_Retail;
      var vendor_Name = data.vendor_Name;

      if (!groupsdata[location]) {
        groupsdata[location] = [];
      }

      groupsdata[location].push({
        delivery_Date: delivery_Date,
        add_Datetime: add_Datetime,
        vendor_Name: vendor_Name,
        confirmationID: confirmationID,
        location: location,
        scanType: scanType,
        pieces: pieces,
        totalCost: totalCost,
        estTotalCost: estTotalCost,
        totalRetail_price: totalRetail_price,
        EsttotalRetail_price: EsttotalRetail_price,
      });
    }
    for (var confirmationID1 in groupsdata) {
      var grup = groupsdata[confirmationID1];
      var location1 = groupsdata[confirmationID1][0].location;
      var scanType1 = groupsdata[confirmationID1][0].scanType;
      var items = groupsdata[confirmationID1].length;
      var MaintotalCost = grup.reduce(function (toplam, veri) {
        return toplam + veri.totalCost;
      }, 0);
      var EstMaintotalCost = grup.reduce(function (toplam, veri) {
        return toplam + veri.estTotalCost;
      }, 0);
      var MaintotalRetail = grup.reduce(function (toplam, veri) {
        return toplam + veri.totalRetail_price;
      }, 0);
      var EstMaintotalRetail = grup.reduce(function (toplam, veri) {
        return toplam + veri.EsttotalRetail_price;
      }, 0);
      var MaintotalPieces = grup.reduce(function (toplam, veri) {
        return toplam + veri.pieces;
      }, 0);

      results.push({
        confirmation_ID: confirmationID1,
        vendor_Name: grup.length > 0 ? vendor_Name : "",
        //vendor_Name: vendor_Name,
        scanType: scanType1,
        location: location1,
        items: items,
        MaintotalCost: Number(MaintotalCost),
        EstMaintotalCost: Number(EstMaintotalCost),
        MaintotalRetail: Number(MaintotalRetail),
        EstMaintotalRetail: Number(EstMaintotalRetail),
        MaintotalPieces: MaintotalPieces,
        delivery_Date: grup.length > 0 ? grup[0].delivery_Date : "",
        add_Datetime: grup.length > 0 ? grup[0].add_Datetime : "",
      });
    }
    setDetailScreenData(results);

    function calculateTotals(data) {
      let totalCost = 0;
      let EsttotalCost = 0;
      let totalPieces = 0;
      let totalItems = 0;
      let totalRetail = 0;
      let EsttotalRetail = 0;

      for (let entry of data) {
        totalCost += entry.MaintotalCost || 0;
        EsttotalCost += entry.EstMaintotalCost || 0;
        totalPieces += entry.MaintotalPieces || 0;
        totalItems += entry.items || 0;
        totalRetail += entry.MaintotalRetail || 0;
        EsttotalRetail += entry.EstMaintotalRetail || 0;
      }

      return {
        MaintotalCost: totalCost.toFixed(2).toString(),
        EstMaintotalCost: EsttotalCost.toFixed(2).toString(),
        MaintotalPieces: totalPieces.toString(),
        items: totalItems.toString(),
        MaintotalRetail: totalRetail.toFixed(2).toString(),
        EstMaintotalRetail: EsttotalRetail.toFixed(2).toString(),

        location: "All",
      };
    }
    setTotalObj(calculateTotals(results));
  };

  const getLocationName = () => {
    let dept = "All";
    if (detailConfData[0] && detailConfData[0].location !== "") {
      dept = detailConfData[0].location
        ? detailConfData[0].location.trim()
        : "";
    }
    return dept;
  };
  const getConfName = () => {
    let conf = " ";
    if (
      groupConfLocDetailData[0] &&
      groupConfLocDetailData[0].confirmation_ID !== ""
    ) {
      conf = groupConfLocDetailData[0].confirmation_ID
        ? groupConfLocDetailData[0].confirmation_ID.trim()
        : "";
    }
    return conf;
  };
  const getDeliveryDate = () => {
    let deliverydate = "All";
    if (
      groupConfLocDetailData[0] &&
      groupConfLocDetailData[0].delivery_Date !== ""
    ) {
      deliverydate = groupConfLocDetailData[0].delivery_Date
        ? groupConfLocDetailData[0].delivery_Date.trim()
        : "";
    }
    return deliverydate;
  };

  const getUserID = () => {
    let userID = "All";
    if (groupConfLocDetailData[0] && groupConfLocDetailData[0].user_ID !== "") {
      userID = groupConfLocDetailData[0].user_ID
        ? groupConfLocDetailData[0].user_ID.trim()
        : "";
    }
    return userID;
  };

  const getReceivedDate = () => {
    let receivedDate = "All";
    if (
      groupConfLocDetailData[0] &&
      groupConfLocDetailData[0].add_Datetime !== ""
    ) {
      receivedDate = groupConfLocDetailData[0].add_Datetime
        ? groupConfLocDetailData[0].add_Datetime.trim()
        : "";
    }
    return receivedDate;
  };

  const handleRowClick = (rowIndex) => {
    // Kopya oluşturarak mevcut seçili satırları değiştirmek yerine, yeni bir dizi oluşturun.
    var newSelectedRows = [...selectedData];
    // rowIndex, mevcut seçili satırların içinde bulunuyorsa kaldırın, değilse ekleyin.
    if (
      newSelectedRows.some(
        (item) => item.confirmation_ID === groupConfLocData[rowIndex].confID
      )
    ) {
      newSelectedRows = newSelectedRows.filter(
        (item) => item.confirmation_ID !== groupConfLocData[rowIndex].confID
      );
    } else {
      // newSelectedRows = [...selectedData, groupConfLocData[rowIndex].datas];

      newSelectedRows = newSelectedRows.concat(
        groupConfLocData[rowIndex].datas
      );
    }
    function calculateTotals(data) {
      let totalpack = 0;
      let totalunits = 0;
      let totalRetail = 0;
      let EsttotalRetail = 0;
      let totalcases = 0;
      let totalcost = 0;
      let Esttotalcost = 0;
      let totaltotalcost = 0;
      let Esttotaltotalcost = 0;
      let totaltotalRetail = 0;
      let EsttotaltotalRetail = 0;

      for (let entry of data) {
        totalpack += entry.pack || 0;
        totalunits += entry.units || 0;
        totalcases += entry.cases || 0;
        totalcost += entry.cost || 0;
        Esttotalcost += entry.est_Cost || 0;

        totaltotalcost += parseFloat(entry.total_Cost) || 0;
        Esttotaltotalcost += parseFloat(entry.total_Est_Cost) || 0;

        totalRetail += entry.retail_Price || 0;
        EsttotalRetail += entry.est_Retail || 0;

        totaltotalRetail += parseFloat(entry.total_Retail) || 0;
        EsttotaltotalRetail += parseFloat(entry.total_Est_Retail) || 0;
      }

      return {
        pack: totalpack.toString(),
        units: totalunits.toString(),
        cases: totalcases.toString(),
        cost: totalcost.toFixed(2),
        est_Cost: Esttotalcost.toFixed(2),
        total_Est_Cost: Esttotaltotalcost.toFixed(2),
        total_Cost: totaltotalcost.toFixed(2).toString(),
        retail_Price: totalRetail.toString(),
        EsttotalRetail: EsttotalRetail.toString(),

        total_Retail: totaltotalRetail.toFixed(2).toString(),
        EsttotaltotalRetail: EsttotaltotalRetail.toFixed(2).toString(),

        location: "All",
      };
    }
    setSelectedDataTotal(calculateTotals(newSelectedRows));
    // State'i güncelleyin
    if (newSelectedRows.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    setSelectedData(newSelectedRows);
  };
  const getFilteredLocation = () => {
    const uniqueConfirmationIDs = [];
    const seenConfirmationIDs = new Set();

    selectedData.forEach((obje) => {
      const { location } = obje;
      if (!seenConfirmationIDs.has(location)) {
        seenConfirmationIDs.add(location);
        uniqueConfirmationIDs.push(location + "/");
      }
    });
    return uniqueConfirmationIDs;
  };

  const getFilteredConfID = () => {
    const uniqueConfirmationIDs = [];
    const seenConfirmationIDs = new Set();

    selectedData.forEach((obje) => {
      const { confirmation_ID } = obje;
      if (!seenConfirmationIDs.has(confirmation_ID)) {
        seenConfirmationIDs.add(confirmation_ID);
        uniqueConfirmationIDs.push(confirmation_ID + " / ");
      }
    });
    return uniqueConfirmationIDs;
  };

  const getFilteredUserID = () => {
    const uniqueConfirmationIDs = [];
    const seenConfirmationIDs = new Set();

    selectedData.forEach((obje) => {
      const { user_ID } = obje;
      if (!seenConfirmationIDs.has(user_ID)) {
        seenConfirmationIDs.add(user_ID);
        uniqueConfirmationIDs.push(user_ID + " / ");
      }
    });
    return uniqueConfirmationIDs;
  };

  const getFilteredReceivedDate = () => {
    const uniqueConfirmationIDs = [];
    const seenConfirmationIDs = new Set();

    selectedData.forEach((obje) => {
      const { add_Datetime } = obje;
      if (!seenConfirmationIDs.has(add_Datetime)) {
        seenConfirmationIDs.add(add_Datetime);
        uniqueConfirmationIDs.push(add_Datetime + " / ");
      }
    });
    return uniqueConfirmationIDs;
  };
  const getFilteredDelivery_Date = () => {
    const uniqueConfirmationIDs = [];
    const seenConfirmationIDs = new Set();

    selectedData.forEach((obje) => {
      const { delivery_Date } = obje;
      if (!seenConfirmationIDs.has(delivery_Date)) {
        seenConfirmationIDs.add(delivery_Date);
        uniqueConfirmationIDs.push(delivery_Date + " / ");
      }
    });
    return uniqueConfirmationIDs;
  };

  const containerRef = useRef(null);
  const [isGroupGrind, setIsGroupGrind] = useState(false);

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    setIsGroupGrind(true);
  };

  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
    setIsGroupGrind(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginBottom: "2rem",
        }}
      >
        {/* Group */}
        {/*  <div ref={containerRef} style={{ position: "relative" }}>
          {!(
            userContext &&
            userContext.groupType === 2 &&
            userContext.groupTypeId === 0
          ) && (
              <SelectPicker
                container={() => containerRef.current}
                onChange={SelectGroupType}
                style={{ width: "auto", minWidth: "200px" }}
                label="Group"
                virtualized
                data={groupAndGroupID.map((item) => ({
                  label: item.groupStore_desc,
                  value: item.groupStore_uid,
                }))}
                cleanable={false}
              />
            )}
        </div> */}

        {/* store */}
        {/* <div ref={containerRef} style={{ position: "relative" }}>
          <SelectPicker
            onChange={onOptionChangeHandler}
            container={() => containerRef.current}
            style={{ width: "auto", minWidth: "200px" }}
            label="Store"
            placeholder={
              (userContext?.groupType === 3 && userContext.groupTypeId === 0) ||
                isGroupGrind
                ? "All"
                : storeName && storeName[0]
                  ? storeName[0]
                  : "All"
            }
            virtualized
            data={storeName.map((item, index) => ({
              label: item,
              value: index,
            }))}
            cleanable={false}
          />
        </div> */}

        {/* <div ref={containerRef} style={{ position: "relative" }}>
          <Calendar
            value={[fromDate, toDate]}
            calenderOnClick={calenderOnClick}
            cleanable={false}
          />
        </div> */}

        <PageHeaderComponent
          setGroupTypeID={setGroupTypeID}
          setAwgOrGotSelect={setAwgOrGotSelect}
          setUserStore={setUserStore}
          userContext={userContext}
          groupAndGroupID={groupAndGroupID}
          awgOrGot={awgOrGot}
          storeName={storeName}
          selectedDesc={selectedDesc}
          selectedID={selectedID}
          storeData={storeData}
          handleDescChange={handleDescChange}
          storeNameHeader={storeNameHeader}
          storeID={storeID}
          groupDepartmentData={groupDepartmentData}
          //onOptionChangeDepartment={onOptionChangeDepartment}
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={setFromDate}
          setToDate={setToDate}
          isVendor={false}
          allVendorName={groupVendorData}
          isCalendar={true}
          isDepartment={false}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
        }}
      >
        <Dropdown
          renderToggle={(_props, _ref) => (
            <div className="filter" ref={_ref} {..._props}>
              <Icon icon="pajamas:export" /> Export
            </div>
          )}
          className="ms-2 me-0 button-fixed"
          appearance="link"
          placement="bottomEnd"
        >
          <Dropdown.Item
            icon={<FileDownloadIcon />}
            onClick={() => {
              exportToExcel(
                detailScreenData,
                `Inventory Report ` //${storeName + storeID}`
              );
            }}
          >
            Export to Excel
          </Dropdown.Item>
        </Dropdown>
      </div>

      {invoiceData.length !== 0 ? (
        <TableComponent
          userContext={userContext}
          isFromModal={false}
          tableDetails={ReportConstants["Inventory"]}
          setConfirmationIDTable={setConfirmationIDTable}
          reportList={detailScreenData}
          iconColumn={"location"}
          onTableRowClick={() => {}}
          refAll={refAll}
          scrollTo={scrollTo}
          showAll={true}
          reportType={""}
          totalTableRow={totalObj}
          isTotalRowRequired={true}
        />
      ) : null}

      <Modal size={"full"} open={open} onClose={handleClose}>
        <Modal.Header>
          <div>
            <Modal.Title>
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
              <div style={{ textAlign: "center" }}>Inventory Detail</div>
            </Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Location</p>
                  <p className="m-0">{getLocationName()}</p>
                </div>
              </>
            </div>

            {groupConfLocDetailData ? (
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:storefront-outline" />
                </div>
                <div className="label">
                  <p className="m-0">{"Conf#"}</p>
                  <p className="m-0">{getConfName()}</p>
                </div>
              </div>
            ) : null}

            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Received Date</p>
                  <p className="m-0">{getReceivedDate()}</p>
                </div>
              </>
            </div>
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Delivery Date</p>
                  <p className="m-0">{getDeliveryDate()}</p>
                </div>
              </>
            </div>

            {isFiltered && (
              <div className="col-1 p-0 reportDescItem">
                <>
                  <div className="label">
                    <Button
                      onClick={() => setOpen5(true)}
                      appearance="primary"
                      className="m-0"
                    >
                      Filter
                    </Button>
                  </div>
                </>
              </div>
            )}

            {groupConfLocDetailData && confirmationIDDetailTable ? (
              <div className="col-1 p-2 reportDescItem">
                <>
                  <div className="label">
                    <p className="m-0">
                      {
                        <Dropdown
                          renderToggle={(_props, _ref) => (
                            <div className="filter" ref={_ref} {..._props}>
                              <Icon icon="pajamas:export" /> Export
                            </div>
                          )}
                          className="ms-2 me-0 button-fixed"
                          appearance="link"
                          placement="bottomEnd"
                        >
                          <Dropdown.Item
                            icon={<FileDownloadIcon />}
                            onClick={() => {
                              exportToExcel(
                                groupConfLocDetailData,
                                `Inventory Report ${
                                  storeName +
                                  " " +
                                  groupConfLocDetailData[0]["location"] +
                                  " " +
                                  groupConfLocDetailData[0]["confirmation_ID"]
                                }`
                              );
                            }}
                          >
                            Export to Excel
                          </Dropdown.Item>
                        </Dropdown>
                      }
                    </p>
                  </div>
                </>
              </div>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body>
          {detailScreenData && !confirmationIDDetailTable ? (
            <TableComponent2
              isFromModal={false}
              tableDetails={ReportConstants["InventoryDetailConf"]}
              setConfirmationIDTable={setConfirmationIDDetailTable}
              reportList={groupConfLocData}
              setOpen4={setOpen4}
              setSelectedData={setSelectedData}
              handleRowClick={handleRowClick}
              selectedData={selectedData}
              setSelectedRow={setSelectedRow}
              iconColumn={"confID"}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={totalObjConf}
              refAll={refAllDetail}
              scrollTo={scrollToDetailAll}
              isTotalRowRequired={true}
              showAll={true}
            />
          ) : null}{" "}
        </Modal.Body>
      </Modal>

      <Modal size={"full"} open={open4} onClose={handleClose4}>
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen4(false);
              setOpenDetail(false);
              setConfirmationIDDetailTable("");
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div style={{ textAlign: "center" }}>
            <Modal.Title>Inventory Detail</Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Location</p>
                  <p className="m-0">{getLocationName()}</p>
                </div>
              </>
            </div>

            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">User ID</p>
                  <p className="m-0">{getUserID()}</p>
                </div>
              </>
            </div>

            {groupConfLocDetailData ? (
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:storefront-outline" />
                </div>
                <div className="label">
                  <p className="m-0">{"Conf#"}</p>
                  <p className="m-0">{getConfName()}</p>
                </div>
              </div>
            ) : null}

            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Received Date</p>
                  <p className="m-0">{getReceivedDate()}</p>
                </div>
              </>
            </div>
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Delivery Date</p>
                  <p className="m-0">{getDeliveryDate()}</p>
                </div>
              </>
            </div>

            {groupConfLocDetailData && confirmationIDDetailTable ? (
              <div className="col-1 p-2 reportDescItem">
                <>
                  <div className="label">
                    <p className="m-0">
                      {
                        <Dropdown
                          renderToggle={(_props, _ref) => (
                            <div className="filter" ref={_ref} {..._props}>
                              <Icon icon="pajamas:export" /> Export
                            </div>
                          )}
                          className="ms-2 me-0 button-fixed"
                          appearance="link"
                          placement="bottomEnd"
                        >
                          <Dropdown.Item
                            icon={<FileDownloadIcon />}
                            onClick={() => {
                              exportToExcel(
                                groupConfLocDetailData,
                                `Inventory Report ${
                                  storeName +
                                  " " +
                                  groupConfLocDetailData[0]["location"] +
                                  " " +
                                  groupConfLocDetailData[0]["confirmation_ID"]
                                }`
                              );
                            }}
                          >
                            Export to Excel
                          </Dropdown.Item>
                        </Dropdown>
                      }
                    </p>
                  </div>
                </>
              </div>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body>
          {detailScreenData ? (
            <TableComponent1
              isFromModal={false}
              tableDetails={ReportConstants["InventoryDetail"]}
              reportList={groupConfLocDetailData}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={totalObjDetail}
              isTotalRowRequired={true}
            />
          ) : null}{" "}
        </Modal.Body>
      </Modal>

      <Modal size={"full"} open={open5} onClose={handleClose5}>
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen5(false);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div style={{ textAlign: "center" }}>
            <Modal.Title>Inventory Detail Filtered</Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Location</p>
                  <p className="m-0">{getFilteredLocation()}</p>
                </div>
              </>
            </div>

            {groupConfLocDetailData ? (
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:storefront-outline" />
                </div>
                <div className="label">
                  <p className="m-0">{"Conf#"}</p>
                  <p className="m-0">{getFilteredConfID()}</p>
                </div>
              </div>
            ) : null}

            {groupConfLocDetailData ? (
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:storefront-outline" />
                </div>
                <div className="label">
                  <p className="m-0">{"User ID"}</p>
                  <p className="m-0">{getFilteredUserID()}</p>
                </div>
              </div>
            ) : null}

            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Received Date</p>
                  <p className="m-0">{getFilteredReceivedDate()}</p>
                </div>
              </>
            </div>
            <div className="clo-12 col-md-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Delivery Date</p>
                  <p className="m-0">{getFilteredDelivery_Date()}</p>
                </div>
              </>
            </div>
            {selectedData ? (
              <div className="col-1 p-2 reportDescItem">
                <>
                  <div className="label">
                    <p className="m-0">
                      {
                        <Dropdown
                          renderToggle={(_props, _ref) => (
                            <div className="filter" ref={_ref} {..._props}>
                              <Icon icon="pajamas:export" /> Export
                            </div>
                          )}
                          className="ms-2 me-0 button-fixed"
                          appearance="link"
                          placement="bottomEnd"
                        >
                          <Dropdown.Item
                            icon={<FileDownloadIcon />}
                            onClick={() => {
                              exportToExcel(
                                selectedData,
                                `Inventory Report ${userStore}`
                              );
                            }}
                          >
                            Export to Excel
                          </Dropdown.Item>
                        </Dropdown>
                      }
                    </p>
                  </div>
                </>
              </div>
            ) : null}
          </div>
        </Modal.Header>
        <Modal.Body>
          <TableComponent1
            isFromModal={false}
            tableDetails={ReportConstants["InventoryDetailAllLocation"]}
            reportList={selectedData}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={selectedDataTotal}
            isTotalRowRequired={true}
          />
        </Modal.Body>
      </Modal>

      {groupConfLocData1 && (
        <Modal size={"full"} open={open1} onClose={handleClose1}>
          <Modal.Header>
            <div>
              <Modal.Title>
                <span
                  className="mx-2 filter"
                  onClick={() => {
                    setOpen1(false);
                  }}
                >
                  <Icon icon="ep:back" />
                </span>
                <div style={{ textAlign: "center" }}>Inventory Detail All</div>
              </Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-3 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Location</p>
                    <p className="m-0">{"All"}</p>
                  </div>
                </>
              </div>

              {groupConfLocDetailData ? (
                <div className="clo-12 col-md-2 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:storefront-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">{"Conf#"}</p>
                    <p className="m-0">{"All"}</p>
                  </div>
                </div>
              ) : null}

              {/*             <div className="col-3 p-2 reportDescItem">
              <div className="icon">
                <Icon icon="material-symbols:storefront-outline" />
              </div>
              <div className="label">
                <p className="m-0">{"Scan Type"}</p>
                <p className="m-0">{getScanType()}</p>
              </div>
            </div> */}

              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Received Date</p>
                    <p className="m-0">{"All"}</p>
                  </div>
                </>
              </div>
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Delivery Date</p>
                    <p className="m-0">{"All"}</p>
                  </div>
                </>
              </div>
              {/* {groupConfLocDetailData && confirmationIDDetailTable  */}
              {true ? (
                <div className="clo-12 col-md-2 p-2 reportDescItem">
                  <>
                    <div className="label">
                      <p className="m-0">
                        {
                          <Dropdown
                            renderToggle={(_props, _ref) => (
                              <div className="filter" ref={_ref} {..._props}>
                                <Icon icon="pajamas:export" /> Export
                              </div>
                            )}
                            className="ms-2 me-0 button-fixed"
                            appearance="link"
                            placement="bottomEnd"
                          >
                            <Dropdown.Item
                              icon={<FileDownloadIcon />}
                              onClick={() => {
                                exportToExcel(
                                  invoiceData,
                                  `Inventory Report All`
                                );
                              }}
                            >
                              Export to Excel
                            </Dropdown.Item>
                          </Dropdown>
                        }
                      </p>
                    </div>
                  </>
                </div>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            {groupConfLocData1 && !confirmationIDDetailTable && false ? (
              <TableComponent2
                isFromModal={false}
                tableDetails={ReportConstants["InventoryDetailConf"]}
                setConfirmationIDTable={setConfirmationIDDetailTable}
                reportList={groupConfLocData1}
                setSelectedRow={setSelectedRow}
                iconColumn={"confID"}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={{}}
                isTotalRowRequired={false}
              />
            ) : (
              <TableComponent1
                isFromModal={false}
                tableDetails={ReportConstants["InventoryDetailAll"]}
                reportList={invoiceData}
                iconColumn={""}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={totalObjAll}
                isTotalRowRequired={true}
              />
            )}
          </Modal.Body>
        </Modal>
      )}

      {detailConfData && (
        <Modal size={"full"} open={open2} onClose={handleClose2}>
          <Modal.Header>
            <span
              className="mx-2 filter"
              onClick={() => {
                setOpen2(false);
                setOpenDetail(false);
                setConfirmationIDDetailTable("");
              }}
            >
              <Icon icon="ep:back" />
            </span>
            <div style={{ textAlign: "center" }}>
              <Modal.Title>Inventory Detail Confirmation</Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:store-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">Location</p>
                    {detailConfData &&
                    detailConfData[0] &&
                    detailConfData[0].location !== undefined
                      ? detailConfData[0].location
                      : "All"}
                    <p className="m-0"></p>
                  </div>
                </>
              </div>

              {groupConfLocDetailData ? (
                <div className="clo-12 col-md-2 p-2 reportDescItem">
                  <div className="icon">
                    <Icon icon="material-symbols:storefront-outline" />
                  </div>
                  <div className="label">
                    <p className="m-0">{"Conf#"}</p>
                    <p className="m-0">
                      {groupConfLocDetailData ? "All" : getConfName()}
                    </p>
                  </div>
                </div>
              ) : null}

              {/*             <div className="col-3 p-2 reportDescItem">
              <div className="icon">
                <Icon icon="material-symbols:storefront-outline" />
              </div>
              <div className="label">
                <p className="m-0">{"Scan Type"}</p>
                <p className="m-0">{getScanType()}</p>
              </div>
            </div> */}

              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Inventory Date</p>
                    <p className="m-0">{getDeliveryDate()}</p>
                  </div>
                </>
              </div>
              <div className="clo-12 col-md-2 p-2 reportDescItem">
                <>
                  <div className="icon">
                    <Icon icon="material-symbols:person-outline-rounded" />
                  </div>
                  <div className="label">
                    <p className="m-0">Delivery Date</p>
                    <p className="m-0">
                      {moment(fromDate).format("MM-DD-YYYY")}
                    </p>
                  </div>
                </>
              </div>
              {/* {groupConfLocDetailData && confirmationIDDetailTable  */}
              {true ? (
                <div className="col-1 p-2 reportDescItem">
                  <>
                    <div className="label">
                      <p className="m-0">
                        {
                          <Dropdown
                            renderToggle={(_props, _ref) => (
                              <div className="filter" ref={_ref} {..._props}>
                                <Icon icon="pajamas:export" /> Export
                              </div>
                            )}
                            className="ms-2 me-0 button-fixed"
                            appearance="link"
                            placement="bottomEnd"
                          >
                            <Dropdown.Item
                              icon={<FileDownloadIcon />}
                              onClick={() => {
                                exportToExcel(
                                  detailConfData,
                                  `Inventory Report ${detailConfData[0].location}`
                                );
                              }}
                            >
                              Export to Excel
                            </Dropdown.Item>
                          </Dropdown>
                        }
                      </p>
                    </div>
                  </>
                </div>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            {groupConfLocData1 && !confirmationIDDetailTable && false ? (
              <TableComponent2
                isFromModal={false}
                tableDetails={ReportConstants["InventoryDetailConf"]}
                setConfirmationIDTable={setConfirmationIDDetailTable}
                reportList={groupConfLocData1}
                setSelectedRow={setSelectedRow}
                iconColumn={"confID"}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={{}}
                isTotalRowRequired={false}
              />
            ) : (
              <TableComponent1
                isFromModal={false}
                tableDetails={ReportConstants["InventoryDetailAllLocation"]}
                reportList={detailConfData}
                iconColumn={""}
                onTableRowClick={() => {}}
                reportType={""}
                totalTableRow={{}}
                isTotalRowRequired={false}
              />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ReportInventoryComponent;
