import { Modal, Dropdown } from "rsuite";
import moment from "moment";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import TableComponent from "../common/tableComponent";

export const CustomModal = ({
  handleClose,
  open,
  choosenDateData,
  exportToExcel,
  GrindConstants,
}) => {
  return (
    <>
      <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-4 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:storefront-outline" />
                </div>
                <div className="label">
                  <p className="m-0">{"Date Time "}</p>
                  <p className="m-0">
                    {choosenDateData &&
                      choosenDateData[0] &&
                      moment(choosenDateData[0].rebuild_DateTime).format(
                        "YYYY-MM-DD"
                      )}
                  </p>
                </div>
              </div>

              <div className="col-1 p-2 reportDescItem">
                <div className="d-flex">
                  <Dropdown
                    className="mx-2 button-fixed"
                    title="Export"
                    appearance="primary"
                    placement="bottomEnd"
                  >
                    <Dropdown.Item
                      icon={<FileDownloadIcon />}
                      onClick={() => {
                        exportToExcel(choosenDateData, "Detailed Grind Data");
                      }}
                    >
                      Export to Excel
                    </Dropdown.Item>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TableComponent
            isFromModal={false}
            tableDetails={GrindConstants["GrindDetail"]}
            reportList={choosenDateData}
            iconColumn={"NA"}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{
              loaded_Quantity_Case: choosenDateData
                ? choosenDateData["totalLoadedQuantity"]?.toFixed(2)
                : 0,
            }}
            isTotalRowRequired={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
