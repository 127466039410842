import { useState, useRef } from "react";
import { Grid, Row, Col } from "rsuite";
import moment from "moment";
import MainChart from "./MainChart";
import DepToStoreDepartment from "./DeptoStoreDepartment";
import DepToStoreStore from "./DeptoStoreStore";

const MainControllerStoreUse = (props: any) => {
  const { reasonReportData, toDate, fromDate, groupTableTotals, userContext } =
    props;
  const [showData, setShowData] = useState("In Store Donation");
  const [showDepartment, setShowDepartment] = useState("GROCERY");
  const [showDepToStore, setDepToStore] = useState(false);
  const [showDepToStoreStore, setDepToStoreStore] = useState(false);

  const [firstLayer, setFirstLayer] = useState(true);
  const [secondLayer, setSecondLayer] = useState(false);
  const [thirdLayer, setThirdLayer] = useState(false);
  const labels: any = [];
  const chartValue: any = [];
  // if has value of GroupTableTotals chartLabel take key.
  const entries = Object.entries(groupTableTotals);
  for (const [key, value] of entries) {
    if (value) {
      if (key !== "total" && key !== "price_Adj") {
        labels.push(key);
        chartValue.push(value);
      }
    }
  }

  //Department
  const GroupByDepartment = (reportList) => {
    const totalsByDepartment = {};
    for (const item of reportList?.reportList) {
      const {
        department,
        TSFR_to_Bakery,
        TSFR_to_Deli,
        total,
        TSFR_to_Seafood,
        Office_Use,
      } = item;
      const inStoreDonation = item["In Store Donation"];
      if (department) {
        if (!totalsByDepartment.hasOwnProperty(department)) {
          totalsByDepartment[department] = {
            TSFR_to_Bakery: 0,
            TSFR_to_Deli: 0,
            TSFR_to_Seafood: 0,
            Office_Use: 0,
            In_Store_Donation: 0,
            total: 0,
          };
        }
        totalsByDepartment[department].TSFR_to_Bakery +=
          typeof TSFR_to_Bakery === "number" ? TSFR_to_Bakery : 0;

        totalsByDepartment[department].TSFR_to_Deli +=
          typeof TSFR_to_Deli === "number" ? TSFR_to_Deli : 0;

        totalsByDepartment[department].TSFR_to_Seafood +=
          typeof TSFR_to_Seafood === "number" ? TSFR_to_Seafood : 0;

        totalsByDepartment[department].Office_Use +=
          typeof Office_Use === "number" ? Office_Use : 0;

        totalsByDepartment[department].In_Store_Donation += inStoreDonation;

        totalsByDepartment[department].total +=
          typeof total === "number" ? total : 0;
      }
    }

    return totalsByDepartment;
  };
  const getAllDataByDepartmentToStore = (data) => {
    const departmentAllData = {};
    for (const item of data) {
      const department = item.department;
      const storeID = item.store_ID;
      if (!departmentAllData[department]) {
        departmentAllData[department] = {};
      }
      if (!departmentAllData[department][storeID]) {
        departmentAllData[department][storeID] = {
          TSFR_to_Deli: 0,
          TSFR_to_Bakery: 0,
          TSFR_to_Seafood: 0,
          Office_Use: 0,
          "In Store Donation": 0,
        };
      }
      departmentAllData[department][storeID]["TSFR_to_Deli"] +=
        item["TSFR_to_Deli"];
      departmentAllData[department][storeID]["TSFR_to_Bakery"] +=
        item["TSFR_to_Bakery"];
      departmentAllData[department][storeID]["TSFR_to_Seafood"] +=
        item["TSFR_to_Seafood"];

      departmentAllData[department][storeID]["Office_Use"] +=
        item["Office_Use"];

      departmentAllData[department][storeID]["In Store Donation"] +=
        item["In Store Donation"];
    }
    return departmentAllData;
  };

  //#Region Department and Department ID
  const DepartmentData = (reportList) => {
    const DepartmentData = {};

    for (const item of reportList) {
      if (item.department !== "") {
        const department = item.department;
        if (!DepartmentData.hasOwnProperty(department)) {
          DepartmentData[department] = {
            poS_Department_ID: 0,
          };
        }
        DepartmentData[department].poS_Department_ID = item.poS_Department_ID;
      }
    }
    return DepartmentData;
  };

  //#endregion

  let ref3 = useRef();
  let ref4 = useRef();

  function scrollTo(ref) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
            {firstLayer && (
              <MainChart
                setFirstLayer={setFirstLayer}
                setSecondLayer={setSecondLayer}
                setThirdLayer={setThirdLayer}
                value={chartValue}
                totalValue={groupTableTotals.total ? groupTableTotals.total : 0}
                setDepToStore={setDepToStore}
                labels={labels}
                MainData={setShowData}
                toDate={moment(toDate).format("MM/DD/YYYY")}
                fromDate={moment(fromDate).format("MM/DD/YYYY")}
                ref4={ref4}
                ref3={ref3}
                scrollTo={scrollTo}
              />
            )}
        </Row>
        <Row className="show-grid">
          {showDepToStore ? (
            <>
              {secondLayer && (
                <DepToStoreDepartment
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                  setDepToStoreStore={setDepToStoreStore}
                  MainLabel={showData}
                  setShowDepartment={setShowDepartment}
                  value={GroupByDepartment(reasonReportData)}
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref4={ref4}
                  ref3={ref3}
                  scrollTo={scrollTo}
                />
              )}
            </>
          ) : (
            ""
          )}
          {showDepToStoreStore ? (
            <>
              {thirdLayer && (
                <DepToStoreStore
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                  MainLabel={showData}
                  showDepartment={showDepartment}
                  value={
                    getAllDataByDepartmentToStore(reasonReportData.reportList)[
                      showDepartment
                    ]
                  }
                  departmentData={DepartmentData(reasonReportData.reportList)}
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref4={ref4}
                  userContext={userContext}
                />
              )}
            </>
          ) : (
            ""
          )}
        </Row>
      </Grid>
    </>
  );
};

export default MainControllerStoreUse;
