export class Filters {
  showingRecords?: string;
  totalRecords?: number;
  totalPageCount?: number;
  offset?: number;
  currentPage?: number;
  currentPageSize?: number;

  constructor() {
    this.offset = 0;
    this.showingRecords = "";
    this.totalRecords = 0;
    this.totalPageCount = 0;
    this.currentPage = 1;
    this.currentPageSize = 10;
  }
}
