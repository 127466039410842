
import UserListForAdmin from "./ForAdminUsers/UserListForAdmin";
import UserListForSuperAdmin from "./ForSuperAdminUsers/UserListForSuperAdmin";
import UserListForGroup from "./ForGroupUsers/UserListForGroup";

const UserList = (props: any) => {
  const { userContext, handleSpinner } = props;
  return (
    <>
      {userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin" ? (
        <UserListForSuperAdmin
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      ) : userContext &&
        userContext.groupTypeId &&
        userContext.groupTypeName ? (
        <UserListForAdmin
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      ) : (
        <UserListForGroup
          userContext={userContext}
          handleSpinner={handleSpinner}
        />
      )}
    </>
  );
};

export default UserList;

//   {userContext &&
//   userContext.roleName &&
//   userContext.roleName === "Super Admin" ? (
//     <UserListForSuperAdmin
//       userContext={userContext}
//       handleSpinner={handleSpinner}
//     />
//   ) : (
//     <UserListForAdmin
//       userContext={userContext}
//       handleSpinner={handleSpinner}
//     />
//   )}
