import { ClipLoader } from "react-spinners";

const SpinnerComponent = ({ message = "Loading" }: any) => {

    return <><div className={`loaderContainer`}>
        <ClipLoader color="#4667C8" />
        <h6>{message}</h6>
    </div></>;

}

export default SpinnerComponent;