export const ReportLDTSConstants = {
  LastDayToSaveMain: [
    {
      Header: "Description",
      DataKey: "description",
      Width: 100,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Size",
      DataKey: "size",
      Width: 90,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Save Amount",
      DataKey: "saving_amount",
      Width: 150,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Cost %",
      DataKey: "cost_Change_Percent",
      Width: 90,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Today Cost ",
      DataKey: "f_Cost_0",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "sUpDown",
      Width: 50,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "WK1 Best Cost",
      DataKey: "cost_S_W_1",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "WK2 Best Cost",
      DataKey: "cost_S_W_2",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "WK3 Best Cost",
      DataKey: "cost_S_W_3",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "WK4 Best Cost",
      DataKey: "cost_S_W_4",
      Width: 120,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Adv Order Pending",
      DataKey: "adv_Ord_Peding_Qty",
      Width: 140,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Adv Order Sent",
      DataKey: "adv_Ord_Qty",
      Width: 130,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Adv Order Total",
      DataKey: "adv_Ord_Total_Qty",
      Width: 130,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Item",
      DataKey: "item_ID",
      Width: 90,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "13 wks mvmnt",
      DataKey: "avg_13_Wks_Movement",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "13 wks purch",
      DataKey: "avg_13_Wks_Purch",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Qty in W/H",
      DataKey: "cases_in_WH",
      Width: 110,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Qty Pending W/H",
      DataKey: "cases_Pending_WH",
      Width: 130,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "PB Diff",
      DataKey: "pB_Diff",
      Width: 100,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Ad Future",
      DataKey: "ad_Future",
      Width: 100,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
  ],
  LastDayToSaveDetail: [
    // {
    //   Header: "Ad Group",
    //   DataKey: "ad_group",
    //   Width: 10,
    //   isFixed: true,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "text-center-heading",
    //   isSearch: true,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    {
      Header: "Store Name",
      DataKey: "store_Name",
      Width: 10,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Store",
      DataKey: "store_ID",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "O/H Supply Days",
      DataKey: "onHand_Supply_Days",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Will Hold",
      DataKey: "will_Hold",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "POS MVT ",
      DataKey: "poS_MVT",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Last 13 weeks Avg Purch",
      DataKey: "purch_Avg_13",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "PB Next Days",
      DataKey: "prebook_Next_Days",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Future Ad",
      DataKey: "ad_Future",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Price Type",
      DataKey: "price_Type",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Best Savings in 3 WKs Range",
      DataKey: "cost_Best",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Best Savings for this WK",
      DataKey: "cost_BestWeek",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Save Amount",
      DataKey: "saving_amount",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_0",
      Width: 10,
      isFixed: false,
      isEditableTable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_7",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_6",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_5",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_4",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_3",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_2",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },
    {
      Header: "",
      DataKey: "total_Qty_Back_1",
      Width: 10,
      isFixed: false,
      isEditableTable: false,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isOrderable: false,
    },

    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_0",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_1",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_2",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_3",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_4",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_5",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_6",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "ordeR_Qty_7",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: true,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    // {
    //   Header: "",
    //   DataKey: "",
    //   Width: 10,
    //   isFixed: false,
    //   isSortable: false,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    // },
    {
      Header: "",
      DataKey: "total_Qty_0",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_1",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_2",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_3",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_4",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_5",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_6",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "",
      DataKey: "total_Qty_7",
      Width: 10,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    // {
    //   Header: "",
    //   DataKey: "",
    //   Width: 10,
    //   isFixed: false,
    //   isEditableTable: false,
    //   Align: "left",
    //   TypeOfData: "Number",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    //   isOrderable: false,
    // },
    // {
    //   Header: "",
    //   DataKey: "total_Qty_Back_0",
    //   Width: 10,
    //   isFixed: false,
    //   isEditableTable: false,
    //   Align: "left",
    //   TypeOfData: "Text",
    //   isSearch: false,
    //   isHeader: true,
    //   Icon: "",
    //   isSubHeader: false,
    //   colSpan: 1,
    //   isOrderable: false,
    // },
  ],

  AMPDetailConstants: [
    {
      Header: "Day",
      DataKey: "dd",
      Width: 20,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Delivery",
      DataKey: "delivery_Date1",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Ord Item",
      DataKey: "adJ_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isInput: true,
    },
    {
      Header: "Adv Ord",
      DataKey: "adV_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Ord",
      DataKey: "orD_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Prebook",
      DataKey: "pB_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Components",
      DataKey: "pbC_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "AMP",
      DataKey: "amP_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Cost",
      DataKey: "cost",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "EBA",
      DataKey: "ebA_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Bill Back",
      DataKey: "billBack_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Scan Qty",
      DataKey: "scan_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Food Show",
      DataKey: "food_show_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Power Buy",
      DataKey: "power_Buy_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Sos",
      DataKey: "sos_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Price Type",
      DataKey: "price_Type",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Order Cost",
      DataKey: "orD_Cost",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Prebook Cost",
      DataKey: "prebook_Cost",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Movement Units",
      DataKey: "mvmt_qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Inv Qty",
      DataKey: "inv_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Inv Comp Qty",
      DataKey: "inv_Comp_Qty",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Delivery Date",
      DataKey: "delivery_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
  ],

  DiscountDetails: [
    {
      Header: "Record",
      DataKey: "type1",
      Width: 20,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Type",
      DataKey: "type2",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Amount",
      DataKey: "amount",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Start Date",
      DataKey: "start_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "End Date",
      DataKey: "end_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Add Date",
      DataKey: "add_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Active",
      DataKey: "active",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isInput: true,
    },
    {
      Header: "File",
      DataKey: "file_Name",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "File ID",
      DataKey: "file_ID",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "End File ID",
      DataKey: "end_File_ID",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "End File",
      DataKey: "end_File",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
  ],
  PriceDetails: [
    {
      Header: "Type",
      DataKey: "type",
      Width: 20,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Per",
      DataKey: "per",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Price",
      DataKey: "price",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "End Date",
      DataKey: "end_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Start Date",
      DataKey: "start_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Add Date",
      DataKey: "add_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Active",
      DataKey: "active",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
      isInput: true,
    },
    {
      Header: "File",
      DataKey: "file_Name",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "File ID",
      DataKey: "file_ID",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
  ],
  CostPrebookDetails: [
    {
      Header: "Type",
      DataKey: "type",
      Width: 20,
      isFixed: true,
      isSortable: true,
      Align: "left",
      TypeOfData: "text-center-heading",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Cost",
      DataKey: "cost",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: false,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "AMP",
      DataKey: "amp",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "EBA",
      DataKey: "eba",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "FS",
      DataKey: "fs",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Price",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Control",
      DataKey: "control_Number",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Ship Date",
      DataKey: "shipDate_Start",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Ship End",
      DataKey: "shipDate_End",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "Add Date",
      DataKey: "add_Date",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Date",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "File",
      DataKey: "file_Name",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Text",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
    {
      Header: "File ID",
      DataKey: "file_ID",
      Width: 20,
      isFixed: false,
      isSortable: true,
      Align: "left",
      TypeOfData: "Number",
      isSearch: true,
      isHeader: true,
      Icon: "",
      isSubHeader: false,
      colSpan: 1,
    },
  ],
};
