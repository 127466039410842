import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonGroup,
  CheckPicker,
  Drawer,
  Dropdown,
  IconButton,
  Input,
  InputGroup,
  Modal,
  PickerHandle,
} from "rsuite";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { Modal } from 'react-bootstrap';
import moment from "moment";
import { Icon } from "@iconify/react";
import "./styles/_page-header.scss";
import * as ReportService from "services/reportServices";
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";

const Calendar = (props: any) => {
  const { value, calenderOnClick } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [fromDate, setFromDate] = useState<Date>(value[0]);
  const [toDate, setToDate] = useState<Date>(value[1]);
  // const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  // const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  const [toastflag, showToastflag] = useState(false);
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Markdown & Shrink",
  });
  const [selectedrange, setSelectedRange] = useState("Yesterday");
  const [disable, showDisabled] = useState(false);
  const [state, setState] = useState([
    {
      startDate: fromDate,
      endDate: toDate,
      key: "selection",
    },
  ]);
  const minDate = subDays(new Date(), 999999);

  const customdates = {
    endOfWeek: new Date(fromDate),
    startOfWeek: addDays(new Date(fromDate), -6),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfday: startOfDay(new Date(fromDate)),
    endOfDay: endOfDay(new Date(fromDate)),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    // fromDate:new Date(fromDate),
    // toDate:new Date(toDate)
  };
  const handleBackward = () => {
    if (prevNextValidation("Previous")) {
      onDateselectionchange("previous");
      //onDateChange(state);
    }
    //onDateChange(state);
  };
  // useEffect(()=> {
  //   onDateChange(state)
  // },[state])
  const handleForward = () => {
    if (prevNextValidation("Next")) {
      onDateselectionchange("next");
      //onDateChange(state);
    }
    //onDateChange(state);
  };

  const staticRangeHandler = {
    range: {},
    isSelected(range) {
      const definedRange = this.range();
      return (
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
      );
    },
  };
  const createStaticRanges = (ranges) => {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
  };
  const defaultStaticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: customdates.startOfYesterday,
        endDate: customdates.endOfYesterday,
      }),
    },

    {
      label: "Week",
      range: () => ({
        startDate: customdates.startOfWeek,
        endDate: customdates.endOfWeek,
      }),
    },
    {
      label: "Single Day",
      range: () => ({
        startDate: customdates.startOfday,
        endDate: customdates.endOfDay,
      }),
    },
    // {
    //   label: 'Custom',
    //   range: () => ({
    //     startDate:new Date(fromDate),
    //     endDate: new Date(toDate),
    //   }),
    // },
  ]);
  // console.log("default", defaultStaticRanges)

  const onDateChange = (value) => {
    // setState([value.selection])
    if (value) {
      setFromDate(value[0].startDate);
      setToDate(value[0].endDate);
      calenderOnClick(value[0].startDate, value[0].endDate);
      showToastflag(true);
      //setIsOpen(false);
    }
  };
  const onDateselectionchange = useCallback(
    (value) => {
      let _fromDate = fromDate;
      let _toDate = toDate;
      if (
        moment(_fromDate).format("MM-DD-YYYY") ===
        moment(_toDate).format("MM-DD-YYYY")
      ) {
        if (value === "previous") {
          _fromDate = moment(fromDate).subtract(1, "days").toDate();
          _toDate = moment(fromDate).subtract(1, "days").toDate();
        } else if (value === "next") {
          _fromDate = moment(fromDate).add(1, "days").toDate();
          _toDate = moment(fromDate).add(1, "days").toDate();
        }
      } else if (
        moment(_fromDate).add(6, "days").format("MM-DD-YYYY") ===
        moment(_toDate).format("MM-DD-YYYY")
      ) {
        if (value === "previous") {
          _fromDate = moment(fromDate).subtract(7, "days").toDate();
          _toDate = moment(toDate).subtract(7, "days").toDate();
        } else if (value === "next") {
          _fromDate = moment(fromDate).add(7, "days").toDate();
          _toDate = moment(toDate).add(7, "days").toDate();
        }
      } else if (
        moment(_fromDate) !== moment(_toDate) &&
        moment(_fromDate).add(6, "days").format("MM-DD-YYYY") !==
          moment(_toDate).format("MM-DD-YYYY")
      ) {
        let x = moment(toDate).diff(moment(fromDate), "days");
        if (value === "previous") {
          _fromDate = moment(fromDate)
            .subtract(x + 1, "days")
            .toDate();
          _toDate = moment(toDate)
            .subtract(x + 1, "days")
            .toDate();
        } else if (value === "next") {
          _fromDate = moment(fromDate)
            .add(x + 1, "days")
            .toDate();
          _toDate = moment(toDate)
            .add(x + 1, "days")
            .toDate();
        }
      }
      setFromDate(_fromDate);
      setToDate(_toDate);
      setState([{ startDate: _fromDate, endDate: _toDate, key: "selection" }]);
      updateRangeSelection(_fromDate, _toDate);
    },
    [fromDate, toDate, selectedTab]
  );

  const prevNextValidation = (button) => {
    let isValid = false;
    let x = moment(toDate).diff(moment(fromDate), "days");
    if (button == "Previous") {
      if (
        moment(fromDate).format("MM-DD-YYYY") ===
          moment(toDate).format("MM-DD-YYYY") &&
        fromDate > new Date(minDate) &&
        toDate > new Date(minDate)
      ) {
        isValid = true;
      } else if (
        moment(fromDate).format("MM-DD-YYYY") !==
        moment(toDate).format("MM-DD-YYYY")
      ) {
        let _fromDate = moment(fromDate)
          .subtract(x + 1, "days")
          .toDate();
        let _toDate = moment(toDate)
          .subtract(x + 1, "days")
          .toDate();
        if (
          _fromDate >= new Date(minDate.toLocaleDateString()) &&
          _toDate >= new Date(minDate.toLocaleDateString())
        ) {
          isValid = true;
        }
      }
    } else if (button == "Next") {
      if (
        moment(fromDate).format("MM-DD-YYYY") !==
        moment(toDate).format("MM-DD-YYYY")
      ) {
        let _fromDate = moment(fromDate)
          .add(x + 1, "days")
          .toDate();
        let _toDate = moment(toDate)
          .add(x + 1, "days")
          .toDate();
        if (
          _fromDate >= new Date(minDate.toLocaleDateString()) &&
          _toDate >= new Date(minDate.toLocaleDateString())
        ) {
          isValid = true;
        }
      } else if (
        moment(fromDate).format("MM-DD-YYYY") ===
          moment(toDate).format("MM-DD-YYYY") &&
        fromDate > new Date(minDate) &&
        toDate > new Date(minDate)
      ) {
        isValid = true;
      }
    } else {
      if (
        moment(fromDate).format("MM-DD-YYYY") ===
          moment(toDate).format("MM-DD-YYYY") &&
        new Date(fromDate.toLocaleDateString()) < subDays(new Date(), 1) &&
        new Date(toDate.toLocaleDateString()) < subDays(new Date(), 1)
      ) {
        isValid = true;
      } else if (
        moment(fromDate).format("MM-DD-YYYY") !==
        moment(toDate).format("MM-DD-YYYY")
      ) {
        let _fromDate = moment(fromDate)
          .add(x + 1, "days")
          .toDate();
        let _toDate = moment(toDate)
          .add(x + 1, "days")
          .toDate();
        if (
          new Date(_fromDate.toLocaleDateString()) <
            new Date(addDays(new Date(), 1).toLocaleDateString()) &&
          new Date(_toDate.toLocaleDateString()) <
            new Date(addDays(new Date(), 1).toLocaleDateString())
        ) {
          isValid = true;
        }
      }
    }
    return isValid;
  };

  const onCustomRangeSelection = (value) => {
    switch (value) {
      case "Today":
        setFromDate(new Date());
        setToDate(new Date());
        setState([
          { startDate: new Date(), endDate: new Date(), key: "selection" },
        ]);
        setSelectedRange("Today");
        showDisabled(true);
        break;
      case "Yesterday":
        setFromDate(subDays(new Date(), 1));
        setToDate(subDays(new Date(), 1));
        setState([
          {
            startDate: subDays(new Date(), 1),
            endDate: endOfDay(subDays(new Date(), 1)),
            key: "selection",
          },
        ]);
        setSelectedRange("Yesterday");
        break;
      case "Week":
        setFromDate(addDays(new Date(), -6));
        setToDate(new Date());
        setState([
          {
            startDate: addDays(new Date(), -6),
            endDate: new Date(),
            key: "selection",
          },
        ]);
        setSelectedRange("Week");
        break;
      case "Single Day":
        setFromDate(new Date(fromDate));
        setToDate(new Date(fromDate));
        setState([
          {
            startDate: new Date(fromDate),
            endDate: new Date(fromDate),
            key: "selection",
          },
        ]);
        setSelectedRange("Single Day");
        break;
      case "Custom":
        setFromDate(new Date(fromDate));
        setToDate(new Date(toDate));
        setState([
          {
            startDate: new Date(fromDate),
            endDate: new Date(toDate),
            key: "selection",
          },
        ]);
        setSelectedRange("Custom");
        break;

      default:
        break;
    }
  };

  const updateRangeSelection = (startDate, endDate) => {
    if (
      new Date(startDate).toLocaleDateString() ===
        addDays(new Date(), -6).toLocaleDateString() &&
      new Date(endDate).toLocaleDateString() === new Date().toLocaleDateString()
    ) {
      setSelectedRange("Week");
    } else if (
      new Date(startDate).toLocaleDateString() ===
        startOfDay(addDays(new Date(), -1)).toLocaleDateString() &&
      new Date(endDate).toLocaleDateString() ===
        endOfDay(addDays(new Date(), -1)).toLocaleDateString()
    ) {
      setSelectedRange("Yesterday");
    } else if (
      new Date(startDate).toLocaleDateString() ===
        new Date().toLocaleDateString() &&
      new Date(endDate).toLocaleDateString() === new Date().toLocaleDateString()
    ) {
      setSelectedRange("Today");
    } else if (
      new Date(startDate).toLocaleDateString() ===
        new Date(fromDate).toLocaleDateString() &&
      new Date(endDate).toLocaleDateString() ===
        new Date(fromDate).toLocaleDateString()
    ) {
      setSelectedRange("Single Day");
    } else {
      setSelectedRange("Custom");
    }
  };

  const handleIconButtonClick = () => {
    setIsOpen(true);
  };
  const onCloseModal = () => {
    setFromDate(value[0]);
    setToDate(value[1]);
    setState([
      {
        startDate: new Date(value[0]),
        endDate: new Date(value[1]),
        key: "selection",
      },
    ]);
    setIsOpen(false);
  };
  let _fromDate1 = moment(fromDate).format("MM-DD-YYYY");
  let _toDate1 = moment(toDate).format("MM-DD-YYYY");

  //add
  function onOpenModal(state) {
    onDateChange(state);
    setIsOpen(false);
  }
  return (
    <>
      {/* {prevNextValidation("Previous") && (
        <Button
          className=" button-fixed"
          appearance="ghost"
          onClick={handleBackward}
        >
          Previous
        </Button>
      )} */}
      <div style={{ width: "100%" }}>
        {" "}
        <InputGroup
          onClick={handleIconButtonClick}
          style={{ width: "100%", marginLeft: "0 10px" }}
          className="select-date"
        >
          <Input
            readOnly
            value={`${_fromDate1} to ${_toDate1}`}
            style={{ cursor: "default" }}
          />
          <InputGroup.Button>
            <Icon icon="cil:calendar" width="20" height="20" />
          </InputGroup.Button>
        </InputGroup>
      </div>

      {/* {prevNextValidation("Next") && (
        <Button
          className="me-3 button-fixed"
          appearance="ghost"
          onClick={handleForward}
        >
          Next
        </Button>
      )} */}

      <Modal size="sm" open={isOpen} overflow={false} onClose={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select Delivery Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-2">
              <ButtonGroup >
                <Button
                  className="text-start"
                  appearance={selectedrange === "Today" ? "primary" : "subtle"}
                  onClick={() => onCustomRangeSelection("Today")}
                >
                  Today
                </Button>
                <Button
                  className="text-start"
                  appearance={
                    selectedrange === "Yesterday" ? "primary" : "subtle"
                  }
                  onClick={() => onCustomRangeSelection("Yesterday")}
                >
                  Yesterday
                </Button>
                <Button
                  className="text-start"
                  appearance={selectedrange === "Week" ? "primary" : "subtle"}
                  onClick={() => onCustomRangeSelection("Week")}
                >
                  Week
                </Button>
                <Button
                  className="text-start"
                  appearance={
                    selectedrange === "Single Day" ? "primary" : "subtle"
                  }
                  onClick={() => onCustomRangeSelection("Single Day")}
                >
                  Single Day
                </Button>
                <Button
                  className="text-start"
                  appearance={selectedrange === "Custom" ? "primary" : "subtle"}
                  onClick={() => onCustomRangeSelection("Custom")}
                >
                  Custom
                </Button>
              </ButtonGroup>
            </div>
            <div className="col-10">
              <DateRangePicker
                value={[fromDate, toDate]}
                cleanable={false}
                direction="horizontal"
                appearance="default"
                placeholder="Date Range"
                format="MM-dd-yyyy"
                name="dateRange"
                character={" to "}
                staticRanges={[]}
                showCustomRangeLabel={false}
                ranges={state}
                // maxDate={new Date()}
                minDate={minDate}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                onChange={(value) => {
                  setState([value.selection]);
                  if (value) {
                    setFromDate(value.selection.startDate);
                    setToDate(value.selection.endDate);
                    updateRangeSelection(
                      value.selection.startDate,
                      value.selection.endDate
                    );
                  }
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="row">
          <div className="col-6 d-flex justify-content-start">
            {prevNextValidation("Previous") && (
              <Button
                className="me-3 button-fixed"
                appearance="ghost"
                onClick={handleBackward}
              >
                Previous
              </Button>
            )}
            {prevNextValidation("Next") && (
              <Button
                className="me-3 button-fixed"
                appearance="ghost"
                onClick={handleForward}
              >
                Next
              </Button>
            )}
          </div>
          <div className="col-6 d-flex justify-content-end">
            <Button className={`me-3`} onClick={() => onCloseModal()}>
              Cancel
            </Button>
            <Button
              appearance="primary"
              disabled={
                // fromDate > new Date() ||
                // toDate > new Date() ||
                fromDate < subDays(new Date(minDate), 1) ||
                toDate < subDays(new Date(minDate), 1)
              }
              onClick={() => onOpenModal(state)}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Calendar;
