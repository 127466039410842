import { useEffect, useState } from "react";
import { Dropdown } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Constants from "../../.././utils/constants";
import TableComponent from "./TableComponentForEquipment";
import { Icon } from "@iconify/react";
import moment from "moment";
import * as SettingsServices from "../../../services/settingsServices";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import { EquipmentByStoreConstants } from "./EquipmentByStoreConstants";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";

const EquipmentByStore = (props: any) => {
  const { handleSpinner, userContext = {} } = props;
  const [storeName, setStoreName] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [userStore, setUserStore] = useState("");
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(
    userContext && userContext.roleName !== "Super Admin"
      ? userContext.groupTypeId === 0 &&
        userContext.store === 0 &&
        userContext.groupTypeId === 1
        ? "1"
        : "2"
      : 1
  );
  const [groupTypeID, setGroupTypeID] = useState(null);
  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 3
    ) {
      GetGroupAndStoreByTypes("3", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getEquipmentByStore(userStore);
  }, [userStore, storeNameHeader, storeID]);

  useEffect(() => {
    if (
      props.userContext &&
      props.userContext.store &&
      props.userContext.store !== 0
    ) {
      getStoreName(props.userContext.store);
    }
  }, [props.userContext]);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion
  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2" || IDType === "3") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (userContext && userContext.roleName === "DM") {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        awgOrGotSelect
      )
        .then((result) => {
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (awgOrGotSelect) {
      SettingsServices.getVendorStoreName(
        grouptypeID1,
        "GetJustStore",
        groupType
      )
        .then((result) => {
          if (groupType === 2 || groupType === 3) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            return setArrayID.push(item.store_ID);
          });
          result.records.filter((item) => {
            return setArrayName.push(item.store_Name);
          });

          setStoreName(setArrayName);
          //setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  //#endregion
  // const getVendorStoreName = async (grouptypeID1) => {
  //   const { handleSpinner, userContext = {} } = props;
  //   const { groupTypeId, groupType, store,user_ID } = userContext;
  //   handleSpinner && handleSpinner(true);
  //   if (store) {
  //     SettingsServices.getVendorStoreName(store, "Store")
  //       .then((result) => {
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];

  //         result.records.filter((item) => {
  //           if (!setArrayID.includes(item.store_ID)) {
  //             setArrayID.push(item.store_ID);
  //           }
  //         });
  //         result.records.filter((item) => {
  //           if (!setArrayName.includes(item.store_Name)) {
  //             setArrayName.push(item.store_Name);
  //           }
  //         });

  //         setStoreName(setArrayName);
  //         setUserStore(setArrayID[0]);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   } else
  //   var storesArray = [];
  //   AuthService.getDMStores(user_ID)
  //     .then((result1) => {
  //       result1.records.map((item) => storesArray.push(item.stores));
  //     })
  //     .catch((err) => {
  //       console.error("Error: Get Data - ", err);
  //       // toast.error("Error Loading Orders");
  //     });
  //     SettingsServices.getVendorStoreName(grouptypeID1, "")
  //       .then((result) => {
  //         if (groupType === 2) {
  //           result.records.map((item) => {
  //             return (item.store_Name =
  //               item.store_ID + " / " + item.store_Name);
  //           });
  //         }

  //         result.records.sort((a, b) => {
  //           const Store_A = a.store_ID;
  //           const Store_B = b.store_ID;

  //           if (Store_A < Store_B) {
  //             return -1;
  //           } else if (Store_A > Store_B) {
  //             return 1;
  //           } else {
  //             return 0;
  //           }
  //         });
  //         if (storesArray.length >= 1) {
  //           result.records = result.records.filter((item) =>
  //             storesArray.includes(item.store_ID.toString())
  //           );
  //         }
  //         //console.log("StoreName: Result - ", result.records);
  //         var setArrayID = [];
  //         var setArrayName = [];
  //         result.records.filter((item) => {
  //           if (!setArrayID.includes(item.store_ID)) {
  //             setArrayID.push(item.store_ID);
  //           }
  //         });
  //         result.records.filter((item) => {
  //           if (!setArrayName.includes(item.store_Name)) {
  //             setArrayName.push(item.store_Name);
  //           }
  //         });

  //         setStoreName(setArrayName);
  //         setUserStore(setArrayID[0]);
  //         setStoreID(setArrayID);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Data - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  // };
  //console.log(storeName);
  const getEquipmentByStore = async (Store_ID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    handleSpinner && handleSpinner(true);
    if (store !== 0 && userContext.roleName !== "Super Admin") {
      SettingsServices.GetEquipmentByStore(store)
        .then((result) => {
          result?.records?.map(
            (item) =>
              (item.in_Service = moment(item?.in_Service).format("MM-DD-YYYY"))
          );
          result?.records?.map((item) => {
            if (item.add_Date) {
              item.add_Date = moment(item?.add_Date).format("MM-DD-YYYY");
            }
          });
          setEquipmentData(result.records);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.GetEquipmentByStore(Store_ID)
        .then((result) => {
          result?.records?.map(
            (item) =>
              (item.in_Service = moment(item?.in_Service).format("MM-DD-YYYY"))
          );
          result?.records?.map((item) => {
            if (item.add_Date) {
              item.add_Date = moment(item?.add_Date).format("MM-DD-YYYY");
            }
          });
          setEquipmentData(result.records);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  return (
    <>
      <div className="scrollable-div">
        <div>
          <PageHeaderComponent
            setGroupTypeID={setGroupTypeID}
            setAwgOrGotSelect={setAwgOrGotSelect}
            setUserStore={setUserStore}
            userContext={userContext}
            groupAndGroupID={groupAndGroupID}
            awgOrGot={awgOrGot}
            storeName={storeName}
            storeNameHeader={storeNameHeader}
            storeID={storeID}
            isVendor={false}
            isCalendar={false}
            isDepartment={false}
          />


          {" "}
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '16px' }}>

            <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <Icon icon="pajamas:export" /> Export
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(equipmentData, `Equipment By Store ${userStore}`);
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>

        <div>
          {" "}
          <TableComponent
            userContext={userContext}
            userStore={userStore}
            isFromModal={false}
            tableDetails={EquipmentByStoreConstants["EquipmentByStore"]}
            reportList={equipmentData}
            iconColumn={"NA"}
            onTableRowClick={() => { }}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        </div>
      </div>
    </>
  );
};

export default EquipmentByStore;
