import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const NotFoundComponent = () => {
    const location = useLocation();
    const [title, setTitle] = useState("Not Found");

    useEffect(() => {
        const { state } = location;
        if (state) {
            setTitle(state.headerTitle ?? "");
        }
    }, [location]);
    return <>
        <div className="not-found">
            <h1>{`Coming Soon.!`}</h1>
            <h2>{title}</h2>
        </div>
    </>;

}

export default NotFoundComponent;