import axios, { AxiosRequestConfig } from "axios";
import Constants from "./constants";

const createBasicConfig = () => {
  const config: AxiosRequestConfig = {
    baseURL: Constants.baseApiUrl,
    headers: {
      Accept: "application/json, application/javascript",
    },
    // timeout: 30000, // 30 seconds
    withCredentials: false,
  };
  return config;
};

const createConfig = (): any => {
  // let ContextInfo = this.authService.ContextInfo().context;
  const config: AxiosRequestConfig = {
    baseURL: Constants.baseApiUrl,
    headers: {
      // Authorization: 'Bearer ' + ContextInfo.token,
      Accept: "application/json, application/javascript",
    },
    // timeout: 30000, // 30 seconds
    withCredentials: false,
  };
  return config;
};

export const AxiosService = {
  GetAnonymous: (url: string): Promise<any> => {
    return axios.get(url, createBasicConfig());
  },
  PostAnonymous: (url: string, data: any): Promise<any> => {
    return axios.post(url, data, createBasicConfig());
  },
  Get: (url: string): Promise<any> => {
    return axios.get(url, createConfig());
  },
  Post: (url: string, data: any): Promise<any> => {
    return axios.post(url, data, createConfig());
  },
};
