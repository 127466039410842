import axios, { AxiosRequestConfig } from "axios";
import Constants from "../../src/./utils/constants";
import { stringDecompression } from "./stringLZService";

const API_URL = {
  VendorStoreData: "https://api.gotsystems.net/api/Settings/VendorStoreName",
  ScheduletimeData:
    "https://api.gotsystems.net/api/Settings/DeliveryScheduleTime",

  DeliveryScheduleWeekDay:
    "https://api.gotsystems.net/api/Settings/DeliveryScheduleWeekDay",
  EquipmentByStore:
    "https://api.gotsystems.net/api/Settings/GetEquipmentByStore",
  MilkOrderBook: "https://api.gotsystems.net/api/Settings/MilkOrderBook",
  MilkOrderBookDetail:
    "https://api.gotsystems.net/api/Settings/MilkOrderBookDetail",
  MilkDeliverySchedule:
    "https://api.gotsystems.net/api/Settings/MilkDeliverySchedule",
  OrderReceipt: "https://api.gotsystems.net/api/Settings/OrderReceipt",

  OrderDetailAmp: "https://api.gotsystems.net/api/Settings/OrderDetailAmp",
  OrderInvoice: "https://api.gotsystems.net/api/Settings/OrderInvoice",

  AdGroupandGroupID:
    "https://api.gotsystems.net/api/Settings/GetAdGroupandGroupID",
  GetMarkdownDiscountSetup:
    "https://api.gotsystems.net/api/Settings/GetMarkdownDiscountSetup",
  GetMarkdownLabelSetup:
    "https://api.gotsystems.net/api/Settings/GetMarkdownLabelSetup",
  InsertMarkdownLabelSetup:
    "https://api.gotsystems.net/api/Settings/InsertMarkdownLabel",
  DeleteMarkdownLabelSetup:
    "https://api.gotsystems.net/api/Settings/DeleteMarkdownLabel",
  GetMarkdownDepartmentSetup:
    "https://api.gotsystems.net/api/Settings/GetMarkdownDepartmentSetup",
  GetMarkdownSubDepartmentSetup:
    "https://api.gotsystems.net/api/Settings/GetMarkdownSubDepartmentSetup",

  InsertMarkdownDiscountSetup:
    "https://api.gotsystems.net/api/Settings/InsertMarkdownDiscount",

  DeleteMarkdownDiscountSetup:
    "https://api.gotsystems.net/api/Settings/DeleteMarkdownDiscount",
};

const createConfig = (): any => {
  // let ContextInfo = this.authService.ContextInfo().context;
  const config: AxiosRequestConfig = {
    baseURL: Constants.baseApiUrl,
    headers: {
      // Authorization: 'Bearer ' + ContextInfo.token,
      Accept: "application/json, text/javascript, */*; q=0.01",
      "Content-Type": "application/json",
    },
    // timeout: 30000, // 30 seconds

    withCredentials: false,
  };
  return config;
};

const AxiosService = {
  Post: (url: string, data: any): Promise<any> => {
    return axios.post(url, data, createConfig());
  },
};

//Get Vendor Name and Store Name

export const getVendorStoreName = (
  GroupStoreUID: string,
  StoreType: string,
  IDType: string | number = 0
) => {
  const requestObject = {
    GroupStore_UID: GroupStoreUID,
    StoreType: StoreType,
    IDType: IDType,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.VendorStoreData, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Schedule Time

export const getScheduleTime = (Vendor_ID, store_ID) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    store_ID: store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.ScheduletimeData, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Equipment By Store
export const GetEquipmentByStore = (store_ID) => {
  const requestObject = {
    store_ID: store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.EquipmentByStore, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Milk Order Book
export const GetMilkOrderBook = (store_ID, Vendor_ID, DeliveryDate) => {
  const requestObject = {
    store_ID: store_ID,
    Vendor_ID: Vendor_ID,
    Department_Store_ID: 3375,
    Delivery_Date: DeliveryDate,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.MilkOrderBook, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Milk Order Book
export const GetMilkOrderBookDetail = (
  store_ID,
  Vendor_ID,
  DeliveryDate,
  Item_ID
) => {
  const requestObject = {
    store_ID: store_ID,
    Vendor_ID: Vendor_ID,
    Department_Store_ID: 3375,
    Delivery_Date: DeliveryDate,
    Item_ID: Item_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.MilkOrderBookDetail, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Milk Delivery Schedule
export const GetMilkDeliverySchedule = (Vendor_ID, DeliveryDate) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    Delivery_Day: DeliveryDate,
    Department_Store_ID: 3375,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.MilkDeliverySchedule, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Order Receipt
export const GetOrderReceipt = (Vendor_ID, Store_ID, Date2) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    Store_ID: Store_ID.toString(),
    //Vendor_ID: "70038000",
    //Store_ID: "102",
    Date2: Date2,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.OrderReceipt, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Order Detail Amp
export const GetOrderDetailAmp = (Store_ID) => {
  const requestObject = {
    Store_ID: Store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.OrderDetailAmp, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Order Invoice
export const GetOrderInvoice = (
  //Group_Store_UID,
  Store_ID,
  FromDate,
  ToDate
) => {
  const requestObject = {
    //GroupStore_UID: Group_Store_UID.toString(),
    Store_ID: Store_ID.toString(),
    FromDate: FromDate,
    ToDate: ToDate,
    //Vendor_ID: Vendor_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.OrderInvoice, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get AdGroupandGroupID
export const GetAdGroupandGroupID = (Store_ID) => {
  const requestObject = {
    Store_ID: Store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.AdGroupandGroupID, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Mark Down Discount Setup
export const GetMarkdownDiscountSetup = (store_ID, Operation) => {
  const requestObject = {
    nStore_ID: store_ID,
    sOperation: Operation,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.GetMarkdownDiscountSetup, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Mark Down Label Setup
export const GetMarkdownLabelSetup = (store_ID, Operation) => {
  const requestObject = {
    nStore_ID: store_ID,
    sOperation: Operation,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.GetMarkdownLabelSetup, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#region Report- Mark Down Label Setup Insert
export const InsertMarkdownLabelSetup = (obj) => {
  const requestObj = {
    UID: obj?.uid,
    title1: obj?.title1,
    title2: obj?.title2,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.InsertMarkdownLabelSetup, requestObj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Mark Down Label Setup Delete
export const DeleteMarkdownLabelSetup = (uid) => {
  const requestObj = {
    UID: uid,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.DeleteMarkdownLabelSetup, requestObj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//Get Mark Down Department Setup
export const GetMarkdownDepartmentSetup = (store_ID) => {
  const requestObject = {
    nStore_ID: store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.GetMarkdownDepartmentSetup, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//Get Mark Down Sub Department Setup
export const GetMarkdownSubDepartmentSetup = (store_ID) => {
  const requestObject = {
    nStore_ID: store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.GetMarkdownSubDepartmentSetup, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

//#region Report- Mark Down Discount Setup Insert
export const InsertMarkdownDiscountSetup = (obj) => {
  const requestObj = {
    UID: obj?.uid,
    Coupon_Percent: obj?.coupon_Percent.toString(),
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.InsertMarkdownDiscountSetup, requestObj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//#region Report- Mark Down Discount Setup Delete
export const DeleteMarkdownDiscountSetup = (uid) => {
  const requestObj = {
    UID: uid,
  };
  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.DeleteMarkdownDiscountSetup, requestObj)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          if (data) resolve(JSON.parse(data));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion

//Get Schedule Time

export const GetDeliveryScheduleWeekDay = (Vendor_ID, store_ID) => {
  const requestObject = {
    Vendor_ID: Vendor_ID,
    store_ID: store_ID,
  };

  return new Promise<any>((resolve, reject) => {
    AxiosService.Post(API_URL.DeliveryScheduleWeekDay, requestObject)
      .then((response) => {
        const { data = null } = response;
        if (data) {
          const jsonString = stringDecompression(data);
          if (jsonString) resolve(JSON.parse(jsonString));
        }
        resolve([]);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
//#endregion
