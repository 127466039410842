/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as ReportService from "services/reportServices";
import moment from "moment";
import { TrackItConstants } from "../../reports/TrackItReport/reportTrackItConstants";
import { capitalCase } from "change-case";
import { ReportMaster, TableHeaderConfig } from "models";

import { InputPicker } from "rsuite";

import MainControllerShrink from "./TrackITShrinkCharts/MainControllerShrink";
import MainControllerStoreUse from "./TrackITStoreUseCharts/MainControllerStoreUse";
import MainControllerReclaim from "./TrackITReclaimCharts/MainControllerReclaim";
import * as SettingsServices from "services/settingsServices";
import * as UMSService from "services/umsService";
const TrackITContainer = (props: any) => {
  const {
    userContext = {},
    fromDate,
    toDate,
    userStore,
    setUserStore,
    groupTypeID,
  } = props;
  // Tab
  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Shrink",
  });
  const tabsList: any[] = [
    { value: "1", label: "Shrink" },
    { value: "3", label: "Store Use" },
    { value: "4", label: "Reclaim" },
  ];

  /*   const tabsList: any[] = [
    { value: "1", label: "Shrink" },
    { value: "3", label: "Store Use" },
    { value: "6", label: "Store Transfer" },
    { value: "5", label: "Discounts" },
    { value: "2", label: "Recall" },
    { value: "4", label: "Reclaim" },

  ]; */

  // Calendar

  // Switch
  const [reportTypeSelected, setReportTypeSelected] = useState("Reason");

  //#region Reports Master Lists
  const [reasonReportData, setReasonReportData] = useState(new ReportMaster());

  //#endregion
  // Totals
  const [groupTableTotals, setGroupTableTotals] = useState({});
  /* ################ */
  //#region Filters

  ///////////////////////////////
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");

  const [storeName, setStoreName] = useState([]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const [awgOrGot, setAwgOrGot] = useState([
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { groupTypeId } = userContext;
    handleSpinner && handleSpinner(true);
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        // setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  // const SelectGroupType = (event) => {
  //   setGroupTypeID(event.target.value);
  //   setUserStore("")
  //   // if (storeID) {
  //   //   setUserStore(storeID[0]);
  //   // }
  // };
  const SelectAwgOrGot = (event) => {
    setAwgOrGotSelect(event.target.value);
  };
  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event.target.selectedIndex;
    setUserStore(storeID[VendorAndStore]);
  };

  //////////////////////////////

  useEffect(() => {
    handleDataChange();
  }, [reasonReportData, reportTypeSelected]);

  const getData = async () => {
    const { groupType, groupTypeId, store, roleName } = userContext;

    if (roleName !== "DM" && roleName === "Super Admin" && userStore === "") {
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeID,
            1,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            ""
          ),
        ]);
        const _reasonData = handleReasonData(ReasonData);
        setReasonReportData(_reasonData);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (
      roleName !== "DM" &&
      (roleName === "Super Admin" ||
        (store === 0 && groupTypeID !== 0 && userContext.groupTypeName === ""))
    ) {

      try {
        const [ReasonData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            userStore
          ),
        ]);
        const _reasonData = handleReasonData(ReasonData);
        setReasonReportData(_reasonData);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    } else if (userStore) {
      const StoreIDUser = store !== 0 ? store : "";
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeId,
            1,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            userStore
          ),
        ]);
        const _reasonData = handleReasonData(ReasonData);
        setReasonReportData(_reasonData);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    }  else if(roleName !=='DM') {

      const StoreIDUser = store !== 0 ? store : "";
      try {
        const [ReasonData] = await Promise.all([
          ReportService.getReportTrackIt(
            selectedTab.value,
            groupTypeId,
            groupType,
            moment(fromDate).format("YYYY-MM-DD"),
            moment(toDate).format("YYYY-MM-DD"),
            StoreIDUser
          ),
        ]);
        const _reasonData = handleReasonData(ReasonData);
        setReasonReportData(_reasonData);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
      }
    }
  };

  useEffect(() => {
    // Reset before loading data
    setGroupTableTotals({});

    // Loading data
    if (selectedTab.value !== "8") {
      getData();
    }
  }, [
    props.userContext,
    selectedTab,
    fromDate,
    toDate,
    userStore,
    groupTypeID,
  ]);

  const handleDataChange = () => {
    let headers = [];
    let data = [];
    let totals = [];
    if (selectedTab.value !== "8") {
      if (reportTypeSelected === "Reason") {
        headers = getReasonHeaders(reasonReportData.headers, true);
        data = reasonReportData.groupedList;
        totals = getTotalRowData(data, headers);
      }
    }
    setGroupTableTotals(totals);
    //setDisplayNoDatatlabel(isDataloaded && data.length === 0 ? true : false);
  };

  //#region Common Functions
  const getTotalRowData = (reportList, headers: Array<any>) => {
    const totalObj: any = {};

    headers.forEach((res) => {
      totalObj[res.DataKey] =
        res.TypeOfData === "Number" || res.TypeOfData === "Price"
          ? res.TypeOfData === "Price"
            ? Number(
                reportList?.reduce(
                  (acc, item) => acc + (item[res.DataKey] ?? 0),
                  0
                )
              ).toFixed(2)
            : Number(
                reportList
                  ?.reduce((acc, item) => acc + (item[res.DataKey] ?? 0), 0)
                  .toFixed(2)
              )
          : "";
    });
    return totalObj;
  };

  //#endregion
  //#region Reason Data
  const handleReasonData = (reasonResponse: any) => {
    const {
      groupedMetadata = [],
      reasonHeaders = [],
      records = [],
    } = reasonResponse;
    const _reasonMaster = { ...reasonReportData };
    _reasonMaster.headers = reasonHeaders;
    _reasonMaster.groupedList = getReasonGroupData(
      reasonHeaders,
      groupedMetadata
    );
    _reasonMaster.reportList = records.map((item) => {
      item["FormatedDate"] =
        item.date && moment(item.date).format("MM-DD-YYYY");
      item["Badge_Type"] =
        item.price_Adj !== 0 ? "Manual Price Keyed" : "POS Price entered";
      item.reasonPrice.forEach((value) => {
        item[value.reason_Description] = value.price ?? 0;
      });
      item["total"] = Number(
        item.reasonPrice.reduce((acc, item) => acc + item.price, 0).toFixed(2)
      );
      return item;
    });
    return _reasonMaster;
  };
  const getReasonHeaders = (reasonHeaders, isGroup = false) => {
    const headers = reasonHeaders.map((data) => {
      return {
        ...new TableHeaderConfig(),
        Header: capitalCase(data),
        DataKey: data,
        TypeOfData: "Price",
      };
    });
    const headerStart =
      selectedTab.value === "6"
        ? TrackItConstants[isGroup ? "GroupStoreTransfer" : "Store Transfer"]
        : TrackItConstants[isGroup ? "GroupHeaderStart" : "CommonHeaderStart"];
    return [...headerStart, ...headers, ...TrackItConstants["CommomHeaderEnd"]];
  };

  const getReasonGroupData = (reasonHeaders, _groupedMetaData) => {
    return _groupedMetaData.map((grpData) => {
      const _grpData = {
        store_ID: grpData.store_ID,
        store_ID1: grpData.store_ID1,
        dm: grpData.dm,
        Badge_Type: grpData.isAdjusted
          ? "Manual Price Keyed"
          : "POS Price entered",
        total: grpData.total,
        price_Adj: grpData.price_Adj,
      };
      reasonHeaders.forEach((hdr, hdrIdx) => {
        _grpData[hdr] = grpData.sums[hdrIdx];
      });
      return _grpData;
    });
  };

  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });
  return (
    <>
      <div className="header-main-dashboard">
        <InputPicker
          className="input-picker"
          onChange={(e) => setSelectedTab({ ...selectedTab, value: String(e) })}
          size="lg"
          placeholder="Shrink"
          data={data}
          cleanable={false}
        />
      </div>
      {/* if Selected tab == Shrink */}
      {selectedTab.value === "1" ? (
        <MainControllerShrink
          toDate={toDate}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={reasonReportData}
          userContext={userContext}
        />
      ) : (
        ""
      )}
      {selectedTab.value === "3" ? (
        <MainControllerStoreUse
          toDate={toDate}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={reasonReportData}
          userContext={userContext}
        />
      ) : (
        ""
      )}
      {selectedTab.value === "4" ? (
        <MainControllerReclaim
          toDate={toDate}
          groupTableTotals={groupTableTotals}
          fromDate={fromDate}
          reasonReportData={reasonReportData}
          userContext={userContext}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default TrackITContainer;
