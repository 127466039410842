import { useState, useRef } from "react";
import { Grid, Row, Col } from "rsuite";
import moment from "moment";
import MainChart from "./MainChart";
import DepToStoreDepartment from "./DeptoStoreDepartment";
import DepToStoreStore from "./DeptoStoreStore";

const ReclaimMainControllerShrink = (props: any) => {
  const {
    reasonReportData,
    toDate,
    fromDate,
    groupTableTotals,
    groupTypeID,
    userStore,
    userContext,
  } = props;

  const [showDepToStore, setDepToStore] = useState(false);
  const [showStoreToDep, setStoreToDep] = useState(false);
  const [showDepToStoreStore, setDepToStoreStore] = useState(false);
  const [showStoreToDepDep, setStoreToDepDep] = useState(false);

  // Group By Department to Store
  const [showData, setShowData] = useState("Seasonal");
  const [showDepartment, setShowDepartment] = useState("PRODUCE");
  //Group By Store to Department
  const [showStore, setShowStore] = useState("");
  const [firstLayer, setFirstLayer] = useState(true);
  const [secondLayer, setSecondLayer] = useState(false);
  const [thirdLayer, setThirdLayer] = useState(false);
  const labels: any = [];
  const chartValue: any = [];

  // if has value of GroupTableTotals chartLabel take key.
  const entries = Object.entries(groupTableTotals);
  for (const [key, value] of entries) {
    if (value) {
      if (
        key === "Damaged" ||
        key === "Disc By Office" ||
        key === "Short Date" ||
        key === "Manager Special" ||
        key === "Mispick" ||
        key === "Seasonal" ||
        key === "Disc By Vendor" ||
        key === "Short Date From W/H"
      ) {
        labels.push(key);
        chartValue.push(value);
      }
    }
  }
  const GroupByDepartment = (reportList) => {
    const totalsByDepartment = {};

    for (const item of reportList) {
      if (item.department !== "") {
        const department = item.department;
        if (!totalsByDepartment.hasOwnProperty(department)) {
          totalsByDepartment[department] = {
            Disc_By_Office: 0,
            Short_Date: 0,
            Manager_Special: 0,
            Seasonal: 0,
            Disc_By_Vendor: 0,
            "Short_Date_From_W/H": 0,
            Damaged: 0,
            Mispick: 0,
          };
        }
        totalsByDepartment[department]["Disc_By_Office"] +=
          item["Disc By Office"];
        totalsByDepartment[department]["Short_Date"] += item["Short Date"];
        totalsByDepartment[department]["Manager_Special"] +=
          item["Manager Special"];
        totalsByDepartment[department]["Seasonal"] += item["Seasonal"];
        totalsByDepartment[department]["Disc_By_Vendor"] +=
          item["Disc By Vendor"];
        totalsByDepartment[department]["Short_Date_From_W/H"] +=
          item["Short Date From W/H"];
        totalsByDepartment[department]["Damaged"] += item["Damaged"];
        totalsByDepartment[department]["Mispick"] += item["Mispick"];
      }
    }
    return totalsByDepartment;
  };
  const getAllDataByDepartmentToStore = (data) => {
    const departmentAllData = {};
    for (const item of data) {
      const department = item.department;
      const storeID = item.store_ID;
      if (!departmentAllData[department]) {
        departmentAllData[department] = {};
      }
      if (!departmentAllData[department][storeID]) {
        departmentAllData[department][storeID] = {
          Disc_By_Office: 0,
          Short_Date: 0,
          Manager_Special: 0,
          Seasonal: 0,
          Disc_By_Vendor: 0,
          "Short_Date_From_W/H": 0,
          Damaged: 0,
          Mispick: 0,
        };
      }
      departmentAllData[department][storeID]["Disc_By_Office"] +=
        item["Disc By Office"];
      departmentAllData[department][storeID]["Short_Date"] +=
        item["Short Date"];
      departmentAllData[department][storeID]["Manager_Special"] +=
        item["Manager Special"];
      departmentAllData[department][storeID]["Seasonal"] += item["Seasonal"];
      departmentAllData[department][storeID]["Disc_By_Vendor"] +=
        item["Disc By Vendor"];
      departmentAllData[department][storeID]["Short_Date_From_W/H"] +=
        item["Short Date From W/H"];
      departmentAllData[department][storeID]["Damaged"] += item["Damaged"];
      departmentAllData[department][storeID]["Mispick"] += item["Mispick"];
    }
    return departmentAllData;
  };

  // When click DepToStore page scroll down.

  let ref1 = useRef();
  let ref2 = useRef();

  function scrollTo(ref) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
            {firstLayer && (
              <MainChart
                setDepToStore={setDepToStore}
                setStoreToDep={setStoreToDep}
                MainData={setShowData}
                totalValue={groupTableTotals.total ? groupTableTotals.total : 0}
                value={chartValue}
                labels={labels}
                toDate={moment(toDate).format("MM/DD/YYYY")}
                fromDate={moment(fromDate).format("MM/DD/YYYY")}
                ref1={ref1}
                ref2={ref2}
                scrollTo={scrollTo}
                setFirstLayer={setFirstLayer}
                setSecondLayer={setSecondLayer}
                setThirdLayer={setThirdLayer}
              />
            )}
        </Row>
        <Row className="show-grid">
          {showDepToStore ? (
            <>
              {secondLayer && (
                <DepToStoreDepartment
                  setDepToStoreStore={setDepToStoreStore}
                  MainLabel={showData}
                  setShowDepartment={setShowDepartment}
                  value={GroupByDepartment(reasonReportData)}
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref1={ref1}
                  ref2={ref2}
                  scrollTo={scrollTo}
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                />
              )}
            </>
          ) : (
            ""
          )}
          {showDepToStoreStore &&
          getAllDataByDepartmentToStore(reasonReportData)[showDepartment] ? (
            <Col lg={24} xl={24} xxl={24}>
              {thirdLayer && (
                <DepToStoreStore
                  MainLabel={showData}
                  showDepartment={showDepartment}
                  value={
                    getAllDataByDepartmentToStore(reasonReportData)[
                      showDepartment
                    ]
                  }
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref2={ref2}
                  userContext={userContext}
                  groupTypeID={groupTypeID}
                  userStore={userStore}
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                />
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Grid>
    </>
  );
};

export default ReclaimMainControllerShrink;
