import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  SelectPicker,
  ButtonToolbar,
  Grid,
} from "rsuite";
import * as UMSService from "../../../../../services/umsService";
import { toast } from "react-toastify";

import { NumberType, SchemaModel, StringType } from "schema-typed";
import GenericForm from "../../components/GenericUserForm";
const CreateUserForGroup = (props: any) => {
  const { userContext } = props;
  const [RoleID, setRoleID] = useState(null);

  const formRef = useRef<any>();
  const model = SchemaModel({
    First_Name: StringType().isRequired("Please enter first name"),
    Last_Name: StringType().isRequired("Please enter last name"),
    Email: StringType()
      .isEmail("Email must be valid.")
      .isRequired("Email is required"),
    //Mobile: NumberType(),
    //Address1: StringType(),
    City: StringType().isRequired("Please enter City"),
    Role_ID: NumberType().isRequired("Please Choose Role"),
    Group_Type_ID: NumberType().isRequired("Please choose Group"),
    //state: StringType(),
    Zip: NumberType().isRequired("Please Enter Zip Code"),
    //Login_User_Name: StringType().isRequired("Please enter User Name."),
    //Password: StringType().isRequired("Please enter Password."),
    //confirmpassword: StringType().isRequired("Please enter Confirm Password."),
    //Status: BooleanType("Choose Active or Deactive"),
    group_type: StringType().isRequired("Choose Group Type"),
  });
  const initFormValue = {
    First_Name: "",
    Last_Name: "",
    Email: "",
    Mobile: "",
    City: "",
    Address1: "",
    Address2: "",
    state: "",
    Zip: "",
    Status: true,
    web_login_uid: "",
    User_ID: "",
    Role_ID: "",
    Group_Type_ID: "",
    group_type: "",
    Login_User_Name: "",
    Password: "",
    confirmpassword: "",
  };

  const selectGroupType = [
    {
      id: 1,
      name: "GOT",
      operation: "GroupType",
    },
    {
      id: 2,
      name: "AWG",
      operation: "GroupType",
    },
    {
      id: 3,
      name: "MDI",
      operation: "GroupType",
    },
    {
      id: 1,
      name: "GOT Group",
      operation: "GroupType",
    },
    {
      id: 2,
      name: "AWG Group",
      operation: "GroupType",
    },
    {
      id: 3,
      name: "MDI Group",
      operation: "GroupType",
    },
    {
      id: 1,
      name: "GOT Store",
      operation: "StoreType",
    },
    {
      id: 2,
      name: "AWG Store",
      operation: "StoreType",
    },
    {
      id: 3,
      name: "MDI Store",
      operation: "StoreType",
    },
  ].map((item) => ({
    label: item.name,
    value: item.operation
      ? item.id + " " + item.operation + " " + item.name
      : item.id,
  }));

  const selectStateList = [
    { name: "Alabama", abbreviation: "AL" },
    { name: "Alaska", abbreviation: "AK" },
    { name: "Arizona", abbreviation: "AZ" },
    { name: "Arkansas", abbreviation: "AR" },
    { name: "California", abbreviation: "CA" },
    { name: "Colorado", abbreviation: "CO" },
    { name: "Connecticut", abbreviation: "CT" },
    { name: "Delaware", abbreviation: "DE" },
    { name: "Florida", abbreviation: "FL" },
    { name: "Georgia", abbreviation: "GA" },
    { name: "Hawaii", abbreviation: "HI" },
    { name: "Idaho", abbreviation: "ID" },
    { name: "Illinois", abbreviation: "IL" },
    { name: "Indiana", abbreviation: "IN" },
    { name: "Iowa", abbreviation: "IA" },
    { name: "Kansas", abbreviation: "KS" },
    { name: "Kentucky", abbreviation: "KY" },
    { name: "Louisiana", abbreviation: "LA" },
    { name: "Maine", abbreviation: "ME" },
    { name: "Maryland", abbreviation: "MD" },
    { name: "Massachusetts", abbreviation: "MA" },
    { name: "Michigan", abbreviation: "MI" },
    { name: "Minnesota", abbreviation: "MN" },
    { name: "Mississippi", abbreviation: "MS" },
    { name: "Missouri", abbreviation: "MO" },
    { name: "Montana", abbreviation: "MT" },
    { name: "Nebraska", abbreviation: "NE" },
    { name: "Nevada", abbreviation: "NV" },
    { name: "New Hampshire", abbreviation: "NH" },
    { name: "New Jersey", abbreviation: "NJ" },
    { name: "New Mexico", abbreviation: "NM" },
    { name: "New York", abbreviation: "NY" },
    { name: "North Carolina", abbreviation: "NC" },
    { name: "North Dakota", abbreviation: "ND" },
    { name: "Ohio", abbreviation: "OH" },
    { name: "Oklahoma", abbreviation: "OK" },
    { name: "Oregon", abbreviation: "OR" },
    { name: "Pennsylvania", abbreviation: "PA" },
    { name: "Rhode Island", abbreviation: "RI" },
    { name: "South Carolina", abbreviation: "SC" },
    { name: "South Dakota", abbreviation: "SD" },
    { name: "Tennessee", abbreviation: "TN" },
    { name: "Texas", abbreviation: "TX" },
    { name: "Utah", abbreviation: "UT" },
    { name: "Vermont", abbreviation: "VT" },
    { name: "Virginia", abbreviation: "VA" },
    { name: "Washington", abbreviation: "WA" },
    { name: "West Virginia", abbreviation: "WV" },
    { name: "Wisconsin", abbreviation: "WI" },
    { name: "Wyoming", abbreviation: "WY" },
  ].map((item) => ({
    label: item.name,
    value: item.abbreviation,
  }));
  const [formValue, setFormValue] = useState(initFormValue);
  const [isShowGroupOrStore, setIsShowGroupOrStore] = useState(false);
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_UID: "", groupStore_desc: "" },
  ]);
  const [isAWG, setISAWG] = useState(false);
  const [storeID, setStoreID] = useState("");
  const [rolesList, setRolesList] = useState([]);
  useEffect(() => {
    GetRolesList();
  }, []);

  const GetRolesList = async () => {
    UMSService.GetRolesList()
      .then((result) => {
        result = result.filter(
          (obj) => obj.role_Name === "Admin" || obj.role_Name === "User"
        );
        setRolesList(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const SelectGroupType = (event) => {
    //console.log(event.toString());
    if (event.toString().includes(" ")) {
      var IDType = event.toString().split(" ")[0];
      var operation = event.toString().split(" ")[1];
      var groupDesc =
        event.toString().split(" ")[2] + " " + event.toString().split(" ")[3];
      setStoreID(groupDesc);
      GetGroupAndStoreByTypes(IDType, operation);
    } else {
      //console.log(event.toString());
      setIsShowGroupOrStore(false);

      if (event.toString() === "1") {
        setStoreID("GOT");
      }
      if (event.toString() === "2") {
        setStoreID("AWG");
      }
      if (event.toString() === "3") {
        setStoreID("MDI");
      }
    }

    setIsShowGroupOrStore(true);
  };
  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form error");
      return;
    }
    if (formValue.Role_ID.toString() === "6") {
      const obj = {
        ...formValue,
        operation: "CreateNewUser",
        state: formValue.state ? formValue.state : "AL",
        //group_type: formValue.group_type.split("-"),
        group_type: formValue.group_type.toString().includes(" ")
          ? Number(formValue.group_type.toString().split(" ")[0])
          : formValue.group_type,
        group_id: null,
        group_type_desc: storeID !== "" ? storeID : "",
        Group_Type_ID: null,
        Status: true,
        Login_User_Name: "",
        Password: "",
        confirmpassword: "",
      };
      console.log("ROLEID = 6 AWG", obj);
      //InsertNewUser(obj);
    } else {
      const obj = {
        ...formValue,
        operation: "CreateNewUser",
        state: formValue.state ? formValue.state : "AL",
        //group_type: formValue.group_type.split(" ")[0],

        group_type: formValue.group_type.toString().includes(" ")
          ? Number(formValue.group_type.toString().split(" ")[0])
          : formValue.group_type,
        Mobile: formValue.Mobile ? formValue.Mobile : "9999999999",
        City: formValue.City ? formValue.City : "City",
        Address1: formValue.Address1 ? formValue.Address1 : "Address1",
        Address2: formValue.Address2 ? formValue.Address2 : "Address2",
        Zip: formValue.Zip ? formValue.Zip : "12345",
        //group_id: formValue.Group_Type_ID,
        Status: true,
        Login_User_Name: "",
        Password: "",
        confirmpassword: "",
        store_id: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? formValue.Group_Type_ID
              ? formValue.Group_Type_ID
              : 0
            : 0
          : 0,
        group_id: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? 0
            : formValue.Group_Type_ID
          : 0,
        Group_Type_ID: formValue.group_type.toString().includes(" ")
          ? formValue.group_type.toString().split(" ")[1] === "StoreType"
            ? null
            : null
          : null,
        group_type_desc: storeID !== " " ? storeID : "",
      };

      //console.log(obj);

      InsertNewUser(obj);
    }
  };
  if (userContext) {
    var { roleName } = userContext;
  }

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };
  const InsertNewUser = async (userData) => {
    UMSService.InsertNewUser(userData)
      .then((result) => {
        result && toast.success("User Created Successfully");
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const formFields = [
    {
      controlId: "First_Name",
      label: "First Name",
      name: "First_Name",
      required: true,
    },
    {
      controlId: "Last_Name",
      label: "Last Name",
      name: "Last_Name",
      required: true,
    },
    {
      controlId: "Email",
      label: "Email",
      name: "Email",
      type: "email",
      required: true,
    },
    { controlId: "Mobile", label: "Mobile", name: "Mobile" },
    {
      controlId: "Address1",
      label: "Address line 1",
      name: "Address1",
      required: true,
    },
    { controlId: "Address2", label: "Address line 2", name: "Address2" },
    { controlId: "City", label: "City", name: "City", required: true },
    {
      controlId: "state",
      label: "State",
      name: "state",
      type: "select",
      data: selectStateList,
      extraProps: { onChange: (value) => /* handle state change */ { } },
    },
    {
      controlId: "Zip",
      label: "Zip",
      name: "Zip",
      type: "number",
      required: true,
    },
    {
      controlId: "Role_ID",
      label: "Select Role",
      name: "Role_ID",
      type: "select",
      data: rolesList.map((item) => ({
        label: item.role_Name,
        value: item.uid,
      })),
      extraProps: { onChange: (value) => /* handle role change */ { } },
      required: true,
    },

    {
      controlId: "group_type",
      label: "Select Group Type",
      name: "group_type",
      type: "select",
      data: selectGroupType,
      extraProps: {
        onChange: (e) => {
          SelectGroupType(e);
        },
      },
      required: true,
    },

  ];
  const buttons = [
    {
      label: "Create User",
      props: {
        className: "createButton",
        appearance: "primary",

        type: "submit",
        onClick: handleSubmit,
      },
    },
  ];

  const additionalProps = {
    groupAndGroupID,
    isAWG,
    RoleID,
    isShowGroupOrStore,
  };

  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <GenericForm
          formTitle={"Create User"}
          formFields={formFields}
          buttons={buttons}
          formRef={formRef}
          model={model}
          setFormValue={setFormValue}
          handleSubmit={handleSubmit}
          additionalProps={additionalProps}
        />
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};

export default CreateUserForGroup;
