export const EquipmentByStoreConstants = {
    "EquipmentByStore": [
        { Header: "Serial#", DataKey: "serial_No", Width: 100, isFixed: true, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Unit", DataKey: "description", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "In Service", DataKey: "in_Service", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Last known Status", DataKey: "last_Known_Status", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Grind", DataKey: "grind", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "IP Address", DataKey: "iP_Address", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Subnet Mask", DataKey: "subnet_Mask", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Gateway", DataKey: "gateway", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Add Date", DataKey: "add_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Report an issue", DataKey: "Report", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "ic:outline-report", isSubHeader: false, colSpan: 1},
       
    ]
}