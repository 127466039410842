export const OrderDetailAMPConstansts = {
    "OrderDetailAMPConstansts":  [
        { Header: "UID", DataKey: "uid", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "File ID", DataKey: "file_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "UPC", DataKey: "upc", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Item ID", DataKey: "item_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Vendor ID", DataKey: "vendor_ID", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},

        { Header: "Delivery Date", DataKey: "delivery_Date", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Department Store ID", DataKey: "department_Store_ID", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, isSubHeader: false, colSpan: 1},
        { Header: "Quantity", DataKey: "quantity", Width: 50, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Update Date", DataKey: "update_Date", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Date", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Cost", DataKey: "cost", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Price", isSearch: false, isHeader: true, isSubHeader: false, colSpan: 1},
        { Header: "AMP", DataKey: "amp", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Price", isSearch: false, isHeader: true, isSubHeader: false, colSpan: 1},
        { Header: "BB", DataKey: "bb", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Price", isSearch: false, isHeader: true,  isSubHeader: false, colSpan: 1},
        { Header: "Savings", DataKey: "savings", Width: 50, isFixed: false, isSortable: false, Align: "center", TypeOfData: "Price", isSearch: false, isHeader: true,  isSubHeader: false, colSpan: 1},

    ]


}