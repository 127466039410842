export const ReportConstants = {
    "Donation": [
        { Header: "Store ID", DataKey: "store_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Company Store Id", DataKey: "store_ID1", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Donation Date", DataKey: "formatedDonationDate", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "UPC", DataKey: "upc", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Description", DataKey: "description", Width: 250, isFixed: false, isSortable: true, Align: "left", TypeOfData: "text-center-heading", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        // { Header: "Size", DataKey: "size", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Quantity", DataKey: "quantity", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Retail Price", DataKey: "retail_Price", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Total", DataKey: "total", Width: 150, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Price", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Markdown", DataKey: "", Width: 120, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Donation Location", DataKey: "food_Bank", Width: 150, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 }
    ]
}