export const MilkOrderBookConstants = {
    "MilkOrderBook": [
        { Header: "Item", DataKey: "item_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Text", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Description", DataKey: "description", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Text", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "Size", DataKey: "size", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvnt WK 1", DataKey: "mvmnt_Wk1", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvnt WK 2", DataKey: "mvmnt_Wk2", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvnt WK 3", DataKey: "mvmnt_Wk3", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvnt WK 4", DataKey: "mvmnt_Wk4", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Awg 4WK Mvnt", DataKey: "avg_4wk_mvmnt", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Last Inv Diff", DataKey: "oh_Inv_Diff", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Round to PK", DataKey: "roundToPack", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "LOQ", DataKey: "loq", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pack", DataKey: "pack", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Shelf Life", DataKey: "shelf_Life", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Days Cnt", DataKey: "inv_LastDayCnt", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "ORDERBK", DataKey: "order_Book", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Commodity", DataKey: "sub_1_3_Description", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Sub Commodity", DataKey: "sub_4_6_Description", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       
    ],
    "MilkOrderBookDetail":[
        { Header: "Item ID", DataKey: "item_ID", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Delivery", DataKey: "date", Width: 100, isFixed: false, isSortable: true, Align: "left", TypeOfData: "Date", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},     
        { Header: "Inventory", DataKey: "inventory_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Rec Qty", DataKey: "rec_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Pack", DataKey: "pack", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Sell", DataKey: "est_Sell_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est O/H", DataKey: "oH_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Price", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Invoice", DataKey: "invoice_Qty", Width: 100, isFixed: false, isSortable: true, Align: "right", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvmnt Load", DataKey: "mvmnt_Loaded", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Mvmnt Qty", DataKey: "mvmnt_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: true , isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
        { Header: "Est Mvmnt Qty", DataKey: "est_Mvmnt_Qty", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1 },
        { Header: "CAO Log", DataKey: "caO_Qty_History", Width: 100, isFixed: false, isSortable: true, Align: "center", TypeOfData: "Number", isSearch: false, isHeader: true, Icon : "", isSubHeader: false, colSpan: 1},
       
    ]
}