import { useState, useRef } from "react";
import { Grid, Row, Col } from "rsuite";
import moment from "moment";
import MainChart from "./MainChart";
import DepToStoreDepartment from "./DepToStoreDepartment";
import DepToStoreStore from "./DepToStoreStore";

const MainControllerReclaim = (props: any) => {
  const { reasonReportData, toDate, fromDate, groupTableTotals, userContext } =
    props;

  const [showData, setShowData] = useState("AWG");
  const [showDepartment, setShowDepartment] = useState("GROCERY");
  const [showDepToStore, setDepToStore] = useState(false);
  const [showDepToStoreStore, setDepToStoreStore] = useState(false);
  const [firstLayer, setFirstLayer] = useState(true);
  const [secondLayer, setSecondLayer] = useState(false);
  const [thirdLayer, setThirdLayer] = useState(false);
  const labels: any = [];
  const chartValue: any = [];
  // if has value of GroupTableTotals chartLabel take key.
  const entries = Object.entries(groupTableTotals);
  for (const [key, value] of entries) {
    if (value) {
      if (
        key === "AWG" ||
        key === "Out_of_Date" ||
        key === "Damage" ||
        key === "Theft" ||
        key === "Recovery" ||
        key === "Spoilage"
      ) {
        labels.push(key);
        chartValue.push(value);
      }
    }
  }
  //Department
  const GroupByDepartment = (reportList) => {
    const totalsByDepartment = {};

    for (const item of reportList) {
      if (item.department !== "") {
        const department = item.department;
        if (!totalsByDepartment.hasOwnProperty(department)) {
          totalsByDepartment[department] = {
            Out_of_Date: 0,
            AWG: 0,
            Damage: 0,
            Theft: 0,
            Spoilage: 0,
            Recovery: 0,
          };
        }
        totalsByDepartment[department].Damage += item.Damage;
        totalsByDepartment[department].Out_of_Date += item.Out_of_Date;
        totalsByDepartment[department].AWG += item.AWG;
        totalsByDepartment[department].Theft += item.Theft;
        totalsByDepartment[department].Spoilage += item.Spoilage;
        totalsByDepartment[department].Recovery += item.Recovery;
      }
    }
    return totalsByDepartment;
  };
  //Department to Store (deparment => store)
  const getAllDataByDepartmentToStore = (data) => {
    const departmentAllData = {};
    for (const item of data) {
      const department = item.department;
      const storeID = item.store_ID;
      if (!departmentAllData[department]) {
        departmentAllData[department] = {};
      }
      if (!departmentAllData[department][storeID]) {
        departmentAllData[department][storeID] = {
          Damage: 0,
          Spoilage: 0,
          Out_of_Date: 0,
          Recovery: 0,
          Theft: 0,
          AWG: 0,
        };
      }
      departmentAllData[department][storeID].Damage += item.Damage;
      departmentAllData[department][storeID].Out_of_Date += item.Out_of_Date;
      departmentAllData[department][storeID].Theft += item.Theft;
      departmentAllData[department][storeID].Recovery += item.Recovery;
      departmentAllData[department][storeID].AWG += item.AWG;
      departmentAllData[department][storeID].Spoilage += item.Spoilage;
    }
    return departmentAllData;
  };

  //#Region Department and Department ID
  const DepartmentData = (reportList) => {
    const DepartmentData = {};

    for (const item of reportList) {
      if (item.department !== "") {
        const department = item.department;
        if (!DepartmentData.hasOwnProperty(department)) {
          DepartmentData[department] = {
            poS_Department_ID: 0,
          };
        }
        DepartmentData[department].poS_Department_ID = item.poS_Department_ID;
      }
    }
    return DepartmentData;
  };

  //#endregion

  let ref3 = useRef();
  let ref4 = useRef();

  function scrollTo(ref) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <>
            {firstLayer && (
              <MainChart
                setFirstLayer={setFirstLayer}
                setSecondLayer={setSecondLayer}
                setThirdLayer={setThirdLayer}
                setDepToStore={setDepToStore}
                MainData={setShowData}
                totalValue={groupTableTotals.total ? groupTableTotals.total : 0}
                value={chartValue}
                labels={labels}
                toDate={moment(toDate).format("MM/DD/YYYY")}
                fromDate={moment(fromDate).format("MM/DD/YYYY")}
                ref3={ref3}
                ref4={ref4}
                scrollTo={scrollTo}
              />
            )}
          </>
        </Row>

        <Row className="show-grid">
          {showDepToStore ? (
            <>
              {secondLayer && (
                <DepToStoreDepartment
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                  setDepToStoreStore={setDepToStoreStore}
                  MainLabel={showData}
                  setShowDepartment={setShowDepartment}
                  value={GroupByDepartment(reasonReportData.reportList)}
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref4={ref4}
                  ref3={ref3}
                  scrollTo={scrollTo}
                />
              )}
            </>
          ) : (
            ""
          )}
          {showDepToStoreStore ? (
            <>
              {thirdLayer && (
                <DepToStoreStore
                  setFirstLayer={setFirstLayer}
                  setSecondLayer={setSecondLayer}
                  setThirdLayer={setThirdLayer}
                  MainLabel={showData}
                  showDepartment={showDepartment}
                  value={
                    getAllDataByDepartmentToStore(reasonReportData.reportList)[
                      showDepartment
                    ]
                  }
                  departmentData={DepartmentData(reasonReportData.reportList)}
                  toDate={moment(toDate).format("MM/DD/YYYY")}
                  fromDate={moment(fromDate).format("MM/DD/YYYY")}
                  ref4={ref4}
                  userContext={userContext}
                />
              )}
            </>
          ) : (
            ""
          )}
        </Row>
      </Grid>
    </>
  );
};

export default MainControllerReclaim;
