/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Drawer, Dropdown, SelectPicker } from "rsuite";
import PageIcon from "@rsuite/icons/Page";
import DetailIcon from "@rsuite/icons/Detail";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../services/reportServices";
import { toast } from "react-toastify";
import Constants from "../.././utils/constants";
import TableComponent from "../common/tableComponent";
import { Icon } from "@iconify/react";

const ReportOrdersComponent = (props: any) => {
  const [vendorSelected, setVendorSelected] = useState({
    label: null,
    value: null,
  });
  const [storeSelected, setStoreSelected] = useState({
    label: null,
    value: null,
  });
  const [departmentSelected, setDepartmentSelected] = useState({
    label: null,
    value: null,
  });

  const [reportData, setReportData] = useState({
    vendor: null,
    store: null,
    department: null,
  });
  const [showFilter, setShowFilter] = useState(false);

  // Listing
  const [vendorList, setVendorList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [reportList, setReportList] = useState(null);

  const tableDetails = [
    {
      Header: "Id",
      DataKey: "Id",
      Width: 150,
      isFixed: false,
      isSortable: false,
      Align: "center",
    },
    {
      Header: "Item",
      DataKey: "Item",
      Width: 250,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
    {
      Header: "Description",
      DataKey: "Description",
      Width: 500,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
    {
      Header: "Size",
      DataKey: "Size",
      Width: 150,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
    {
      Header: "Pack",
      DataKey: "Pack",
      Width: 150,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
    {
      Header: "Unit Cost",
      DataKey: "UnitCost",
      Width: 150,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
    {
      Header: "Order",
      DataKey: "Order",
      Width: 150,
      isFixed: false,
      isSortable: true,
      Align: "left",
    },
  ];

  useEffect(() => {
    getReportData();
    GetOrdersList();
  }, []);

  const GetOrdersList = async () => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    try {
      console.log("Get Vendor & Store List");
      let vendorData = await ReportService.getVendorList();
      console.log("vendorData", vendorData);
      let reportData = await ReportService.getStoreList();
      const _vendorList = (vendorData.data ?? []).map((item) => ({
        label: item.short,
        value: item.vendorID,
      }));
      const _storeList = (reportData.data ?? []).map((item) => ({
        label: item.storeName,
        value: item.storeID,
      }));
      setVendorList(_vendorList);
      setStoreList(_storeList);
    } catch (err) {
      console.error("Error: Get Vendor & Store - ", err);
    } finally {
      handleSpinner && handleSpinner(false);
    }
  };

  const getDepartments = async (vendorId, storeId) => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    ReportService.getDepartmentsList(vendorId, storeId)
      .then((result) => {
        const { data = [] } = result;
        console.log(data, "Department List");
        const _departmentList = data.map((item) => ({
          label: item.departmentStoreName.trim(),
          value: item.departmentStoreName.trim(),
        }));
        setDepartmentList(_departmentList);
      })
      .catch((err) => {
        console.error("Error: Get Department - ", err);
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const getReportData = async (
    vendorId?: any,
    storeId?: any,
    department?: any
  ) => {
    const { handleSpinner } = props;
    handleSpinner && handleSpinner(true);
    ReportService.getReportOrderBooks(vendorId, storeId, department)
      .then((result) => {
        const { data = [] } = result;
        console.log(data, "Report List");
        const _reportsList = modifyReportData(data);
        setReportList(_reportsList);
        toast.success("Report Loaded Successfully");
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  };

  const modifyReportData = (data) => {
    let modifiedData = data.map((_repData, index) => {
      return {
        Id: index + 1,
        Item: _repData.itemID,
        Description: _repData.description,
        Size: _repData.size,
        Pack: _repData.pack,
        UnitCost: "-",
        Order: "-",
      };
    });
    return modifiedData;
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer
  const RenderDrawer = () => {
    return (
      <>
        <Drawer open={showFilter} onClose={() => setShowFilter(false)}>
          <Drawer.Header>
            <Drawer.Title>Filters</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div className="content">
              <div className="row m-0">
                <div className="col-12 p-2">
                  <SelectPicker
                    placement="auto"
                    className="w-100"
                    data={storeList}
                    style={{ width: 224 }}
                    defaultValue={""}
                    value={storeSelected.value}
                    placeholder={`Select Store`}
                    renderMenuItem={(label, item) => {
                      return (
                        <div className="d-flex flex-column">
                          <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                      );
                    }}
                    // ? Difference btwn onSelect and onChange?
                    onChange={(value, event) => {
                      // console.log(event);
                      setStoreSelected({
                        label: event.target["innerText"],
                        value: value,
                      });
                      if (value && vendorSelected.value) {
                        getDepartments(vendorSelected.value, value);
                      }
                    }}
                  />
                </div>
                <div className="col-12 p-2">
                  <SelectPicker
                    placement="auto"
                    className="w-100"
                    data={vendorList}
                    style={{ width: 224 }}
                    defaultValue={""}
                    value={vendorSelected.value}
                    placeholder={`Select Vendor`}
                    renderMenuItem={(label, item) => {
                      return (
                        <div className="d-flex flex-column">
                          <i className="rs-icon rs-icon-user" /> {label}
                        </div>
                      );
                    }}
                    onChange={(value, event) => {
                      setVendorSelected({
                        label: event.target["innerText"],
                        value: value,
                      });
                      if (value && storeSelected.value) {
                        getDepartments(value, storeSelected.value);
                      }
                    }}
                  />
                </div>
                <div className="col-12 p-2">
                  {departmentList.length > 0 && (
                    <SelectPicker
                      placement="auto"
                      className="w-100"
                      data={departmentList}
                      style={{ width: 224 }}
                      defaultValue={""}
                      value={departmentSelected.value}
                      placeholder={`Select Department`}
                      renderMenuItem={(label, item) => {
                        return (
                          <div className="d-flex flex-column">
                            <i className="rs-icon rs-icon-user" /> {label}
                          </div>
                        );
                      }}
                      onChange={(value, event) => {
                        setDepartmentSelected({
                          label: event.target["innerText"],
                          value: value,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="footer text-end">
              <Button
                appearance="ghost"
                className="mx-2"
                onClick={() => {
                  setReportData({
                    vendor: null,
                    store: null,
                    department: null,
                  });
                  setStoreSelected({ label: null, value: null });
                  setVendorSelected({ label: null, value: null });
                  setDepartmentSelected({ label: null, value: null });
                  setDepartmentList([]);
                  getReportData();
                  setShowFilter(false);
                }}
              >
                Clear Filter
              </Button>
              <Button
                appearance="primary"
                className="mx-2"
                disabled={
                  !(
                    vendorSelected.value &&
                    storeSelected.value &&
                    departmentSelected.value
                  )
                }
                onClick={() => {
                  setReportData({
                    vendor: vendorSelected,
                    store: storeSelected,
                    department: departmentSelected,
                  });
                  getReportData(
                    vendorSelected.value,
                    storeSelected.value,
                    departmentSelected.value
                  );
                  setShowFilter(false);
                }}
              >
                Apply Filter
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  };
  //#endregion

  return (
    <>
      <div className="p-3">
        <div className="m-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <span className="mx-2 filterPills">{`Vendor : ${
                (reportData["vendor"] && reportData["vendor"]["label"]) ?? "All"
              }`}</span>
              <span className="mx-2 filterPills">{`Department : ${
                (reportData["department"] &&
                  reportData["department"]["label"]) ??
                "All"
              }`}</span>
            </div>
            <div>
              <div className="d-flex">
                <div
                  className="mx-2 filter"
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <Icon icon="material-symbols:filter-alt-outline-sharp" />
                </div>
                <Button className="mx-2" appearance="ghost">
                  Schedule
                </Button>
                <Dropdown
                  className="mx-2"
                  title="Export"
                  appearance="primary"
                  placement="bottomEnd"
                >
                  <Dropdown.Item
                    icon={<FileDownloadIcon />}
                    onClick={() => {
                      exportToExcel(reportList, "Order Report");
                    }}
                  >
                    Export to Excel
                  </Dropdown.Item>
                  <Dropdown.Item icon={<DetailIcon />}>
                    Export to PDF
                  </Dropdown.Item>
                  <Dropdown.Item divider />
                  <Dropdown.Item icon={<PageIcon />}>
                    Share to Email
                  </Dropdown.Item>
                  <Dropdown.Item icon={<PageIcon />}>
                    Send Link to Email
                  </Dropdown.Item>
                </Dropdown>
              </div>
            </div>
          </div>
          {RenderDrawer()}
          <TableComponent
            isFromModal={false}
            tableDetails={tableDetails}
            reportList={reportList}
            handleShowFilter={setShowFilter}
            isTotalRowRequired={false}
          />
        </div>
      </div>
    </>
  );
};

export default ReportOrdersComponent;
