const Constants = {
  baseApiUrl: process.env.REACT_APP_BASE_API_URL,

  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
      authority: process.env.REACT_APP_AZURE_AUTHORITY, // Choose SUSI as your default authority.
      knownAuthorities: [process.env.REACT_APP_AZURE_INSTANCE], // Mark your B2C tenant's domain as trusted.
      redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  /* 
  msalConfig: {
    auth: {
      clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
      authority: "https://<B2C_Tenant_Name>.b2clogin.com/<B2C_Tenant_Name>.onmicrosoft.com/<Policy_Name>",
      redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  } */

  //#region RegExs
  numberRegEx: /^[0-9]\d*(\.\d{0,4})?$/,
  decimalRegEx: /^\d+\.\d{0,4}$/,
  pincodeRegEx: /^[1-9][0-9]{5}$/,
  mobileNumberRegEx: /^[1-9]{1}[0-9]{9}$/,
  alphaNumericRegEx: /^[a-zA-Z0-9]{1,}[a-zA-Z0-9 ]*$/,
  alphabetRegEx: /^[a-zA-Z][a-zA-Z ]+[a-zA-Z]*$/,
  numericRegEx: /^[0-9]*$/,
  // eslint-disable-next-line
  emailRegEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  //#endregion

  //#region DateFormat
  dateLocale: "en-IN",
  dateformatOptionsDateTime: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  },
  dateformatOptionsDate: {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  dateformatOptionsMonthYear: {
    year: "numeric",
    month: "long",
  },
  dateformatOptionsTime: {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  },
  monthsLong: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  monthsShort: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Ma",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  //#endregion

  //#region NotificationTypes
  notificationType: {
    Success: 1,
    Info: 2,
    Warning: 3,
    Error: 4,
  },
  //#endregion

  //#region ConvertToExcel
  fileType:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  fileExtension: ".xlsx",
  //#endregion

  //#region ReportPageLimitOptions
  pageLimit: [10, 50, 100, 500, 9999],
  //#endregion
};

export default Constants;
