import React, { FC } from "react";

interface CardProps {
  children: React.ReactNode;
}

const Card: FC<CardProps> = ({ children }) => {
  return <div className="programming-stats-dashboard">{children}</div>;
};

export default Card;
