import TableComponent from "../../../../components/common/tableComponent";
import { useEffect, useState } from "react";
import * as OrdersServices from "../../../../services/orderService";
import { FilteredDepartmentConstants } from "./FilteredDepartmentConstants";
import { Button, Dropdown, Input, Modal, InputGroup } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import SearchIcon from "@rsuite/icons/Search";
import Constants from "../../../.././utils/constants";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Icon } from "@iconify/react";
import * as SettingsServices from "../../../../services/settingsServices";
import * as AuthService from "../../../../services/authService";
import { ConfirmationListConstants } from "../ConfirmationListAll/ConfirmationListConstants";
import TableComponent1 from "../ConfirmationListAll/TableComponentConf";
import moment from "moment";
import * as UMSService from "../../../../services/umsService";
import PageHeaderComponent from "../../../common/pageHeaderComponent";
import ConfirmationListDetail from "../ConfirmationListAll/ConfirmationListDetail";
import { addDays } from "date-fns";
import { SelectPicker } from "rsuite";

const DepartmentMain = (props: any) => {
  const { userContext, handleSpinner, setIsGrindOrMain } = props;

  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), 0));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), 0));
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [groupsForAllDepartment, setGroupsForAllDepartment] = useState([]);
  const [filteredDepartmentName, setFilteredDepartmentName] = useState([]);
  const [allConfDataFiltered, setAllConfDataFiltered] = useState([]);
  const [confDetailData, setConfDetailData] = useState([]);

  const [totalAllData, setTotalAllData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredConfID, setFilteredConfID] = useState({});

  const [detailConf, setDetailConf] = useState("");
  const [confDetailFilteredData, setConfDetailFilteredData] = useState([]);
  const [allConfDataFilteredByUser, setAllConfDataFilteredByUser] = useState(
    []
  );
  const [allConfData, setAllConfData] = useState([]);
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };
  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const [userStore, setUserStore] = useState("");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  useEffect(() => {
    getConfData(
      userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
  }, [userStore, userContext, fromDate, toDate]);

  useEffect(() => {
    FilterByDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentData]);

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const getConfData = async (store_ID, fromDate, toDate) => {
    store_ID = userContext.store ? userContext.store : store_ID;

    OrdersServices.GetOrdersConfirmationList(store_ID, fromDate, toDate)
      .then((result) => {
        result.confirmation_M.map((item) => {
          item.department = item.department.trim();
          item.vendor = item.vendor.trim();

          var momentDate = moment(item.delivery_Date, "M/D/YYYY h:mm:ss A");

          var dayName = momentDate.format("dddd");
          if (item.delivery_Date.includes("12:00:00 AM")) {
            item.delivery_Date = item.delivery_Date.replace("12:00:00 AM", "");
          }
          item.day = dayName;
          return item;
        });
        setDepartmentData(result.confirmation_M);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };

  function calculateTotals(data) {
    let totalitems = 0;
    let totalpieces = 0;

    for (let entry of data) {
      totalitems += Number(entry.items) || 0;
      totalpieces += Number(entry.quantity) || 0;
    }

    return {
      items: totalitems,
      quantity: totalpieces,
    };
  }

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const handleRowClick = (rowIndex) => {
    var newSelectedRows = [...selectedData];

    if (
      newSelectedRows.some(
        (item) =>
          item.got_Confirmation_ID === allConfData[rowIndex].got_Confirmation_ID
      )
    ) {
      newSelectedRows = newSelectedRows.filter(
        (item) =>
          item.got_Confirmation_ID !== allConfData[rowIndex].got_Confirmation_ID
      );
    } else {
      newSelectedRows = newSelectedRows.concat(allConfData[rowIndex]);
    }

    //setSelectedDataTotal(calculateTotals(newSelectedRows));
    //State'i güncelleyin

    setFilteredConfID(
      newSelectedRows.map((item) => {
        return item.got_Confirmation_ID;
      })
    );
    if (newSelectedRows.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    setSelectedData(newSelectedRows);
  };

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { store, groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          if (groupType === 2) {
            result.records.map((item) => {
              return (item.store_Name =
                item.store_ID + " / " + item.store_Name);
            });
          }
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  const FilterByDepartment = () => {
    var groupsForDepartment = {};

    departmentData.forEach(function (data) {
      var department = data.department;
      if (!groupsForDepartment[department]) {
        groupsForDepartment[department] = [];
      }

      groupsForDepartment[department].push(data);
    });

    var GroupsAllDepartment = [];
    var GroupsAllDepartmentName = [];

    for (var key in groupsForDepartment) {
      if (groupsForDepartment.hasOwnProperty(key)) {
        var depObject = {
          department: key,
          datas: groupsForDepartment[key],
        };
        GroupsAllDepartment.push(depObject);
      }
    }
    GroupsAllDepartment.map((item) =>
      GroupsAllDepartmentName.push({
        department: item.department,
        length: item.datas.length,
      })
    );
    setFilteredDepartmentName(GroupsAllDepartmentName);
    setGroupsForAllDepartment(GroupsAllDepartment);
  };

  const showDepartmentDetail = (event) => {
    groupsForAllDepartment.map((item) => {
      if (item.department === event.department.toString()) {
        setAllConfData(item.datas);
        setAllConfDataFiltered(item.datas);
        setTotalAllData(calculateTotals(item.datas));
      }
      return item;
    });
    setOpen2(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.got_Confirmation_ID
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.user_ID.toLowerCase().includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfDataFiltered(allConfData);
    }
  };
  const filterListDetail = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfDataFilteredByUser(
          confDetailFilteredData.filter((item) =>
            item.upc
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfDataFilteredByUser(
          confDetailFilteredData.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfDataFilteredByUser(confDetailFilteredData);
    }
  };
  const getUniqueTable = (dataset, value) => {
    if (dataset.length > 0) {
      var uniqueDepartments = new Set(dataset.map((obj) => obj[value]));
      var uniqueDepartmentsString = [...uniqueDepartments].join(",");
      return uniqueDepartmentsString;
    }
  };
  const [allConfListFiltered, setAllConfListFiltered] = useState([]);
  useEffect(() => {
    setAllConfListFiltered(confDetailData);
  }, [confDetailData]);
  const filterListConfirmationDetail = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfListFiltered(
          confDetailData.filter((item) =>
            item.upc
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfListFiltered(
          confDetailData.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfListFiltered(confDetailData);
    }
  };

  const mainOrGrind = [
    { value: "1", label: "Main" },
    { value: "2", label: "Department" },
  ];
  const SelectMainOrGrind = (event) => {
    setIsGrindOrMain(event);
  };
  return (
    <>
      <div className="OrderInvoice ">
        <div>
          <SelectPicker
            placement="bottomStart" // Or use "bottomEnd" based on your requirement
            size="md"
            className="header-bar-item"
            placeholder="Select Filter Type"
            onChange={SelectMainOrGrind}
            data={mainOrGrind.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            style={{ width: "180px" }} // Set a fixed width for the dropdown button
            menuStyle={{ width: "180px" }} // Set the same fixed width for the dropdown menu
          />
        </div>
        <>
          <PageHeaderComponent
            setGroupTypeID={setGroupTypeID}
            setAwgOrGotSelect={setAwgOrGotSelect}
            setUserStore={setUserStore}
            userContext={userContext}
            groupAndGroupID={groupAndGroupID}
            awgOrGot={awgOrGot}
            storeName={storeName}
            selectedDesc={selectedDesc}
            selectedID={selectedID}
            storeData={storeData}
            handleDescChange={handleDescChange}
            storeNameHeader={storeNameHeader}
            storeID={storeID}
            isVendor={false}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
            isCalendar={true}
          />
        </>
      </div>
      <TableComponent
        userContext={userContext}
        isFromModal={false}
        tableDetails={FilteredDepartmentConstants["DepartmentConstants"]}
        reportList={filteredDepartmentName}
        iconColumn={"department"}
        onTableRowClick={(e) => {
          showDepartmentDetail(e);
        }}
        showAll={true}
        reportType={""}
        isTotalRowRequired={false}
      />

      <Modal size={"full"} open={open2} onClose={handleClose2}>
        <Modal.Header>
          <div>
            <Modal.Title>
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpen2(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
              <div style={{ textAlign: "center" }}>
                Confirmation Department Detail
              </div>{" "}
            </Modal.Title>
          </div>

          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {allConfDataFiltered &&
                      allConfDataFiltered[0] &&
                      allConfDataFiltered[0].department}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="label">
                  <p className="m-0">
                    <InputGroup
                      onChange={(event) => {
                        filterList(event.target.value);
                      }}
                      style={{
                        width: 220,
                        height: 40,
                        fontWeight: 800,
                      }}
                    >
                      <Input placeholder="Filter Conf or User ID" />
                      <InputGroup.Button>
                        <SearchIcon />
                      </InputGroup.Button>
                    </InputGroup>
                  </p>
                </div>
              </>
            </div>

            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="label">
                  <p className="m-0">
                    {
                      <Dropdown
                        renderToggle={(_props, _ref) => (
                          <div className="filter" ref={_ref} {..._props}>
                            <Icon icon="pajamas:export" /> Export
                          </div>
                        )}
                        className="ms-2 me-0 button-fixed"
                        appearance="link"
                        placement="bottomEnd"
                      >
                        <Dropdown.Item
                          icon={<FileDownloadIcon />}
                          onClick={() => {
                            exportToExcel(
                              allConfDataFiltered,
                              `Confirmation Department Detail`
                            );
                          }}
                        >
                          Export to Excel
                        </Dropdown.Item>
                      </Dropdown>
                    }
                  </p>
                </div>
              </>
            </div>

            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="label">
                  {isFiltered ? (
                    <Button
                      onClick={() => {
                        selectedData && setOpen3(true);
                      }}
                      appearance="primary"
                      className="m-0"
                    >
                      Filter
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <TableComponent1
            userContext={userContext}
            isFromModal={false}
            tableDetails={
              ConfirmationListConstants["ConfirmationListConstantsDepartment"]
            }
            reportList={allConfDataFiltered ? allConfDataFiltered : allConfData}
            iconColumn={"vendor"}
            onTableRowClick={(e) => {
              setDetailConf(e);
            }}
            setOpen={setOpen}
            reportType={""}
            setSelectedData={setSelectedData}
            handleRowClick={handleRowClick}
            selectedData={selectedData}
            setSelectedRow={setSelectedRow}
            totalTableRow={totalAllData}
            isTotalRowRequired={true}
          />
        </Modal.Body>
      </Modal>

      <Modal size={"full"} open={open} onClose={handleClose}>
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen(false);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div style={{ textAlign: "center" }}>
            <Modal.Title>Confirmation List Detail</Modal.Title>
          </div>
          <div className="bg-container row m-0 mt-3 p-3">
            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">{userStore}</p>
                </div>
              </>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {allConfListFiltered &&
                      allConfListFiltered[0] &&
                      allConfListFiltered[0].department}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Serial Number</p>
                  <p className="m-0">
                    {allConfListFiltered &&
                      allConfListFiltered[0] &&
                      allConfListFiltered[0].serial_Number}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">User ID</p>
                  <p className="m-0">
                    {allConfListFiltered &&
                      allConfListFiltered[0] &&
                      allConfListFiltered[0].user_ID}
                  </p>
                </div>
              </>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Conf ID</p>
                  <p className="m-0">
                    {allConfListFiltered &&
                      allConfListFiltered[0] &&
                      allConfListFiltered[0].confirmation_ID}
                  </p>
                </div>
              </>
            </div>

            {/*             <div className="col-3 p-2 reportDescItem">
              <div className="icon">
                <Icon icon="material-symbols:storefront-outline" />
              </div>
              <div className="label">
                <p className="m-0">{"Scan Type"}</p>
                <p className="m-0">{getScanType()}</p>
              </div>
            </div> */}

            {/* <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Delivery Date</p>
                  <p className="m-0">
                    {allConfData &&
                      allConfData[0] &&
                      allConfData[0].delivery_Date}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Received Date</p>
                  <p className="m-0">
                    {allConfData &&
                      allConfData[0] &&
                      allConfData[0].received_Time}
                  </p>
                </div>
              </>
            </div> */}
            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="label">
                  <InputGroup
                    onChange={(event) => {
                      filterListConfirmationDetail(event.target.value);
                    }}
                    style={{
                      width: 300,
                      height: 40,
                      marginTop: "10px",
                      fontWeight: 800,
                    }}
                  >
                    <Input placeholder="Filter Description or UPC" />
                    <InputGroup.Button>
                      <SearchIcon />
                    </InputGroup.Button>
                  </InputGroup>
                </div>
              </>
            </div>
            {/* {groupConfLocDetailData && confirmationIDDetailTable  */}

            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="label">
                  <p className="m-0">
                    {
                      <Dropdown
                        renderToggle={(_props, _ref) => (
                          <div className="filter" ref={_ref} {..._props}>
                            <Icon icon="pajamas:export" /> Export
                          </div>
                        )}
                        className="ms-2 me-0 button-fixed"
                        appearance="link"
                        placement="bottomEnd"
                      >
                        <Dropdown.Item
                          icon={<FileDownloadIcon />}
                          onClick={() => {
                            exportToExcel(
                              allConfListFiltered,
                              `Confirmation List Detail`
                            );
                          }}
                        >
                          Export to Excel
                        </Dropdown.Item>
                      </Dropdown>
                    }
                  </p>
                </div>
              </>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ConfirmationListDetail
            handleSpinner={handleSpinner}
            setConfDetailData={setConfDetailData}
            confDetailData={allConfListFiltered}
            userContext={userContext}
            ConfID={detailConf}
            tableDetails={
              ConfirmationListConstants["ConfirmationListDetailConstants"]
            }
            store_ID={userStore}
          />
        </Modal.Body>
      </Modal>

      <Modal size={"full"} open={open3} onClose={handleClose3}>
        <Modal.Header>
          <span
            className="mx-2 filter"
            onClick={() => {
              setOpen3(false);
            }}
          >
            <Icon icon="ep:back" />
          </span>
          <div style={{ textAlign: "center" }}>
            <Modal.Title>Selected Data Detail</Modal.Title>
          </div>
          <div className="bg-container d-flex justify-content-between">
            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">{userStore}</p>
                </div>
              </>
            </div>
            {/* <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].department}
                  </p>
                </div>
              </>
            </div> */}
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].user_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : confDetailFilteredData,
                      "department"
                    )}
                  </p>
                </div>
              </>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Serial Number</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].user_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : confDetailFilteredData,
                      "serial_Number"
                    )}
                  </p>
                </div>
              </>
            </div>

            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">User ID</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].user_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : confDetailFilteredData,
                      "user_ID"
                    )}
                  </p>
                </div>
              </>
            </div>
            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Conf ID</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].confirmation_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : confDetailFilteredData,
                      "confirmation_ID"
                    )}
                  </p>
                </div>
              </>
            </div>

            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="label">
                  <InputGroup
                    onChange={(event) => {
                      filterListDetail(event.target.value);
                    }}
                    style={{
                      width: 300,
                      height: 40,
                      marginTop: "10px",
                      fontWeight: 800,
                    }}
                  >
                    <Input placeholder="Filter Description and UPC" />
                    <InputGroup.Button>
                      <SearchIcon />
                    </InputGroup.Button>
                  </InputGroup>
                </div>
              </>
            </div>
          </div>
          <div className="col-1 p-2 reportDescItem">
            <>
              <div className="label">
                <p className="m-0">
                  {
                    <Dropdown
                      renderToggle={(_props, _ref) => (
                        <div className="filter" ref={_ref} {..._props}>
                          <Icon icon="pajamas:export" /> Export
                        </div>
                      )}
                      className="ms-2 me-0 button-fixed"
                      appearance="link"
                      placement="bottomEnd"
                    >
                      <Dropdown.Item
                        icon={<FileDownloadIcon />}
                        onClick={() => {
                          exportToExcel(
                            allConfDataFilteredByUser
                              ? allConfDataFilteredByUser
                              : allConfDataFiltered,
                            `Confirmation Filtered  Detail`
                          );
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    </Dropdown>
                  }
                </p>
              </div>
            </>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ConfirmationListDetail
            handleSpinner={handleSpinner}
            setConfDetailData={setConfDetailFilteredData}
            setAllConfDataFilteredByUser={setAllConfDataFilteredByUser}
            confDetailData={
              allConfDataFilteredByUser
                ? allConfDataFilteredByUser
                : confDetailFilteredData
            }
            tableDetails={
              ConfirmationListConstants[
                "ConfirmationListDetailFilteredConstants"
              ]
            }
            userContext={userContext}
            ConfID={filteredConfID}
            store_ID={userStore}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DepartmentMain;
