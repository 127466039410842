import { TableHeaderConfig } from "../../../models";

export const ReportConstants = {
  "Grind Log Details": [
    {
      ...new TableHeaderConfig(),
      Header: "Confirmation ID",
      DataKey: "confirmation_ID",
      Width: 150,
      isFixed: true,
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "User",
      DataKey: "user_ID",
      Width: 100,
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Type",
      DataKey: "type",
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Date & Time",
      DataKey: "FormatedDateTime",
      TypeOfData: "Date",
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Grinder",
      DataKey: "grinder_N",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Source",
      DataKey: "",
      Width: 550,
      isSortable: false,
      TypeOfData: "",
      colSpan: 3,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Packer",
      DataKey: "packer_Name",
      Width: 150,
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Product Code",
      DataKey: "product_Code",
      Width: 150,
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Description",
      DataKey: "prod_Description",
      Width: 250,
      TypeOfData: "text-center",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Sellable",
      DataKey: "sell_Description",
      TypeOfData: "text-center",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Lug",
      DataKey: "lug_N",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Product Date",
      DataKey: "FormatedProductDate",
      TypeOfData: "Date",
    },
    { ...new TableHeaderConfig(), Header: "Serial/Seq #", DataKey: "serial_N" },
    {
      ...new TableHeaderConfig(),
      Header: "Est #",
      DataKey: "est_N",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Days old record",
      DataKey: "Badge_Type",
      Width: 150,
      isSearch: true,
      searchGroup: [1, 2],
      isHeader: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Days Old",
      DataKey: "days_Old",
      TypeOfData: "Number",
      Icon: "days_Old",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Prod Weight",
      DataKey: "ProdWeight",
      TypeOfData: "Number",
    },
  ],
  "Grind Log Case": [
    {
      ...new TableHeaderConfig(),
      Header: "Confirmation ID",
      DataKey: "confirmation_ID",
      Width: 150,
      isFixed: true,
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "User",
      DataKey: "user_ID",
      Width: 100,
      isSearch: true,
      searchGroup: [1, 2],
    },
    { ...new TableHeaderConfig(), Header: "Type", DataKey: "type" },
    {
      ...new TableHeaderConfig(),
      Header: "Pull Date & Time",
      DataKey: "FormatedDateTime",
      TypeOfData: "Date",
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Source",
      DataKey: "",
      Width: 400,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "UPC",
      DataKey: "upc",
      Width: 150,
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Description",
      DataKey: "description",
      Width: 250,
      TypeOfData: "text-center",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Package Date",
      DataKey: "FormatedProductDate",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Lug",
      DataKey: "lug_N",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Price",
      DataKey: "price",
      TypeOfData: "Price",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Markdown",
      DataKey: "",
      Width: 360,
      isSortable: false,
      TypeOfData: "",
      colSpan: 3,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Price",
      DataKey: "mrkdwn_Price",
      TypeOfData: "Price",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Percent(%)",
      DataKey: "mrkdwn_Perc",
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Date/Time",
      DataKey: "mrkdwn_Date",
      isSubHeader: true,
    },
  ],
  Summary: [
    {
      ...new TableHeaderConfig(),
      Header: "Store",
      DataKey: "store_ID",
      Width: 10,
      TypeOfData: "Text",
      isFixed: true,
      isSearch: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Company Store ID",
      Width: 10,
      TypeOfData: "Number",
      DataKey: "store_ID1",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Day",
      DataKey: "date_Name",
      isFixed: false,
      isSearch: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Date",
      DataKey: "FormatedDateTime",
      TypeOfData: "Date",
      isSearch: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Grind Lugs",
      Width: 10,
      TypeOfData: "Number",
      DataKey: "grind_Lugs",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Grinder Cleaned Status",
      DataKey: "Badge_Type_Clean",
      Width: 10,
      isSearch: true,
      isHeader: false,
    },

    {
      ...new TableHeaderConfig(),
      Header: "Totals",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "# of Grinds",
      DataKey: "grinds_Total",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "LBS",
      DataKey: "total_LBS",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "First Grind",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Time",
      DataKey: "first_Grind_Time",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "LBS",
      DataKey: "first_Grind_LBS",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Last Grind",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Time",
      DataKey: "last_Grind_Time",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "LBS",
      DataKey: "last_Grind_LBS",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Trim",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Pieces",
      DataKey: "trim_Pieces",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Lugs",
      DataKey: "trim_Lugs",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },

    {
      ...new TableHeaderConfig(),
      Header: "Case Pulls",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 3,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Pulls",
      DataKey: "case_Pulls",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Markdown",
      DataKey: "case_Pulls_Markdown",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Not Markdown",
      DataKey: "case_Pulls_NotMarkdown",
      Width: 10,
      TypeOfData: "Number",
      isSubHeader: true,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Clean",
      DataKey: "",
      Width: 10,
      isSortable: false,
      TypeOfData: "",
      colSpan: 2,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Times ",
      DataKey: "CleanWithFlag",
      Width: 10,
      TypeOfData: "Number",
      Icon: "clean_Cheating",
      isSubHeader: true,
    },
    //{...new TableHeaderConfig(), Header: "Times", DataKey: "clean_Times", Width: 100,TypeOfData: "Number", isSubHeader: true,},
    {
      ...new TableHeaderConfig(),
      Header: "Last Grind",
      DataKey: "clean_Last_Grind",
      Width: 10,
      TypeOfData: "Text",
      isSubHeader: true,
    },
    // {...new TableHeaderConfig(), Header: "Grinder Cleaned Status", DataKey: "Badge_Type_Clean", Width: 150, isSearch: true, searchGroup: [1, 2], isHeader: false},
    // {...new TableHeaderConfig(), Header: "Grind lbs", DataKey: "grind_Weight", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "Lug lbs", DataKey: "lug_Weight", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "Totals", DataKey: "TotalCount", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "Times Grind", DataKey: "grind_Cnt", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "# Lugs Used", DataKey: "lug_Cnt", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "Pieces Trimed", DataKey: "trim_Cnt", TypeOfData: "Number"},
    // {...new TableHeaderConfig(), Header: "Times Cleaned", DataKey: "CleanWithFlag", TypeOfData: "Number", Icon: "clean_Cheating"},
    // {...new TableHeaderConfig(), Header: "Last Grind", DataKey: "grind_Last"},
    // {...new TableHeaderConfig(), Header: "Last Clean", DataKey: "last_Clean_ThatDay"},
    // {...new TableHeaderConfig(), Header: "Clean After Last Grind", DataKey: "last_Clean_After_Grind", TypeOfData: "text-center"},
    // {...new TableHeaderConfig(), Header: "Case Pulls", DataKey: "scanned_Cases", TypeOfData: "Number"},
  ],

  Summary2: [
    {
      ...new TableHeaderConfig(),
      Header: "Store",
      DataKey: "store_ID",
      isFixed: true,
      isSearch: true,
      searchGroup: [2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Company Store Id",
      DataKey: "store_ID1",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Date",
      DataKey: "FormatedDateTime",
      TypeOfData: "Date",
      isSearch: true,
      searchGroup: [1, 2],
    },
    {
      ...new TableHeaderConfig(),
      Header: "Grinder Cleaned Status",
      DataKey: "Badge_Type_Clean",
      Width: 150,
      isSearch: true,
      searchGroup: [1, 2],
      isHeader: false,
    },
    {
      ...new TableHeaderConfig(),
      Header: "Grind lbs",
      DataKey: "grind_Weight",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Lug lbs",
      DataKey: "lug_Weight",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Totals",
      DataKey: "TotalCount",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Times Grind",
      DataKey: "grind_Cnt",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "# Lugs Used",
      DataKey: "lug_Cnt",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Pieces Trimed",
      DataKey: "trim_Cnt",
      TypeOfData: "Number",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Times Cleaned",
      DataKey: "CleanWithFlag",
      TypeOfData: "Number",
      Icon: "clean_Cheating",
    },
    { ...new TableHeaderConfig(), Header: "Last Grind", DataKey: "grind_Last" },
    {
      ...new TableHeaderConfig(),
      Header: "Last Clean",
      DataKey: "last_Clean_ThatDay",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Clean After Last Grind",
      DataKey: "last_Clean_After_Grind",
      TypeOfData: "text-center",
    },
    {
      ...new TableHeaderConfig(),
      Header: "Case Pulls",
      DataKey: "scanned_Cases",
      TypeOfData: "Number",
    },
  ],
};
