import { useState, useRef } from "react";
import {
  Form,
  Button,
  Divider,
  Row,
  Col,
  Checkbox,
  ButtonToolbar,
} from "rsuite";
import * as UMSService from "../../../../services/umsService";
import { toast } from "react-toastify";

const UpdateRole = (props: any) => {
  const { userContext, roleData } = props;
  //console.log(userData);
  const formRef = useRef<any>();
  const checkStatusRef = useRef<any>();
  const checkAdminsRef = useRef<any>();
  const checkUsersRef = useRef<any>();

  const initFormValue = {
    Role_Name: null,
    IsUsers: false,
    IsAdmin: false,
    Status: false,
  };

  const [formValue, setFormValue] = useState(roleData);

  const handleSubmit = () => {
    /*     if (!formRef.current.check()) {
      console.error("Form error");
      return;
    } */

    const obj = {
      //...roleData,
      Role_Name: roleData.role_Name,
      UID: roleData.uid,
      Status: true,
      IsAdmin: checkAdminsRef.current.checked,
      IsUsers: checkUsersRef.current.checked,
    };


    UpdateRole(obj);
  };
  if (userContext) {
    var { roleName } = userContext;
  }

 

  const UpdateRole = async (userData) => {
    UMSService.UpdateNewRole(userData)
      .then((result) => {
        result && toast.success("Role Updated Successfully");
        result && window.location.reload();
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  if (roleName === "Admin" || roleName === "Super Admin") {
    return (
      <>
        <Row className="show-grid">
          <Col xs={24}>
            <Form onChange={setFormValue} onSubmit={handleSubmit}>
              <Form.Group controlId="Role_Name">
                <Form.ControlLabel>Role Name</Form.ControlLabel>
                <Form.Control
                  disabled
                  placeholder={roleData.role_Name}
                  value={roleData.role_Name}
                  name="Role_Name"
                  style={{ width: 360 }}
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="IsAdmin">
                <Form.ControlLabel>Admin</Form.ControlLabel>
                <Form.Control
                  name="IsAdmin"
                  accepter={Checkbox}
                  inputRef={checkAdminsRef}
                  inline
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId="IsUsers">
                <Form.ControlLabel>Users</Form.ControlLabel>
                <Form.Control
                  name="IsUsers"
                  inputRef={checkUsersRef}
                  accepter={Checkbox}
                  inline
                ></Form.Control>
              </Form.Group>{" "}
              <Form.Group controlId="Status">
                <Form.ControlLabel>Status</Form.ControlLabel>
                <Form.Control
                  name="Status"
                  checked
                  readOnly
                  inputRef={checkStatusRef}
                  accepter={Checkbox}
                  inline
                ></Form.Control>
              </Form.Group>
              <ButtonToolbar>
                <Button
                  style={{ width: 360, marginLeft: 190 }}
                  appearance="primary"
                  color="blue"
                  type="submit"
                >
                  Update Role
                </Button>
              </ButtonToolbar>
            </Form>
            <Divider />
          </Col>
        </Row>
      </>
    );
  } else {
    return <div>You have to contact Admin</div>;
  }
};

export default UpdateRole;
