export class ReportMaster {
  headers: Array<any>;
  groupedList: Array<any>;
  reportList: Array<any>;

  constructor() {
    this.headers = [];
    this.groupedList = [];
    this.reportList = [];
  }
}
