export const ReportConstants = {
    Pending: [
        {
            Header: "Store ID1",
            DataKey: "store_ID1",
            Width: "auto",
            isFixed: true,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Vendor",
            DataKey: "vendor",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "text-center-heading",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Department",
            DataKey: "department",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Number",
            DataKey: "invoice_Number",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Date",
            DataKey: "invoice_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Confirmation ID",
            DataKey: "confirmation_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "UPC",
            DataKey: "upc",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Item",
            DataKey: "item_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Description",
            DataKey: "description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "text-center-heading",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Size",
            DataKey: "size",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Pack",
            DataKey: "pack",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Reason Description",
            DataKey: "reason_Description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit Qty",
            DataKey: "qty",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit Type",
            DataKey: "credit_Type",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "WH Comments",
            DataKey: "got_Comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Store Comments",
            DataKey: "comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Qty",
            DataKey: "invoice_Qty",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Case Cost",
            DataKey: "price",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Price",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Extended Cost",
            DataKey: "credit_Amount",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Price",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Detail Delivery Date",
            DataKey: "detail_Delivery_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Date Next",
            DataKey: "invoice_Date_Next",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
    ],

    Paid: [
        {
            Header: "Store ID1",
            DataKey: "store_ID1",
            Width: "auto",
            isFixed: true,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Vendor",
            DataKey: "vendor",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Department",
            DataKey: "department",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Confirmation ID",
            DataKey: "confirmation_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Invoice Number",
            DataKey: "invoice_Number",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Date",
            DataKey: "invoice_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "UPC",
            DataKey: "upc",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Item",
            DataKey: "item_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Description",
            DataKey: "description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "text-center-heading",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Size",
            DataKey: "size",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Pack",
            DataKey: "pack",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Reason_Description",
            DataKey: "reason_Description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit Qty",
            DataKey: "qty",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit_Type",
            DataKey: "credit_Type",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "WH Comments",
            DataKey: "got_Comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Store Comments",
            DataKey: "comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Qty",
            DataKey: "invoice_Qty",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Case Cost",
            DataKey: "price",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Price",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Detail Delivery Date",
            DataKey: "detail_Delivery_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Date Next",
            DataKey: "invoice_Date_Next",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
    ],
    NotApproved: [
        {
            Header: "Store ID1",
            DataKey: "store_ID1",
            Width: "auto",
            isFixed: true,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Department",
            DataKey: "department",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "right",
            TypeOfData: "text-center-heading",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit_Type",
            DataKey: "credit_Type",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit N",
            DataKey: "credit_N",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Confirmation ID",
            DataKey: "confirmation_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },

        {
            Header: "Invoice Number",
            DataKey: "invoice_Number",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Invoice Date",
            DataKey: "invoice_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Delivery Date",
            DataKey: "delivery_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Item",
            DataKey: "item_ID",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Description",
            DataKey: "description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "text-center-heading",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Size",
            DataKey: "size",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Pack",
            DataKey: "pack",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Reason_Description",
            DataKey: "reason_Description",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Credit Qty",
            DataKey: "credit_Qty",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Not Approved Reason",
            DataKey: "notApproved_Reason",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "WH Comments",
            DataKey: "wH_Comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Store Comments",
            DataKey: "store_Comments",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Days Reordered",
            DataKey: "days_Reordered",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Number",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Rec Date",
            DataKey: "rec_Date",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Date",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Ext Cost",
            DataKey: "ext_Cost",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Price",
            isSearch: true,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "DM",
            DataKey: "dm",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "text-center-heading",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
        {
            Header: "Vendor",
            DataKey: "vendor",
            Width: "auto",
            isFixed: false,
            isSortable: true,
            Align: "left",
            TypeOfData: "Text",
            isSearch: false,
            isHeader: true,
            Icon: "",
            isSubHeader: false,
            colSpan: 1,
            HeaderStyle: { whiteSpace: "nowrap" },
        },
    ],
};
