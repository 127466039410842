import { TableHeaderConfig } from "models";

export const MarkdownDiscountConstants = {
    "MarkdownDiscount": [

        {...new TableHeaderConfig(), Header: "Level", DataKey: "store_Group", Width: 10, isFixed: true, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Department", DataKey: "department", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Sub Department", DataKey: "sub_Department", Width: 10, isSearch: true, searchGroup: [1, 2]},
        //{...new TableHeaderConfig(), Header: "Show", DataKey: "show_Department", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true },
        {...new TableHeaderConfig(), Header: "PLU", DataKey: "plu", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Description", DataKey: "description", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Disc %", DataKey: "coupon_Percent", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Start Price", DataKey: "start_Price",TypeOfData:"Number", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "End Price", DataKey: "end_Price", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Min %", DataKey: "percent_Min", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Max %", DataKey: "percent_Max", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Food Stamp", DataKey: "food_Stamp", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},
        {...new TableHeaderConfig(), Header: "Taxable", DataKey: "taxable", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},
        //{...new TableHeaderConfig(), Header: "Store ID", DataKey: "id", Width: 10, isSearch: true, searchGroup: [1, 2]},
        //{...new TableHeaderConfig(), Header: "Delete", DataKey: "", Width: 10, isFixed: false, isSearch: true, searchGroup: [1, 2],isSortable:false,Filter:true},

    ],
    "MarkdownLabel": [
        {...new TableHeaderConfig(), Header: "Level", DataKey: "store_Group", Width: 10, isFixed: true, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Template", DataKey: "template", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Label Title", DataKey: "title1", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Reduced Price Title", DataKey: "title2", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Print Reg Price", DataKey: "print_Reg_Price", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},
        {...new TableHeaderConfig(), Header: "Print You Save", DataKey: "print_You_Save", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},
        {...new TableHeaderConfig(), Header: "Weight Item Exception", DataKey: "weight_Item_Exception", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},
        {...new TableHeaderConfig(), Header: "Symbol", DataKey: "plU_Symbology", Width: 10, isSearch: true, searchGroup: [1, 2]},
        {...new TableHeaderConfig(), Header: "Price Check Digit", DataKey: "price_CheckDigit", Width: 10, isSearch: true, searchGroup: [1, 2],isInput:true},


    ]

}