/* eslint-disable array-callback-return */
import { useState } from "react";
import ReportTrackItByGroupComponent from "./StoreLayer/reportTrackItByGroupComponent";
import TrackITDepartmentMain from "./DepartmentLayer/TrackITDepartmentMain";

const TrackITMain = (props: any) => {
  const { userContext, handleSpinner } = props;

  const [isDepOrStore, setIsDepOrStore] = useState("1");

  return (
    <>
      <div className="scrollable-div">
        <></>
        {isDepOrStore === "1" ? (
          <ReportTrackItByGroupComponent
            userContext={userContext}
            handleSpinner={handleSpinner}
          />
        ) : (
          <TrackITDepartmentMain
            userContext={userContext}
            handleSpinner={handleSpinner}
          />
        )}
      </div>
    </>
  );
};

export default TrackITMain;
