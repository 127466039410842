import { useEffect, useState } from "react";
import * as ReportService from "../../../services/reportServices";
import moment from "moment";
import TableComponent from "components/common/tableComponent";
import { ReportConstants } from "./GrindLogTableConstants";

const GrindLogTable = (props: any) => {
  const { userStore, fromDate, toDate, userContext, groupTypeID } = props;
  const [reportSummaryList, setReportSummaryList] = useState([]);
  const getReportSummaryData = (fromDate, toDate) => {
    const { handleSpinner, userContext = {} } = props;
    const { groupType, groupTypeId, store } = userContext;

    handleSpinner && handleSpinner(true);

    if (
      !userStore &&
      !groupTypeID &&
      userContext?.groupType === 3 &&
      userContext?.groupTypeId === 0
    ) {
      ReportService.getReportGrindLogSummary(
        0,
        3,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && store === 0 && groupTypeID !== 0 && !userStore) {
      ReportService.getReportGrindLogSummary(
        groupTypeID ? groupTypeID : groupTypeId !== 0 ? groupTypeId : null,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && store !== 0) {
      ReportService.getReportGrindLogSummary(
        store,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else if (userContext && userStore) {
      ReportService.getReportGrindLogSummary(
        userStore,
        1,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      ReportService.getReportGrindLogSummary(
        groupTypeId !== 0 ? groupTypeId : null,
        2,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      )
        .then((result) => {
          const GrindlogSummaryResponse = modifySummaryReportData(
            result.summary
          );
          setReportSummaryList(GrindlogSummaryResponse);
        })
        .catch((err) => {
          console.error("Error: Get Summary Report - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const modifySummaryReportData = (data) => {
    let modifiedData = data.map((item) => {
      item["FormatedDateTime"] =
        item.delivery_Date &&
        item.delivery_Date.trim() &&
        moment(item.delivery_Date).format("MM-DD-YYYY");
      item["CleanWithFlag"] = item.clean_Times
        ? item.clean_Cheating
          ? item.clean_Times.toString()
          : item.clean_Times.toString()
        : "0";
      item["Badge_Type_Clean"] =
        item.clean_Cheating === true ? "Quick cleaned" : "Regular cleaned";
      item["total_LBS"] =
        (item.first_Grind_LBS ? Number(item.first_Grind_LBS) : 0) +
        (item.last_Grind_LBS ? Number(item.last_Grind_LBS) : 0);

      return item;
    });
    return modifiedData;
  };

  useEffect(() => {
    getReportSummaryData(fromDate, toDate);
  }, [fromDate, toDate, userContext, userStore, groupTypeID]);
  return (
    <>
      <TableComponent
        isFromModal={false}
        tableDetails={ReportConstants["Summary"]}
        reportList={reportSummaryList}
        iconColumn={"NA"}
        onTableRowClick={() => {}}
        reportType={""}
        totalTableRow={{}}
        isTotalRowRequired={false}
        isTableCustomStriped={
          moment(fromDate).format("MM-DD-YYYY") !==
          moment(toDate).format("MM-DD-YYYY")
            ? true
            : false
        }
      ></TableComponent>
    </>
  );
};

export default GrindLogTable;
